import React from "react";
import { useParams, NavLink } from "react-router-dom";
import { useFetchCourse } from "hooks/useFetchCourse";
import CourseHeader from "components/shared/CourseHeader";

const CourseNav = ({ onDrawer = false }) => {
    const containerClasses = [
        "p-5 syllabus-sidebar",
        !onDrawer ? "sidebar h-auto col-3 d-none d-md-block" : "h-100",
    ].join(" ");
    let params = useParams();

    const { data } = useFetchCourse({ slug: params.slug });

    function toggleSidebarDrawer() {
        const backDrop = document.querySelectorAll(".offcanvas-backdrop");
        if (!Boolean(backDrop.length)) return;

        backDrop.forEach((element) => {
            element.dispatchEvent(new MouseEvent("mousedown"));
        });
        document.body.style = {};
    }
    return (
        <div className={containerClasses}>
            {data && data.fetchCourse ? (
                <CourseHeader course={data.fetchCourse} />
            ) : null}
            <ul className="nav flex-column mt-7">
                <li className="nav-item">
                    <NavLink
                        onClick={toggleSidebarDrawer}
                        end={true}
                        to={`/teacher/my/courses/${params.slug}`}
                        className="nav-link text-dark ps-4">
                        Home
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        onClick={toggleSidebarDrawer}
                        to={`/teacher/my/courses/${params.slug}/content`}
                        className="nav-link text-dark ps-4">
                        Syllabus Builder
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        onClick={toggleSidebarDrawer}
                        to={`/teacher/my/courses/${params.slug}/submissions`}
                        className="nav-link text-dark ps-4">
                        Submissions
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        onClick={toggleSidebarDrawer}
                        to={`/teacher/my/courses/${params.slug}/students`}
                        className="nav-link text-dark ps-4">
                        Students
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        onClick={toggleSidebarDrawer}
                        to={`/teacher/my/courses/${params.slug}/student-progress-report`}
                        className="nav-link text-dark ps-4">
                        Student Progress Report
                    </NavLink>
                </li>
            </ul>
        </div>
    );
}

export default CourseNav
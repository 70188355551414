import React from "react";
import { useParams } from "react-router-dom";
import Block from "./Block";
import Question from "./Question";
import Breadcrumbs from "../partials/Breadcrumbs";
import SidebarToggler from "../../content_builder/sidebar/SidebarToggler";
import formatDate from "../../../utils/formatDate";
import currentPortal from "../../../utils/currentPortal";

const Activity = ({
    submission,
    submission: { answers, courseContent },
    activityState,
    dispatch,
    readOnly,
}) => {

    let params = useParams();
    const isStudent = currentPortal() === "student";
    const crumbUrlPrefix = isStudent ? "/student/my/classes/"
                                     : "/teacher/my/courses/";


    return (
        <div className="col-12 col-md-9 course-main-panel">
            <Breadcrumbs crumbUrlPrefix={crumbUrlPrefix} crumbs={[
                { name: "Submissions", url: `${crumbUrlPrefix}${params.slug}/submissions` },
                { name: courseContent.name },
            ]} />
            <div className="d-flex flex-column mb-6">
                <h2 className="mb-2">{courseContent.name}</h2>
                <div className="d-flex flex-column flex-md-row align-items-md-center">
                    <span className="text-capitalize text-primary">{courseContent.contentType}</span>
                    {!readOnly ? (
                        <>
                            <span className="text-muted d-none d-md-block mx-2">|</span>
                            <span className="text-capitalize text-primary">{submission.status === "checked" ? "Draft" : submission.status}</span>
                        </>
                    ) : null}
                    <span className="text-muted d-none d-md-block mx-2">|</span>
                    {!readOnly ? (
                        <span>Submitted on {formatDate(submission.submitDate)} by {submission.user.name}</span>
                    ) : (
                        <>
                            {["checked", "published"].includes(submission.status) ? (
                                <span>Last checked on {formatDate(submission.updatedAt)} by {submission.lastCheckedByUser.name}</span>
                            ) : (
                                <span className="text-capitalize text-primary">{submission.status}</span>
                            )}
                        </>
                    )}
                    <SidebarToggler text="Score summary" />
                </div>
            </div>
            <h2>Items</h2>
            <ol className="p-0 m-0">
                {courseContent.blocks.map((block) => {
                    return block.type === "question" && !!activityState.length ? (
                        <Question key={block.id} block={block}
                            answer={answers.find(answer => answer.blockId === block.id)}
                            checkData={activityState.find(answer => answer.blockId === block.id)}
                            dispatch={dispatch}
                            readOnly={readOnly}
                            published={submission?.status === "published"} />
                    ) : <Block key={block.id} block={block} />
                })}
            </ol>
        </div>
    );
}

export default Activity
import React from 'react'
import Lesson from './Lesson';

const Lessons = ({ lessons }) => {

  return (
    <ul className="list-group ps-4 py-2">
      { [...lessons]
        .sort((a, b) => a.ordinality - b.ordinality)
        .map((lesson) => (
          <Lesson
            key={lesson.id}
            lesson={lesson} />
        )) }
    </ul>
  );
}

export default Lessons
import React, { Fragment } from 'react'
import { Outlet, Link } from "react-router-dom";
import Sidenav from './Sidenav';

const StudentPortal = () => {
  return (
    <Fragment>
      <Sidenav />
      <Outlet />
    </Fragment>
  );
}

export default StudentPortal
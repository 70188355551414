import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client';
import { FETCH_COURSE_SUBMISSIONS } from 'graphql/queries';
import Breadcrumbs from './partials/Breadcrumbs';
import SubmissionsTabs from './partials/SubmissionsTabs';
import SidebarToggler from '../content_builder/sidebar/SidebarToggler';
import { Link, useParams } from 'react-router-dom';
import { useFetchCourse } from 'hooks/useFetchCourse';
import { default as AddBoxOutline } from '../../../assets/images/add-box-outlined.svg';
import { default as UploadFileOutline } from '../../../assets/images/upload-file-outlined.svg';

const Submissions = () => {

    let params = useParams();
    const [fetchSubmissions, { data, loading }] = useLazyQuery(FETCH_COURSE_SUBMISSIONS);
    const { data: courseData, loading: courseLoading } = useFetchCourse({ slug: params.slug });
    const [showSubmissionsSplash, setShowSubmissionsSplash] = useState(false);
    const [showActivitiesSplash, setShowActivitiesSplash] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const [activities, setActivities] = useState([]);

    useEffect(() => {
        fetchSubmissions({ variables: {
            slug: params.slug,
        } });
    }, []);

    useEffect(() => {
        setShowSubmissionsSplash(!loading && !Boolean(submissions?.length));
        setShowContent(!loading && Boolean(submissions?.length))
    }, [loading]);

    useEffect(() => {
        if (courseData?.fetchCourse) {
            let activities = courseData?.fetchCourse?.courseModules
                                       ?.flatMap(module => module.lessons)
                                       ?.flatMap(lesson => lesson.courseContents)
                                       ?.filter(content => content?.isActivity);
            setShowActivitiesSplash(!Boolean(activities.length));
            setActivities(activities);
        }
    }, [courseLoading]);

    const submissions = data?.fetchCourseSubmissions;

    return (
        <div className="col-12 col-md-9 px-5 bg-white min-vh-100">
            <Breadcrumbs crumbs={[{ name: 'Submissions' }]} />
            <h2>Submissions</h2>
            <SidebarToggler text="Navigate Course" />
            {(showSubmissionsSplash && !showActivitiesSplash ) && (
                <div className="text-center bg-white py-6 mt-4 border border-3 border-light rounded-2 coi">
                    <div className="d-flex justify-content-center pb-4">
                        <div className="rounded-pill d-flex justify-content-center align-items-center" style={{width: "50px", height: "50px", backgroundColor: "#DDE7F6"}}>
                            <img src={UploadFileOutline} alt="Add Icon" />
                        </div>
                    </div>
                    <span className="fs-1 fw-bold">Your activities do not have submissions yet.</span>
                    <p className="mb-5">You may view your existing activitites, or create a new one.</p>

                    <div className="d-flex justify-content-center">
                        <Link to={`/teacher/my/courses/${params.slug}/content/new`}
                              className="btn btn-outline-primary mx-2" >Create a new one +</Link>

                        <Link to={`/teacher/my/courses/${params.slug}/content/${activities[0]?.slug}`}
                              className="btn btn-primary mx-2" >View existing ➝</Link>
                    </div>
                </div>
            )}

            {showActivitiesSplash && (
                <div className="text-center bg-white py-6 mt-4 border border-3 border-light rounded-2 coi">
                    <div className="d-flex justify-content-center pb-4">
                        <div className="rounded-pill d-flex justify-content-center align-items-center" style={{width: "50px", height: "50px", backgroundColor: "#DDE7F6"}}>
                            <img src={AddBoxOutline} alt="Add Icon" />
                        </div>
                    </div>
                    <span className="fs-1 fw-bold">You have no activities created yet.</span>
                    <p className="mb-5">Start by creating an activity.</p>

                    <Link to={`/teacher/my/courses/${params.slug}/content/new`}
                              className="btn btn-primary" >Create an activity +</Link>
                </div>
            )}
            {showContent && (
                <>
                    { data && data.fetchCourseSubmissions ? (
                        <SubmissionsTabs/>
                    ) : null }
                </>
            )}
        </div>
    );
}

export default Submissions
import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { sortByOrdinality } from "../../utils/sortCourseContents";
import Button from "../elements/Button";
import useCourseContentProgress from "../../hooks/useCourseContentProgress";

const SectionNavigator = ({
    course,
    isStudent,
}) => {
    if (!course) return null;

    // [
    //     {
    //       label: "Module 1 > Lesson 1",
    //       options: [
    //         { label: "Section 1", value: "section-slug" },
    //         { label: "Section 2", value: "section-slug" }
    //       ]
    //     }
    // ];
    const options =
        sortByOrdinality(course?.courseModules)
            .flatMap(module => (
                sortByOrdinality(module.lessons)
                    .map(lesson => {
                        let sections = sortByOrdinality(lesson.courseContents)
                                        .map(section => ({
                                            label: section.name,
                                            value: section.slug,
                                            isActivity: section.isActivity,
                                            progress: section.courseContentProgress,
                                        }));
                        return {
                            label: `${module.name} > ${lesson.name}`,
                            options: sections
                        };
                    })
            ));

    const params = useParams();
    const sections = options?.flatMap(group => group.options);
    const location = useLocation();
    const url = isStudent
        ? location.pathname.includes("/my/") // student
            ? `/student/my/classes/${params.slug}/content/`
            : `/student/classes/${params.slug}/content/`
        : location.pathname.includes("/my/") // teacher
            ? `/teacher/my/courses/${params.slug}/content/`
            : `/teacher/courses/${params.slug}/content/`;

    return (
        <div className="d-flex justify-content-between align-items-center">
            <NavigationButton sections={sections} url={url}/>
            <SectionSelector options={options} sections={sections} url={url}/>
            <NavigationButton sections={sections} url={url} isStudent={isStudent} isNext />
        </div>
    );
}

const SectionSelector = ({
    options,
    sections,
    url,
}) => {
    const navigate = useNavigate();
    const params = useParams();

    const handleSelectorChange = (option) => {
        navigate(url + option.value, { replace: true });
    };
    const selectedOption = sections?.find(option => option.value == params.slug2);

    return (
        <Select
            className="section-selector"
            placeholder="Select a content to show"
            onChange={handleSelectorChange}
            options={options}
            value={selectedOption}
            styles={{
                option: (styles, state) => ({
                    ...styles,
                    paddingLeft: "2rem"
                })
            }}
        />
    );
}

const NavigationButton = ({
    sections,
    url,
    title,
    isNext,
    isStudent,
}) => {
    if (!sections) return null;

    const params = useParams();
    const icon = isNext ? "right" : "left";
    const containerClasses = [
        "btn btn-sm py-2 btn-secondary d-flex align-items-center navigator-button",
        // "justify-content-between",
        isNext ? "flex-row-reverse" : "",
    ].join(" ");
    const currentSection = sections?.find(option => option.value == params.slug2);
    const currentSectionIndex = sections?.indexOf(currentSection)

    // current section is last, show "mark as done" button
    if (isNext && (currentSectionIndex + 1) > (sections.length - 1)) {
        return <MarkAsDoneButton currentSection={currentSection} isStudent={isStudent} />;
    }

    // current section is first, do not show "previous" button
    if (!isNext && (currentSectionIndex - 1) < 0) return null;

    const sectionToNavigate = isNext
            ? sections[currentSectionIndex + 1]
            : sections[currentSectionIndex - 1];

    return (
        <Link
            to={url + sectionToNavigate.value}
            className={containerClasses}
        >
            <span className={`fe fe-chevron-${icon}`} style={{ width: "15%" }}></span>
            <span className="text-truncate" style={{ width: "85%" }}>
                {title || sectionToNavigate.label}
            </span>
        </Link>
    );
}

const MarkAsDoneButton = ({
    currentSection,
    isStudent,
}) => {
    if (!isStudent) return null;
    if (currentSection.isActivity) return null;
    if (currentSection.progress === "done") return null;

    const { doneContent } = useCourseContentProgress();
    function handleDoneContent() {
        if(confirm(`Do you wnat to mark ${currentSection.label} as done?`)) {
            doneContent({ variables: { slug: currentSection.value } })
                .then(res => window.location.reload())
        }
    }
    return (
        <Button
            onClick={handleDoneContent}
            className="btn btn-sm py-2 btn-secondary navigator-button"
            text="Mark as done" />
    );
}

SectionNavigator.defaultProps = {
    course: null,
    isStudent: false,
};
SectionSelector.defaultProps = {
    option: [],
    sections: [],
    url: "#"
}
NavigationButton.defaultProps = {
    sections: null,
    url: "#",
    title: null,
    isNext: false,
};

export default SectionNavigator;

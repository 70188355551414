import { useLazyQuery, useQuery } from "@apollo/client";
import { FETCH_COURSE_CONTENT } from "graphql/queries";

export const useFetchCourseContent = () => {
    const [fetchCourseContentAsync, { loading: fetchCourseContentAsyncLoading }] = useLazyQuery(
        FETCH_COURSE_CONTENT,
        { fetchPolicy: "cache-and-network" },
    );

    function fetchCourseContent(variables) {
        return useQuery(FETCH_COURSE_CONTENT, { variables });
    }

    return {
        fetchCourseContent,
        fetchCourseContentAsync,
        fetchCourseContentAsyncLoading,
    };
}
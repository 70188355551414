import { useLazyQuery, useQuery } from "@apollo/client";
import { FETCH_COURSE } from "../graphql/queries";

const useCourseQuery = () => {

    const [fetchCourseAsync, { loading: fetchCourseAsyncLoading }] = useLazyQuery(FETCH_COURSE);

    function fetchCourse(variables) {
        return useQuery(FETCH_COURSE, { variables });
    }

    return {
        fetchCourse,
        fetchCourseAsync,
        fetchCourseAsyncLoading,
    };
}

export default useCourseQuery;
import React from 'react';
import { useMutation } from '@apollo/client';
import { FETCH_COURSE_USERS } from 'graphql/queries';
import { DESTROY_COURSE_USER } from 'graphql/mutations';

const CourseUser = ({ courseUser, courseUser: { user }, courseId, allowEnlistment }) => {

  const [destroyCourseUser] = useMutation(DESTROY_COURSE_USER, {
    onCompleted: (data) => alert('Student delisted.'),
    onError: (error) => alert('Failed to delist student.'),
    update(cache, { data: { destroyCourseUser } }) {
      cache.updateQuery({
        query: FETCH_COURSE_USERS,
        variables: { courseId },
        overwrite: true,
      }, (data) => ({
        fetchCourseUsers: data.fetchCourseUsers.filter(courseUser => courseUser.id !== destroyCourseUser.id),
      }));
    }
  });

  const handleDelist = () => {
    destroyCourseUser({ variables: { id: courseUser.id } });
  }

  return (
    <tr>
      <td>{user.name}</td>
      <td>{user.email}</td>
      <td>
        <span className="badge bg-primary">{courseUser.role}</span>
      </td>
      <td>{user.lrn}</td>
      <td>{user.yearLevel ? user.yearLevel.name : null}</td>
      { allowEnlistment && (<td>
        <button
          type="button"
          className="btn btn-sm btn-danger"
          onClick={() => handleDelist()}>
          Delist
        </button>
      </td>)}
    </tr>
  );
}

export default CourseUser
import React, { Fragment, useEffect } from 'react'
import SubmissionsTable from './SubmissionsTable';
import SubmissionFilters from './SubmissionFilters';
import { useLazyQuery } from '@apollo/client';
import { FETCH_COURSE_SUBMISSIONS } from 'graphql/queries';
import { useParams } from 'react-router-dom';

const SubmissionsTabs = ({ }) => {
    let params = useParams();
    const [fetchSubmissions, { data }] = useLazyQuery(FETCH_COURSE_SUBMISSIONS);

    useEffect(() => {
        fetchSubmissions({ variables: {
            slug: params.slug,
        } });
    }, []);

    const submissions = data?.fetchCourseSubmissions;
    const submitted = submissions?.filter(submission => submission.status === 'submitted');
    const checked = submissions?.filter(submission => submission.status === 'checked');
    const published = submissions?.filter(submission => submission.status === 'published');

  return (
    <Fragment>
       <SubmissionFilters fetchSubmissions={fetchSubmissions} />
      <ul className="nav nav-tabs" id="tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button className="nav-link active" id="all-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="all" aria-selected="true">
            {`All (${submissions?.length})`}
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="submitted-tab" data-bs-toggle="tab" data-bs-target="#submitted" type="button" role="tab" aria-controls="submitted" aria-selected="false">
            {`Submitted (${submitted?.length})`}
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="checked-tab" data-bs-toggle="tab" data-bs-target="#checked" type="button" role="tab" aria-controls="checked" aria-selected="false">
            {`Checked (${checked?.length})`}
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="published-tab" data-bs-toggle="tab" data-bs-target="#published" type="button" role="tab" aria-controls="published" aria-selected="false">
            {`Published (${published?.length})`}
          </button>
        </li>
      </ul>
      <div className="tab-content" id="tabContent">
        <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
          <SubmissionsTable submissions={submissions || []} />
        </div>
        <div className="tab-pane fade" id="submitted" role="tabpanel" aria-labelledby="submitted-tab">
          <SubmissionsTable submissions={submitted || []} />
        </div>
        <div className="tab-pane fade" id="checked" role="tabpanel" aria-labelledby="checked-tab">
          <SubmissionsTable submissions={checked || []} />
        </div>
        <div className="tab-pane fade" id="published" role="tabpanel" aria-labelledby="published-tab">
          <SubmissionsTable submissions={published || []} />
        </div>
      </div>
    </Fragment>
  );
}

export default SubmissionsTabs
import React from 'react'
import { NavLink } from "react-router-dom";

const Sidenav = () => {

  return (
    <nav className="navbar navbar-vertical navbar-vertical-sm fixed-start navbar-expand-md navbar-light border-right">
      <div className="container-fluid">
        <a className="navbar-brand text-primary fw-bold">LMS</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink end={true} to="/student" className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} title="Dashboard">
                <span className="fe fe-home" />
                <span className="d-inline d-md-none">Dashboard</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/student/my/classes" className="nav-link" title="My Classes">
                <span className="fe fe-book-open" />
                <span className="d-inline d-md-none">My Classes</span>
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink to="/student/classes" className="nav-link" title="Search Classes">
                <span className="fe fe-search" />
                <span className="d-inline d-md-none">Search Classes</span>
              </NavLink>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Sidenav

export const sortCourseContents = (courseModules) => {
    return sortByOrdinality(courseModules)
            .flatMap(module => (
                sortByOrdinality(module.lessons)
                    .flatMap(lesson => sortByOrdinality(lesson.courseContents))
            ));
}

export const sortByOrdinality = (records) => {
    return [...records].sort((a, b) => a.ordinality - b.ordinality);
}

import React from "react";
import formatDate from "../../utils/formatDate";

const SectionHeader = ({
    lesson,
    courseContent,
}) => {
    return (
        <div className="section-header d-flex flex-column">
            <span className="lesson-name">{lesson.name}</span>
            <span className="content-name">{courseContent.name}</span>
            <div className="content-info">
                <span className="text-capitalize">{courseContent.contentType}</span>
                <span className="mx-1">|</span>
                <span>Last update was on {formatDate(courseContent.updatedAt)} {!!courseContent.lastUpdateByUser ? `by ${courseContent.lastUpdateByUser.name}` : null}</span>
            </div>
            <div className="descriptions">
                {!!lesson?.description ? (
                    <div>
                        <span>About this lesson:</span>
                        <span>{lesson.description}</span>
                    </div>
                ) : null}
                {!!courseContent.description ? (
                    <div>
                        <span>About this {courseContent.contentType}:</span>
                        <span>{courseContent.description}</span>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

SectionHeader.defaultProps = {

};

export default SectionHeader;
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import ActivityTabs from "./submissions/ActivityTabs";
import SidebarToggler from "../../content_builder/sidebar/SidebarToggler";
import useSubmissionQuery from "../../../hooks/useSubmissionQuery";
import { Loader } from "../../utils/Loader";

const MySubmissions = ({ userId }) => {
    const [submissions, setSubmissions] = useState([]);
    const hasSubmissions = Boolean(submissions.length);
    const { course } = useOutletContext();

    const { fetchSingleSubmissionAsync, fetchSubmissionsAsyncLoading } = useSubmissionQuery();

    useEffect(() => {
        fetchSingleSubmissionAsync({ variables: { userId, slug: course.slug } })
            .then(res => setSubmissions(res.data.fetchSubmissions));
    }, [])


    return (
        <div className="col-12 col-md-9 course-main-panel">
            <Breadcrumbs crumbs={[{ name: "My Submissions" }]} />
            <h2 className="mb-4">My Submissions</h2>
            <SidebarToggler text="Navigate course" />
            {fetchSubmissionsAsyncLoading ? <Loader /> : null}
            <ActivityTabs submissions={submissions} />
        </div>
    );
}

export default MySubmissions;
import { useMutation } from "@apollo/client";
import { CREATE_SUBMISSION, UPDATE_SUBMISSION } from "../graphql/mutations";
import { useState } from "react";

const useSubmissionMutation = () => {

    function onError(error) {
        alert("Failed to save answers. Please contact an administrator!")
    }

    const [createSubmissionMutation, { loading: createLoading }] = useMutation(CREATE_SUBMISSION, {
        onError: onError,
    });

    const [updateSubmissionMutation, { loading: updateLoading }] = useMutation(UPDATE_SUBMISSION, {
        onError: onError,
    });

    function createSubmission(variables, callback = null) {
        createSubmissionMutation({ variables })
            .then(res => {
                alert("Answers saved.");
                if (callback) callback();
            })
    }

    function updateSubmission(variables, callback = null) {
        updateSubmissionMutation({ variables })
            .then(res => {
                alert("Answers saved.");
                if (callback) callback();
            })
    }

    return {
        createSubmission,
        createLoading,
        updateSubmission,
        updateLoading,
    };
}

export default useSubmissionMutation;
import React, { useState } from 'react';

const CourseModuleDrag = ({ courseModule, index, handleSort }) => {

  const [isDropTarget, setDropTarget] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    setDropTarget(true);
  }

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDropTarget(false);
  }

  const handleDrop = (e) => {
    e.preventDefault();
    setDropTarget(false);

    const dataId = e.dataTransfer.getData('module-id');
    if (dataId) {
      handleSort({ dataId, index });
    }
  }

  const handleDrag = (e) => {
    e.dataTransfer.setData('module-id', courseModule.id);
  }

  return (
    <div
      className={`py-2 mb-2 d-flex flex-column ${isDropTarget ? 'bg-info text-white' : ''}`}
      draggable="true"
      onDragOver={(e) => handleDragOver(e)}
      onDragLeave={(e) => handleDragLeave(e)}
      onDrop={(e) => handleDrop(e)}
      onDragStart={(e) => handleDrag(e)}
      title={courseModule.name}
    >
      <span><strong>{courseModule.name}</strong></span>
      <span className="mb-0 text-muted">{courseModule.lessons.length} Lessons</span>
      {/* <div className="course-content-block bg-white py-3 my-0">
        {courseModule.description || "No description added yet."}
      </div> */}
    </div>
  );
}

export default CourseModuleDrag
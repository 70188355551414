import React, { Fragment } from "react";
import QuestionRenderer from "./QuestionRenderer";

const Question = ({ block: { id, data }, answer, dispatch }) => {

    return (
        <Fragment>
            {!!data.question ? (
                <li className="question-list-item py-2">
                    <QuestionRenderer
                        blockId={id}
                        data={data}
                        answer={answer}
                        dispatch={dispatch} />
                </li>
            ) : null}
        </Fragment>
    );
}

export default Question;

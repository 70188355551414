import React, { Fragment } from 'react'
import Button from '../../elements/Button';

const CourseModuleForm = ({
    mutateModule,
    moduleName,
    setModuleName,
    moduleDescription,
    setModuleDescription,
    handleClose,
    loading,
    error,
}) => {

    return (
        <Fragment>
            <form onSubmit={(e) => mutateModule(e)}>
                <div className="my-1">
                    <label htmlFor={`module-${moduleName}`}>Title</label>
                    <input
                        type="text"
                        id={`module-${moduleName}`}
                        className="form-control"
                        autoFocus
                        defaultValue={moduleName}
                        disabled={!!loading}
                        placeholder="Module title"
                        onChange={(e) => setModuleName(e.target.value)} />
                </div>
                {/* <div className="my-1">
                    <label htmlFor={`module-${moduleName}-description`}>Description</label>
                    <textarea
                        id={`module-${moduleName}-description`}
                        className="form-control"
                        defaultValue={moduleDescription}
                        disabled={!!loading}
                        onChange={(e) => setModuleDescription(e.target.value)} />
                </div> */}

                <div className="d-flex justify-content-end my-2">
                    <Button type="secondary" text="Cancel" className="btn btn-transparent bg-white" disabled={!!loading} onClick={() => handleClose()} />
                    <Button type="primary" disabled={!!loading} text="Save" />
                </div>
            </form>
            { !!error ? (
                error.graphQLErrors.map(({ message }, index) => {
                return <p key={index} className="text-danger small">{message}</p>
                })
            ) : null }
        </Fragment>
    );
}

export default CourseModuleForm
import React, { useState, useRef, useEffect } from 'react'
import { v4 as uuid } from 'uuid';
import { BLOCK_TYPES, ACTIVITY_BLOCKS } from 'blocks';
import { PlusCircle } from "react-feather";

const AddBlock = ({ block: { id, type, data }, dispatch, contentType }) => {

    const [isVisible, toggleVisibility] = useState(false);
    const [activityBlocks, setActivityBlocks] = useState(ACTIVITY_BLOCKS);
    const [blockTypes, setBlockTypes] = useState(BLOCK_TYPES);

    const ref = useRef(null);
    const searchRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                toggleVisibility(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [ref]);

    const handleSelect = ({ newBlock }) => {
        toggleVisibility(false);

        if (type === 'p' && data.text === '') {
            const payload = { id, type: newBlock.type, data: newBlock.data }
            dispatch({ type: 'convert', payload });
        } else {
            const payload = { id: uuid(), type: newBlock.type, data: newBlock.data }
            dispatch({ type: 'add', payload, id });
        }
    }

    function filterBlockTypes(e) {
        setActivityBlocks(current => {
            if (e.target.value === "") return ACTIVITY_BLOCKS;
            return ACTIVITY_BLOCKS?.filter(block => block.label.toLowerCase().includes(e.target.value.toLowerCase()))
        });
        setBlockTypes(current => {
            if (e.target.value === "") return BLOCK_TYPES;
            return BLOCK_TYPES?.filter(block => block.label.toLowerCase().includes(e.target.value.toLowerCase()))
        });
    }

    useEffect(() => {
        if (!isVisible || !searchRef.current) return;
        searchRef.current.focus();
    }, [isVisible])

    return (
        <div ref={ref} className="dropdown">
            <a
                href="#"
                className="btn btn-transparent px-1"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => toggleVisibility(!isVisible)}>
                    <PlusCircle />
            </a>
            <div className="dropdown-menu dropdown-menu-start position-absolute px-2" >
                <input
                    type="text"
                    id={`block-search-${id}`}
                    ref={searchRef}
                    className="form-control my-2"
                    placeholder="Search block type"
                    autoComplete="off"
                    onChange={filterBlockTypes}
                    autoFocus />
                <div className="overflow-auto" style={{minWidth: "320px", maxHeight: "280px"}}>
                    { contentType !== 'lecture' && activityBlocks?.map((block) => (
                        <a
                            className="cursor-pointer"
                            key={block.type}
                            onClick={() => handleSelect({ newBlock: block }) }
                        >
                            <li className="list-group-item p-3">
                                <h4>{block.label}</h4>
                                <p className="mb-0">{block.description}</p>
                            </li>
                        </a>
                    )) }
                    { blockTypes?.map((block) => (
                        <a
                            className="cursor-pointer"
                            key={block.type}
                            onClick={() => handleSelect({ newBlock: block }) }
                        >
                            <li className="list-group-item p-3">
                                <h4>{block.label}</h4>
                                <p className="mb-0">{block.description}</p>
                            </li>
                        </a>
                    )) }
                </div>
            </div>
        </div>
    );
}

export default AddBlock
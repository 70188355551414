import React from "react"

export const Loader = ({ loading = true }) => {
    if (!loading) return null;

    return (
        <div className="d-flex justify-content-center w-100 py-8">
            <div className="loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

import React from "react";

const PageInfo = ({
    title,
    subtitle,
    callToActionText,
    callToActionUrl,
}) => {
    return (
        <div className="d-flex flex-column align-items-center text-center w-100 py-8 bg-white border border-3 border-light rounded-2">
            <h1 className="my-1">{title}</h1>
            {subtitle ? (
                <h5 >{subtitle}</h5>
            ) : null}
            {(!!callToActionText && !!callToActionUrl) ? (
                <a href={callToActionUrl}
                   className="text-decoration-underline">
                    {callToActionText}
                </a>
            ) : null}
        </div>
    );
}

PageInfo.defaultProps = {
    title: "Title",
    subtitle: null,
    callToActionText: null,
    callToActionUrl: null,
};

export default PageInfo;
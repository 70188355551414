import React, { useState, useEffect, useRef } from 'react'
import Button from '../../elements/Button';

const Dropdown = ({ toggleVisibility, handleDestroy, loading, editText }) => {

  const [confirmDelete, toggleConfirm] = useState(false);

  // Set confirm delete to false if delete button (ref) is not clicked
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        toggleConfirm(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref]);

  return (
    <div className="dropdown card-dropdown editor-dropdown">
      <a
        href="#"
        className="dropdown-ellipses dropdown-toggle text-dark"
        role="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">
        <i className="fe fe-more-vertical" />
      </a>
      <div className="dropdown-menu dropdown-menu-end" style={{ width: "8rem" }}>
        <div className="d-flex flex-column">
          <Button
              type="secondary"
              text={editText}
              className="btn btn-transparent h3 mb-0 text-start"
              onClick={() => toggleVisibility(true)}
              disabled={!!loading}/>
          <Button
              type="secondary"
              text="Delete"
              className="btn btn-transparent h3 mb-0 text-start"
              onClick={() => handleDestroy()}
              disabled={!!loading}/>
        </div>
      </div>
    </div>
  );
}

export default Dropdown
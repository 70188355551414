import React, { Fragment } from 'react'
import Button from '../../elements/Button';

const CourseContentForm = ({ mutateContent, contentName, setContentName, handleClose,
  loading, error }) => {

  return (
    <Fragment>
      <form onSubmit={(e) => mutateContent(e)}>
        <input
          type="text"
          className="form-control"
          autoFocus
          defaultValue={contentName}
          disabled={!!loading}
          placeholder="Section name"
          onChange={(e) => setContentName(e.target.value)} />
          <div className="d-flex justify-content-end my-2">
              <Button type="secondary" text="Cancel" className="btn btn-transparent bg-white" disabled={!!loading} onClick={() => handleClose()} />
              <Button type="primary" disabled={!!loading} text="Save" />
          </div>
      </form>
      { !!error ? (
        error.graphQLErrors.map(({ message }, index) => {
          return <p key={index} className="text-danger small">{message}</p>
        })
      ) : null }
    </Fragment>
  );
}

export default CourseContentForm
import React, { useState } from "react"
import { Loader } from "../../../utils/Loader";

const YOUTUBE_URL_REGEX = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
const YOUTUBE_URL_ID_REGEX = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
const YOUTUBE_EMBED_URL_PREFIX = "https://www.youtube.com/embed/";

const Embed = ({ block, dispatch, readOnly }) => {

    return (
        <>
            {readOnly
                ? <EmbedPreview block={block} />
                : <EmbedForm block={block} dispatch={dispatch} />}
        </>
    );
}

const EmbedForm = ({ block, dispatch }) => {
    const [payload, setPayload] = useState(block)

    const handleUrlInputChange = (e) => {
        const url = e.target.value;

        // youtube will be the default type for now, handle when other embed types are for dev
        const blockData = {
            ...payload,
            data: {
                url: url,
                type: "youtube",
            }
        }
        setPayload(blockData)
        dispatch({ type: 'update', payload: blockData });
    }

    return (
        <div>
            <input
                type="text"
                id={`embed-input-${block?.id}`}
                className="block-input"
                placeholder="Youtube url"
                defaultValue={block?.data?.url}
                onChange={handleUrlInputChange}
            />
            <EmbedPreview block={payload} />
        </div>
    );
}

function getURLId(url) {
    if (!url) return null;

    var match = url.match(YOUTUBE_URL_ID_REGEX);
    return (match && match[1].length==11) ? match[1] : null;
}

const EmbedPreview = ({
    block
}) => {
    const url = block?.data?.url;
    const urlId = getURLId(url);
    const URLInvalid =
            !url ||
            !YOUTUBE_URL_REGEX.test(url) ||
            !urlId;
    const [loading, setLoading] = useState(true);
    const hasURL = Boolean((url || '').length);

    return (
        <>
            {(URLInvalid && hasURL) ? (
                <p className="text-danger">Invalid URL</p>
            ) : null}
            {!URLInvalid ? (
                <iframe
                    className="course-embed-iframe mt-4"
                    src={YOUTUBE_EMBED_URL_PREFIX + urlId}
                    title="YouTube video player"
                    allowFullScreen
                    onLoad={() => setLoading(false)}>
                </iframe>
            ) : null}
            <Loader loading={loading && hasURL} />
        </>
    );
}

export default Embed;

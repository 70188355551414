import React from 'react';
import { useMutation } from '@apollo/client';
import { FETCH_COURSE_USERS } from 'graphql/queries';
import { DESTROY_COURSE_USER } from 'graphql/mutations';
import { Link } from "react-router-dom";

const CourseUser = ({ courseUser, courseUser: { course }, userId, allowEnlistment }) => {

  const [destroyCourseUser, { loading }] = useMutation(DESTROY_COURSE_USER, {
    onCompleted: (data) => alert('Successfully delisted.'),
    onError: (error) => alert('Failed to delist.'),
    update(cache, { data: { destroyCourseUser } }) {
      cache.updateQuery({
        query: FETCH_COURSE_USERS,
        variables: { userId },
        overwrite: true,
      }, (data) => ({
        fetchCourseUsers: data.fetchCourseUsers.filter(courseUser => courseUser.id !== destroyCourseUser.id),
      }));
      cache.evict({
        id: 'ROOT_QUERY',
        field: 'fetchCourses',
      });
      cache.gc();
    }
  });

  const handleDelist = () => {
    destroyCourseUser({ variables: { id: courseUser.id } })
  }

  return (
    <tr>
      <td>{course.name}</td>
      <td>{course.teacher ? course.teacher.name : null}</td>
      <td></td>
      <td className="d-flex align-items-center">
        <Link to={`/student/my/classes/${course.slug}`} className="text-secondary me-5">
          <span className="fe fe-eye h1" />
        </Link>
        { allowEnlistment && (
          <button
            type="button"
            className="btn btn-outline-dark"
            disabled={!!loading}
            onClick={() => handleDelist()}>
            Delist
          </button>
        )}
      </td>
    </tr>
  );
}
  
export default CourseUser
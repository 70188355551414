import React, { Fragment, useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Breadcrumbs from "../elements/Breadcrumbs";
import Section from "./Section";
import SectionNavigator from "./SectionNavigator";
import { Loader } from "../../components/utils/Loader";
import useCourseContentProgress from "../../hooks/useCourseContentProgress";
import { sortCourseContents } from "../../utils/sortCourseContents";
import PageInfo from "../elements/PageInfo";
import { useFetchCourseContent } from "../../hooks/useFetchCourseContent";

const MainPanel = ({ course }) => {

    let params = useParams();
    let navigate = useNavigate();
    const { confirmDoneContent, confirmReadContent } = useCourseContentProgress();
    const { fetchCourseContentAsync, fetchCourseContentAsyncLoading: loading } = useFetchCourseContent();
    const courseContents = sortCourseContents(course.courseModules);
    const lastCourseContent = courseContents.slice(-1)[0];

    const [section, setSection] = useState(null);
    const [crumbs, setCrumbs] = useState([]);

    // On load, navigate to first section
    useEffect(() => {
        if (!params.slug2) {
            const firstCourseContent = courseContents[0];

            if (firstCourseContent) {
                navigate(`/student/my/classes/${params.slug}/content/${firstCourseContent.slug}`, { replace: true });
            }
        }
    }, []);

    useEffect(() => {
        let prevContent = section?.courseContent;
        fetchContent();
        if (prevContent && prevContent?.slug !== params?.slug2) {
            confirmDoneContent(prevContent, () => {
                if (prevContent?.slug === lastCourseContent.slug) {
                     // reload page when marking last courseContent as done
                     // this will fetch new course data
                    window.location.reload();
                } else {
                    fetchContent();
                }
            });
        }
    }, [params])

    function fetchContent() {
        fetchCourseContentAsync({ variables: { slug: params.slug2, courseId: course.id } })
        .then(res => {
            let data = res.data;
            if (data && data.fetchCourseContent) {
                const courseContent = data?.fetchCourseContent;
                const lesson = courseContent.lesson;
                const courseModule = courseContent.courseModule;
                confirmReadContent(courseContent);
                setSection({ courseModule, lesson, courseContent });
                setCrumbs([
                    { text: courseModule.name },
                    { text: lesson.name },
                    { text: courseContent.name },
                ])
            }
        });
    }

    return (
        <div className="col-12 col-md-9 course-main-panel">
            {loading ? <Loader /> : null}
            {(!!section && !loading) ? (
                <Fragment>
                    <Breadcrumbs crumbs={crumbs} />
                    <Section section={section} fetchContent={fetchContent} />
                </Fragment>
            ) : null}
            {(!section && !loading) ? (
                <PageInfo
                    title="Course content not found"
                    subtitle="Please check if you have the correct url or use the content selector below" />
            ) : null}
            <div className="py-5">
                <SectionNavigator course={course} isStudent />
            </div>
        </div>
    );
}

export default MainPanel;
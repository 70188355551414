import React from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { useFetchCourse } from "hooks/useFetchCourse";

const Breadcrumbs = ({ crumbs, crumbUrlPrefix }) => {
    const params = useParams();
    const location = useLocation();
    const { data } = useFetchCourse({ slug: params.slug });
    const hasCrumbs = Boolean(crumbs.length);
    const onStudent = location.pathname.includes("/student/");

    return (
        <nav className="syllabus-builder-breadcrumbs" aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item text-truncate">
                    <a href={crumbUrlPrefix} className="text-muted">My {onStudent ? "Classes" : "Courses"}</a>
                </li>
                {data && data.fetchCourse ? (
                    <li className={`breadcrumb-item text-truncate ${!hasCrumbs ? "text-primary active" : ""}`}>
                        {hasCrumbs ? (
                            <Link to={`${crumbUrlPrefix}${params.slug}`} className="text-muted">{data.fetchCourse.name}</Link>
                        ) : data.fetchCourse.name}
                    </li>
                ) : null}
                {crumbs.map((crumb, index) => (
                    <li key={index} className={`breadcrumb-item text-truncate ${index == crumbs.length - 1 ? "text-primary active" : ""}`}>
                        {crumb.url ? (
                            <Link to={crumb.url} className="text-muted">{crumb.name}</Link>
                        ) : crumb.name}
                    </li>
                ))}
            </ol>
        </nav>
    );
}

export default Breadcrumbs;
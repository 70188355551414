import React from "react";
import User from "./User";

const Users = ({ users, courseUsers, courseId }) => {

    return (
        <table className="table mt-4">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">LRN</th>
                    <th scope="col">Year Level</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {users.map((_user) => (
                    <User
                        key={_user.id}
                        user={_user}
                        courseId={courseId}
                        isEnlisted={courseUsers && courseUsers.map(({ user }) => user.id).includes(_user.id)} />
                ))}
            </tbody>
        </table>
    );
}

export default Users;
import React from 'react';
import CourseUser from './CourseUser';

const CourseUsers = ({ courseUsers, userId, allowEnlistment }) => {

  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Class Name</th>
          <th scope="col">Class Advisor</th>
          <th scope="col">Enlistment Date</th>
          { allowEnlistment && (<th scope="col"></th>)}
        </tr>
      </thead>
      <tbody>
        { courseUsers.map((courseUser) => (
          <CourseUser key={courseUser.id} courseUser={courseUser} userId={userId} allowEnlistment={allowEnlistment} />
        )) }
      </tbody>
    </table>
  );
}
  
export default CourseUsers
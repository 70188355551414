import React, { Fragment, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Button from "../../../elements/Button";

const SubmissionsTable = ({ submissions }) => {

    let params = useParams();

    const itemsPerPage = 10;

    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(0);

    const [sortCriteria, setSortCriteria] = useState("");
    const [sortReverse, setSortReverse] = useState(false);

    // On filter select, reset sort rules and return to first page
    useEffect(() => {
        setSortCriteria("");
        setSortReverse(false);
        setPage(0);
    }, [submissions]);

    useEffect(() => {
        const sortedItems = [...submissions];
        const sortValue = sortReverse ? -1 : 1;

        if (sortCriteria === "name") {
            sortedItems.sort((a, b) => (a.courseContent.name).localeCompare(b.courseContent.name) * sortValue);
        } else if (sortCriteria === "lesson") {
            sortedItems.sort((a, b) => (a.lesson.name).localeCompare(b.lesson.name) * sortValue);
        } else if (sortCriteria === "date") {
            sortedItems.sort((a, b) => (a.submitDate || "").localeCompare(b.submitDate || "") * sortValue);
        } else if (sortCriteria === "score") {
            sortedItems.sort((a, b) => (a.totalScore - b.totalScore) * sortValue);
        }

        const offset = (page * itemsPerPage) % submissions.length;
        setCurrentItems(sortedItems.slice(offset, offset + itemsPerPage));
        setPageCount(Math.ceil(submissions.length / itemsPerPage));
    }, [page, itemsPerPage, sortCriteria, sortReverse, submissions]);

    const handlePageClick = (e) => {
        setPage(e.selected);
    };

    const handleSort = (criteria) => {
        if (criteria === sortCriteria) {
            setSortReverse(sortReverse => !sortReverse);
        } else {
            setSortCriteria(criteria);
            setSortReverse(false);
        }
    }

    const sortIcon = sortReverse ? "arrow-down" : "arrow-up";

    return (
        <Fragment>
            <div className="w-100 table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th className="p-1">
                                <Button
                                    text=""
                                    className="p-0 m-0 d-flex align-items-center justify-content-between btn btn-transparent text-muted text-uppercase fw-bold"
                                    onClick={() => handleSort("name")}>
                                        <span>Name</span>
                                        <span className={`fe fe-${sortCriteria === "name" ? sortIcon : "align-justify"} ms-2`} />
                                </Button>
                            </th>
                            <th className="p-1">
                                <Button
                                    text=""
                                    className="p-0 m-0 d-flex align-items-center justify-content-between btn btn-transparent text-muted text-uppercase fw-bold"
                                    onClick={() => handleSort("lesson")}>
                                        <span>Lesson</span>
                                        <span className={`fe fe-${sortCriteria === "lesson" ? sortIcon : "align-justify"} ms-2`} />
                                </Button>
                            </th>
                            <th className="p-1" width="15%">
                                <Button
                                    text=""
                                    className="p-0 m-0 d-flex align-items-center justify-content-between btn btn-transparent text-muted text-uppercase fw-bold"
                                    onClick={() => handleSort("date")}>
                                        <span>Date Submitted</span>
                                        <span className={`fe fe-${sortCriteria === "date" ? sortIcon : "align-justify"} ms-2`} />
                                </Button>
                            </th>
                            <th className="p-1" width="10%">Status</th>
                            <th className="p-1" width="10%">
                                <Button
                                    text=""
                                    className="p-0 m-0 d-flex align-items-center justify-content-between btn btn-transparent text-muted text-uppercase fw-bold"
                                    onClick={() => handleSort("score")}>
                                        <span>Score</span>
                                        <span className={`fe fe-${sortCriteria === "score" ? sortIcon : "align-justify"} ms-2`} />
                                </Button>
                            </th>
                            <th className="p-1" width="10%">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((submission) => (
                            <tr key={submission.id}>
                                <td>{submission.courseContent.name}</td>
                                <td>{submission.lesson.name}</td>
                                <td >{submission.formatSubmitDate}</td>
                                <td className="text-capitalize">{submission.status}</td>
                                <td>{submission.status === "published" ? submission.totalScore : "Unpublished"}</td>
                                <td>
                                    <Link to={`/student/my/classes/${params.slug}/submissions/${submission.id}`}>
                                        View
                                    </Link>
                                </td>
                            </tr>
                        ))}
                        {!Boolean(submissions.length) ? (
                            <tr>
                                <td colSpan="6">
                                    <div className="text-center text-muted"><h3>No submissions found.</h3></div>
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </table>
            </div>
            <ReactPaginate
                breakLabel="..."
                nextLabel={<span className="fe fe-chevron-right" />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<span className="fe fe-chevron-left" />}
                renderOnZeroPageCount={null}
                forcePage={page}
                containerClassName="pagination align-items-center justify-content-md-end justify-content-center"
                pageClassName="page"
                activeClassName="rounded-circle bg-light"
                disabledLinkClassName="text-muted"
            />
        </Fragment>
    );
}

export default SubmissionsTable
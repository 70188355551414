import React, { useEffect, useState } from "react";
import { Outlet, matchPath, useLocation, useParams } from "react-router-dom";
import CourseNav from "./CourseNav";
import SidebarDrawer from "../../content_builder/sidebar/SidebarDrawer";
import useCourseQuery from "../../../hooks/useCourseQuery";
import { Loader } from "../../../components/utils/Loader";
import PageInfo from "../../elements/PageInfo";

const Course = () => {
    const params = useParams();
    const [course, setCourse] = useState(undefined);
    const { fetchCourseAsync, fetchCourseAsyncLoading } = useCourseQuery();

    useEffect(() => {
        fetchCourseAsync({ variables: { slug: params.slug } })
            .then(res => setCourse(res.data.fetchCourse));
    }, []);

    const onSubmissionShow = pageMatchesUrl("/student/my/classes/:slug/submissions/:submissionId");
    const onContentViewer = pageMatchesUrl("/student/my/classes/:slug/content")
                            || pageMatchesUrl("/student/my/classes/:slug/content/:slug2");
    const showContainerSideNav = !onSubmissionShow && !onContentViewer;

    return (
        <>
            {fetchCourseAsyncLoading ? (
                <Loader />
            ) : null}
            {(!fetchCourseAsyncLoading && !!course) ? (
                <div className="d-flex m-0">
                    <SidebarDrawer visible={showContainerSideNav}>
                        <CourseNav  key="course-containter-sidebar" course={course} visible={showContainerSideNav} onDrawer />
                    </SidebarDrawer>
                    <CourseNav  key="course-containter-sidebar" course={course} visible={showContainerSideNav} />
                    <Outlet context={{ course }} />
                </div>
            ) : null}
            {(!fetchCourseAsyncLoading && course === null) ? (
                <PageInfo
                    title="Class not found"
                    callToActionText="Go to My Classes"
                    callToActionUrl="/student/my/classes" />
            ) : null}
        </>
    );
}

function pageMatchesUrl(url) {
    const location = useLocation();
    return !!matchPath({ path: url, exact: true, strict: true }, location.pathname);
}

export default Course;
import React from 'react'
import CourseModules from './sidebar/CourseModules';
import { useLocation } from "react-router-dom";

const Sidebar = ({ course, readOnly, onDrawer = false }) => {
    const containerClasses = [
        "p-4 bg-light w-full",
        !onDrawer ? "sidebar col-3 d-none d-md-block" : "",
    ].join(" ");

  const location = useLocation();
  const backUrl = location.pathname.includes("/my/")
                    ? `/teacher/my/courses/${course.slug}`
                    : `/teacher/courses/${course.slug}`;
  return (
    <div className={containerClasses}>
      <a href={backUrl} className="d-inline-flex mb-4">
        <span className="fe fe-chevron-left me-2" />
        Back to course home page
      </a>
      <div className="mb-4">
        <h2 className="mb-2">{course.name}</h2>
        { course.teacher ? (
          <p>by <span className="text-primary">{course.teacher.name}</span></p>
        ) : null }
      </div>
      <CourseModules course={course} readOnly={readOnly} />
    </div>
  );
}

export default Sidebar;
import React, { useState } from 'react';
import { useParams, Link, useLocation } from "react-router-dom";

const CourseContentDrag = ({ courseContent, index, handleSort, readOnly }) => {

  let params = useParams();
  const location = useLocation();

  const [isDropTarget, setDropTarget] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    setDropTarget(true);
  }

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDropTarget(false);
  }

  const handleDrop = (e) => {
    e.preventDefault();
    setDropTarget(false);

    const dataId = e.dataTransfer.getData('content-id');
    if (dataId) {
      handleSort({ dataId, index });
    }
  }

  const handleDrag = (e) => {
    e.dataTransfer.setData('content-id', courseContent.id);
  }

  let url = location.pathname.includes("/my/")
                ? `/teacher/my/courses/${params.slug}/content/${courseContent.slug}`
                : `/teacher/courses/${params.slug}/content/${courseContent.slug}`;

  function toggleSidebarDrawer() {
    const backDrop = document.querySelectorAll(".offcanvas-backdrop");
    if (!Boolean(backDrop.length)) return;

    backDrop.forEach((element) => {
      element.dispatchEvent(new MouseEvent("mousedown"));
    });
  }

  return (
    <div
      className={`py-2 d-flex w-75 ${isDropTarget ? 'bg-info text-white' : ''}`}
      draggable="true"
      onDragOver={(e) => handleDragOver(e)}
      onDragLeave={(e) => handleDragLeave(e)}
      onDrop={(e) => handleDrop(e)}
      onDragStart={(e) => handleDrag(e)}
    >
      <div className={`px-1 me-3 h-50 bg-tropical-blue ${courseContent.slug === params.slug2 ? 'bg-primary text-white' : ''}`}>
        <span className={`fe fe-${!!courseContent.isActivity ? 'activity' : 'menu'}`} />
      </div>
      <Link
        draggable="false"
        onClick={toggleSidebarDrawer}
        to={url}
        className="text-dark" title={courseContent.name}>{courseContent.name}
      </Link>
    </div>
  );
}

export default CourseContentDrag
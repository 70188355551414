import React, { Fragment, useEffect, useReducer } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { FETCH_SUBMISSION } from "graphql/queries";
import Activity from "./submissions/Activity";
import Sidebar from "./submissions/Sidebar";
import SidebarDrawer from "../content_builder/sidebar/SidebarDrawer";
import currentPortal from "../../utils/currentPortal";
import PageInfo from "../elements/PageInfo";

const Submission = ({ readOnly = false }) => {

    let params = useParams();
    const isStudent = currentPortal() === "student";

    const course = useOutletContext()?.course;
    const { data, loading } = useQuery(FETCH_SUBMISSION, {
        variables: { slug: params.slug, id: params.submissionId }
    });

    const [activityState, dispatch] = useReducer(reducer, []);

    function reducer(state, action) {
        switch (action.type) {
            case "update":
                return state.map((answer) => (
                    answer.blockId === action.payload.blockId ? { ...answer, status: action.payload.status } : answer
                ));
            case "initialize":
                return action.payload;
            default:
                return state;
        }
    }

    // Initialize state for tracking checking progress
    useEffect(() => {
        if (data && data.fetchSubmission) {
            const { courseContent, status, answers } = data.fetchSubmission;
            const payload = courseContent.blocks.filter(block => block.type === "question").map(block => {
                let isChecked = false;
                let points = 0;
                let isCorrect = false;

                // If submission is marked as checked, get checking progress from submission
                if (["checked", "published"].includes(status)) {
                    const answer = answers.find(answer => answer.blockId === block.id);

                    if (answer && answer.status) {
                        isChecked = answer.status.isChecked;
                        points = answer.status.points;
                        isCorrect = answer.status.isCorrect;
                    }
                }

                return {
                    blockId: block.id,
                    status: { isChecked, points, isCorrect },
                }
            });

            dispatch({ type: "initialize", payload });
        }
    }, [data]);

    return (
        <div className="d-flex m-0 w-100 min-vh-100">
            {(data && data.fetchSubmission && !loading) ? (
                <Fragment>
                    <SidebarDrawer>
                        <Sidebar
                            key="submission-show-sidebar"
                            submission={data.fetchSubmission}
                            activityState={activityState}
                            onDrawer
                            readOnly={readOnly && isStudent} />
                    </SidebarDrawer>
                    <Sidebar
                            key="submission-show-sidebar"
                            submission={data.fetchSubmission}
                            activityState={activityState}
                            readOnly={readOnly && isStudent} />
                    <Activity
                        submission={data.fetchSubmission}
                        activityState={activityState}
                        dispatch={dispatch}
                        readOnly={readOnly && isStudent} />
                </Fragment>
            ) : null}
            {!loading && !data?.fetchSubmission ? (
                <PageInfo
                    title="Submission not found."
                    callToActionText={`Go to ${readOnly ? "My Submissions" : "Course Submissions"}`}
                    callToActionUrl={readOnly ? `/student/my/classes/${course.slug}/submissions` : `/teacher/my/courses/${params.slug}/submissions`} />
            ) : null}
        </div>
    );
}

export default Submission
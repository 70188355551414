import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import Root from 'components/student_portal/Root';

document.addEventListener('turbolinks:load', () => {
  const root = document.querySelector("#student-portal");
  const token = document.querySelector('meta[name="csrf-token"]').content;

  if (!!root) {
    const cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            fetchCourses: {
              keyArgs: false,
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
          },
        },
      },
    });
    
    const client = new ApolloClient({
      uri: '/graphql',
      cache,
      headers: {
        'X-CSRF-Token': token
      }
    });
    const userId = root.getAttribute("data-id");

    ReactDOM.render(
      <ApolloProvider client={client} >
        <Root userId={userId} />
      </ApolloProvider>,
      root,
    )
  }
})

import React from "react";
import { Info } from "react-feather";
import Button from "./Button";

const Banner = ({
    text,
    type,
    buttonText,
    buttonOnClick,
}) => {
    const bannerClass = [
        "px-4 py-2 my-2",
        "d-flex justify-content-between",
        `border rounded-1 border-${type}`,
        `banner ${type}`,
    ].join(" ");
    return (
        <div className={bannerClass} role="alert">
            <div className="d-flex align-items-center" style={{ padding: "0.65rem 0" }}>
                <Info className={`text-${type}`} />
                <span className="mx-4">{text}</span>
            </div>
            <Button
                text={buttonText}
                visible={!!buttonText}
                onClick={buttonOnClick}
                className={`btn btn-transparent text-${type}`} />
        </div>
    );
}

Banner.defaultProps = {
    text: "Banner text",
    type: "info",
};

export default Banner;
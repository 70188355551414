import React, { Fragment } from 'react'
import { useQuery } from '@apollo/client';
import { FETCH_QUESTION } from 'graphql/queries';
import QuestionRenderer from 'components/editor/blocks/questions/QuestionRenderer';

const QuestionView = ({ questionId }) => {

  const { loading, data } =  useQuery(FETCH_QUESTION, { variables: { id: questionId } });

  return (
    <Fragment>
      <h2>Question Preview</h2>
      { !!loading ? (
        <p>Loading...</p>
      ) : null }
      { data && data.fetchQuestion ? (
        <div className="border col-6 p-3 mb-4">
          <QuestionRenderer
            blockId={data.fetchQuestion.id}
            data={{ question: data.fetchQuestion, points: 1 }}
            readOnly={true} />
        </div>
      ) : null }
    </Fragment>
  );
}

export default QuestionView
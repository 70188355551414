import React from 'react'
import { useMutation } from '@apollo/client';
import { REORDER_COURSE_MODULES } from 'graphql/mutations';
import CourseModule from './CourseModule';
import NewCourseModule from './NewCourseModule';

const CourseModules = ({ course, course: { courseModules }, readOnly }) => {

  const [reorderModules] = useMutation(REORDER_COURSE_MODULES);

  const handleSort = ({ dataId, index }) => {
    const sortedIds = [...courseModules]
      .sort((a, b) => a.ordinality - b.ordinality)
      .map(courseModule => courseModule.id)
      .filter(id => id !== dataId);
    const courseModuleIds = [...sortedIds.slice(0, index), dataId, ...sortedIds.slice(index)];

    reorderModules({ variables: { id: course.id, courseModuleIds } });
  }

  return (
    <ul className="list-group">
      { [...courseModules].sort((a, b) => a.ordinality - b.ordinality).map((courseModule, index) => (
        <CourseModule
          key={courseModule.id}
          courseModule={courseModule}
          course={course}
          index={index}
          handleSort={handleSort}
          readOnly={readOnly} />
      )) }
      { !readOnly ? (
        <NewCourseModule course={course} />
      ) : null }
    </ul>
  );
}

export default CourseModules
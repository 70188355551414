import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Course from "./Course";
import HomePage from "./HomePage";
import Submissions from "./Submissions";
import Submission from "./Submission";
import CourseEnlistment from "./CourseEnlistment";
import ContentBuilder from "components/content_builder/ContentBuilder";
import StudentProgressReport from "./StudentProgressReport";

const Root = ({ courseId }) => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/teacher/my/courses/:slug" element={<Course />}>
                    <Route path="" element={<HomePage />} />
                    <Route path="submissions" element={<Submissions />} />
                    <Route path="students" element={<CourseEnlistment courseId={courseId} />} />
                    <Route path="student-progress-report" element={<StudentProgressReport />} />
                    <Route path="*" element={<p>404</p>} />
                </Route>
                <Route path="/teacher/my/courses/:slug/content" element={<ContentBuilder />} />
                <Route path="/teacher/my/courses/:slug/content/:slug2" element={<ContentBuilder />} />
                <Route path="/teacher/my/courses/:slug/submissions/:submissionId" element={<Submission />} />
                <Route path="/teacher/courses/:slug/content" element={<ContentBuilder readOnly={true} />} />
                <Route path="/teacher/courses/:slug/content/:slug2" element={<ContentBuilder readOnly={true} />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Root
import React, { useState } from 'react'
import { useMutation } from '@apollo/client';
import { LESSON_FIELDS } from 'graphql/fragments';
import { CREATE_LESSON } from 'graphql/mutations';
import LessonForm from './LessonForm';

const NewLesson = ({ courseModule }) => {

  const [isVisible, toggleVisibility] = useState(false);
  const [lessonName, setLessonName] = useState("");

  const [createLesson, { loading, error }] = useMutation(CREATE_LESSON, {
    onCompleted: (data) => handleClose(),
    update(cache, { data: { createLesson: { lesson } } }) {
      cache.modify({
        id: cache.identify(courseModule),
        fields: {
          lessons(currentLessons) {
            const newLesson = cache.writeFragment({
              data: lesson,
              fragment: LESSON_FIELDS
            });

            return [...currentLessons, newLesson];
          },
        },
      });
    }
  });

  const mutateLesson = (e) => {
    e.preventDefault();
    createLesson({ variables: { name: lessonName, courseModuleId: courseModule.id } });
  }

  const handleClose = () => {
    setLessonName("");
    toggleVisibility(false);
  }

  return (
    <div className="py-2">
      { !!isVisible ? (
        <LessonForm
          mutateLesson={mutateLesson}
          lessonName={lessonName}
          setLessonName={setLessonName}
          handleClose={handleClose}
          loading={loading}
          error={error} />
      ) : (
        <a className="cursor-pointer" onClick={() => toggleVisibility(true)}>
          + Add Lesson
        </a>
      ) }
    </div>
  );
}

export default NewLesson
import React, { Fragment } from 'react'
import { useQuery, useMutation } from '@apollo/client';
import { FETCH_QUESTION_DATA } from 'graphql/queries';
import { UPDATE_QUESTION } from 'graphql/mutations';
import QuestionForm from './QuestionForm';

const QuestionEditor = ({ questionId }) => {

    const { loading, error, data } =  useQuery(FETCH_QUESTION_DATA, { variables: { id: questionId } });

    const [updateQuestion, { error: _error }] = useMutation(UPDATE_QUESTION, {
        onCompleted: () => {
            alert('Question saved.');
            window.location.href = `/teacher/questions/${questionId}`;
        },
    });

    const handleSave = (variables) => {
        updateQuestion({ variables });
    }

    if (loading) return "Loading...";
    if (error) return "An error occured";

    return (
        <Fragment>
            <h2>Edit Question</h2>
            <QuestionForm
                question={data.fetchQuestion}
                handleSave={handleSave}
                error={_error}
            />
        </Fragment>
    );
}

export default QuestionEditor
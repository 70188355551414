import React, { Fragment, useEffect } from "react";
import Editor from "./Editor";
import SidebarToggler from "../content_builder/sidebar/SidebarToggler";
import SplashScreen from "../elements/SplashScreen";
import { useParams } from "react-router-dom";
import SectionHeader from "../elements/SectionHeader";
import Banner from "../elements/Banner";

const Section = ({ section, fetchContent }) => {

    const { courseContent, lesson } = section;
    const params = useParams();
    const preRequisite = courseContent.userPrerequisiteStatus;
    const userSubmission = courseContent.userSubmission;
    const prerequisiteFulfilled = preRequisite.status === "done";
    const splashMessage = preRequisite.contentType === "lecture"
        ? `You have to mark ${preRequisite.name} as done to access this section`
        : `You have to submit ${preRequisite.name}'s activity to access this section`;

    const showSubmittedBanner = !!courseContent.isActivity &&
                                !!userSubmission &&
                                userSubmission.status !== "draft";
    return (
        <Fragment>
            {prerequisiteFulfilled ? (
                <>
                    {showSubmittedBanner ? (
                        <Banner
                            type="primary"
                            text="Answers Submitted!"/>
                    ) : null}
                    <SectionHeader
                        lesson={lesson}
                        courseContent={courseContent} />
                    <SidebarToggler />
                    <Editor
                        courseContent={courseContent}
                        userSubmission={userSubmission}
                        fetchContent={fetchContent} />
                </>
            ) : null}
            {!prerequisiteFulfilled ? (
                <SplashScreen
                    title="Prerequisite not yet fulfilled"
                    subTitle={splashMessage}
                    primaryButtonText="View prerequisite"
                    primaryButtonUrl={`/student/my/classes/${params.slug}/content/${preRequisite.slug}`} />
            ) : null}
        </Fragment>
    );
}

export default Section
import React from 'react'
import { useMutation } from '@apollo/client';
import { REORDER_LESSONS } from 'graphql/mutations';
import Lesson from './Lesson';
import NewLesson from './NewLesson';

const Lessons = ({ courseModule, courseModule: { lessons }, readOnly }) => {

  const [reorderLessons] = useMutation(REORDER_LESSONS);

  const handleSort = ({ dataId, index }) => {
    const sortedIds = [...lessons]
      .sort((a, b) => a.ordinality - b.ordinality)
      .map(lesson => lesson.id)
      .filter(id => id !== dataId);
    const lessonIds = [...sortedIds.slice(0, index), dataId, ...sortedIds.slice(index)];

    reorderLessons({ variables: { id: courseModule.id, lessonIds } });
  }

  return (
    <ul className="list-group ps-4 py-2">
      { [...lessons].sort((a, b) => a.ordinality - b.ordinality).map((lesson, index) => (
        <Lesson
          key={lesson.id}
          lesson={lesson}
          courseModule={courseModule}
          index={index}
          handleSort={handleSort}
          readOnly={readOnly} />
      )) }
      { !readOnly ? ( 
        <NewLesson courseModule={courseModule} />
      ): null }
    </ul>
  );
}

export default Lessons
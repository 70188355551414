import { useLazyQuery, useQuery } from "@apollo/client";
import { FETCH_SUBMISSION, FETCH_SUBMISSIONS } from "../graphql/queries";

const useSubmissionQuery = () => {

    const [fetchSubmissionsAsync, { loading: fetchSubmissionsAsyncLoading }] = useLazyQuery(FETCH_SUBMISSIONS);
    const [fetchSingleSubmissionAsync, { loading: fetchSingleSubmissionAsyncLoading }] = useLazyQuery(FETCH_SUBMISSIONS);

    function fetchSubmissions(variables) {
        return useQuery(FETCH_SUBMISSIONS, { variables });
    }

    function fetchSingleSubmission(variables) {
        return useQuery(FETCH_SUBMISSION, { variables });
    }

    return {
        fetchSubmissionsAsync,
        fetchSubmissionsAsyncLoading,
        fetchSingleSubmissionAsync,
        fetchSingleSubmissionAsyncLoading,
        fetchSubmissions,
        fetchSingleSubmission,
    };
}

export default useSubmissionQuery;
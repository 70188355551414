import React from "react";
import { useParams, Link } from "react-router-dom";
import { useFetchCourse } from "hooks/useFetchCourse";

const Breadcrumbs = ({ crumbs }) => {
    let params = useParams();
    const { data } = useFetchCourse({ slug: params.slug });
    const hasCrumbs = Boolean(crumbs.length);

    return (
        <nav className="syllabus-builder-breadcrumbs" aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item text-truncate">
                    <a href="/student/my/classes" className="text-muted">My Classes</a>
                </li>
                {data && data.fetchCourse ? (
                    <li className={`breadcrumb-item text-truncate ${!hasCrumbs ? "text-primary current active" : ""}`}>
                        {hasCrumbs ? (
                            <Link to={`/student/my/classes/${params.slug}`} className="text-muted">{data.fetchCourse.name}</Link>
                        ) : data.fetchCourse.name}
                    </li>
                ) : null}
                {crumbs.map((crumb, index) => (
                    <li key={index} className={`breadcrumb-item text-truncate ${index == crumbs.length - 1 ? "text-primary current active" : ""}`}>
                        {crumb.url ? (
                            <Link to={crumb.url}>{crumb.name}</Link>
                        ) : crumb.name}
                    </li>
                ))}
            </ol>
        </nav>
    );
}

export default Breadcrumbs;
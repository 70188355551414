import React from "react";
import QuestionRenderer from "./QuestionRenderer";
import Button from "../../elements/Button";

const Question = ({
    block: { id, data },
    answer,
    checkData: { status },
    dispatch,
    readOnly,
    published,
}) => {

    const handleCheck = (isCorrect) => {
        const payload = {
            blockId: id,
            status: {
                isChecked: true,
                isCorrect,
                points: isCorrect ? data.points : 0,
            }
        }
        dispatch({ type: "update", payload });
    }

    const handlePoints = (e) => {
        let points = parseFloat(e.target.value) || 0;

        if (points > data.points || points < 0){
            e.target.value = data.points;
            points = data.points
        }

        const payload = {
            blockId: id,
            status: {
                isChecked: true,
                isCorrect: points !== 0,
                points,
            }
        }
        dispatch({ type: "update", payload });
    }

    return (
        <div className="mb-6">
            <li className="question-list-item py-2">
                {!!data.question ? (
                    <QuestionRenderer
                        blockId={id}
                        data={data}
                        answer={answer}
                        status={status}
                        dispatch={dispatch}
                        readOnly={readOnly}
                        published={published} />
                ) : null}
            </li>
            {!readOnly ? (
                <div className="d-flex flex-column flex-md-row justify-content-end">
                    <label className="d-flex justify-content-end align-items-center mx-4 my-3 my-md-0">
                        <span className="me-3">Points to give:</span>
                        <input
                            className="form-control form-control-sm border-0 points-input"
                            type="number"
                            step={1}
                            min={0}
                            max={data.points}
                            onChange={(e) => handlePoints(e)}
                            defaultValue={status.points} />
                    </label>
                    <div className="d-flex align-items-center justify-content-end">
                        <Button
                            text="Mark as wrong"
                            type="secondary"
                            className="btn btn-outline-danger mx-2"
                            onClick={() => handleCheck(false)}/>
                        <Button
                            text="Mark as correct"
                            type="secondary"
                            className="btn btn-primary mx-2"
                            onClick={() => handleCheck(true)}/>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default Question;
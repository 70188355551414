import React from 'react'

export const SplashScreen = ({
    title,
    subTitle,
    primaryButtonText,
    primaryButtonOnClick,
    secondaryButtonText,
    secondaryButtonOnClick,
    primaryButtonUrl,
    secondaryButtonUrl,
}) => {
    return (
        <div className="text-center bg-white py-6 mt-4 border border-3 border-light rounded-2 coi">
            <div className="d-flex justify-content-center pb-4">
                {/* <div className="rounded-pill d-flex justify-content-center align-items-center" style={{width: "50px", height: "50px", backgroundColor: "#DDE7F6"}}>
                    <img src={UploadFileOutline} alt="Add Icon" />
                </div> */}
            </div>
            <span className="fs-1 fw-bold">{title}</span>
            <p className="mb-5">{subTitle}</p>

            <div className="d-flex justify-content-center">
                {primaryButtonUrl ? (
                    <a href={primaryButtonUrl}
                       className="btn btn-primary mx-2" >{primaryButtonText}</a>
                ) : null}
                {secondaryButtonUrl ? (
                    <a to={secondaryButtonUrl}
                       className="btn btn-primary mx-2" >{secondaryButtonText}</a>
                ) : null}
            </div>
        </div>
    )
}
export default SplashScreen;
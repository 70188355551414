import React, { Fragment, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from '@apollo/client';
import { UPDATE_COURSE_CONTENT, DESTROY_COURSE_CONTENT } from 'graphql/mutations';
import CourseContentForm from './CourseContentForm';
import Dropdown from './Dropdown';
import CourseContentDrag from './CourseContentDrag';

const CourseContent = ({ lesson, courseContent, index, handleSort, readOnly }) => {

  let params = useParams();
  let navigate = useNavigate();

  const [isVisible, toggleVisibility] = useState(false);
  const [contentName, setContentName] = useState(courseContent.name);

  const [updateContent, { loading, error }] = useMutation(UPDATE_COURSE_CONTENT, {
    onCompleted: (data) => { handleClose(), handleNavigate(data) },
  });

  const [destroyContent, { loading: destroyLoading }] = useMutation(DESTROY_COURSE_CONTENT, {
    onCompleted: (data) => { handleNavigate() },
    update(cache, { data: { destroyCourseContent } }) {
      cache.modify({
        id: cache.identify(lesson),
        fields: {
          courseContents(currentCourseContents, { readField }) {
            return currentCourseContents.filter(courseContent => (
              destroyCourseContent.id !== readField('id', courseContent)
            ));
          },
        },
      });
    }
  });

  const mutateContent = (e) => {
    e.preventDefault();
    updateContent({ variables: { id: courseContent.id, name: contentName } });
  }

  const handleDestroy = () => {
    if (confirm("Your are about to delete a section. Continue?")) {
      destroyContent({ variables: { id: courseContent.id } });
    }
  }

  const handleClose = () => {
    toggleVisibility(false);
  }

  const handleNavigate = (data) => {
    try {
      const { updateCourseContent: { courseContent: { slug } } } = data;
      navigate(`/teacher/my/courses/${params.slug}/content/${slug}`, { replace: true });
    } catch {
      navigate(`/teacher/my/courses/${params.slug}/content`, { replace: true });
    }
  }

  return (
    <li className="list-group-item p-0">
      { !!isVisible ? (
        <CourseContentForm
          mutateContent={mutateContent}
          contentName={contentName}
          setContentName={setContentName}
          handleClose={handleClose}
          loading={loading}
          error={error} />
      ) : (
        <Fragment>
          <CourseContentDrag
            courseContent={courseContent}
            index={index}
            handleSort={handleSort}
            readOnly={readOnly} />
          { !readOnly ? (
            <Dropdown
              toggleVisibility={toggleVisibility}
              handleDestroy={handleDestroy}
              loading={destroyLoading}
              editText="Edit section" />
          ) : null }
        </Fragment>
      ) }
    </li>
  );
}

export default CourseContent
import React, { Fragment, useEffect, useReducer, useContext } from "react";
import { useParams } from "react-router-dom";
import { SubmissionContext } from "context";
import Block from "components/course_view/submissions/Block";
import Question from "./Question";
import { UserContext } from "context";
import Button from "../elements/Button";
import useSubmissionMutation from "../../hooks/useSubmissionMutation";

const Editor = ({
    courseContent,
    courseContent: { blocks },
    userSubmission,
    fetchContent,
}) => {
    let params = useParams();
    const userId = useContext(UserContext);
    const [activityState, dispatch] = useReducer(reducer, []);

    function reducer(state, action) {
        switch (action.type) {
            case "update":
                return state.map((answer) => (
                    answer.blockId === action.payload.id ? { ...answer, content: action.payload.content } : answer
                ));
            case "initialize":
                return action.payload;
            default:
                return state;
        }
    }

    useEffect(() => {
        if (!!userSubmission) {
            dispatch({ type: "initialize", payload: userSubmission.answers });
        }

        if (!userSubmission) {
            const payload = blocks.filter(block => block.type === "question").map((block) => {
                return {
                    blockId: block.id,
                    data: {
                        questionId: block.data.question ? block.data.question.id : "",
                    },
                }
            });
            dispatch({ type: "initialize", payload });
        }
    }, [courseContent]);

    const {
        createSubmission,
        createLoading,
        updateSubmission,
        updateLoading,
    } = useSubmissionMutation();

    const handleSave = ({ status }) => {
        const variables = { answers: activityState, status };

        if (!!userSubmission) {
            variables.id = userSubmission.id;
            updateSubmission(variables, fetchContent);
        } else {
            variables.courseContentId = courseContent.id;
            createSubmission(variables, fetchContent);
        }
    }

    const showFormButtons = !!courseContent.isActivity &&
                            ((!!userSubmission && userSubmission.status === "draft") || (!userSubmission));

    return (
        <Fragment>
            <div id="editor" className="section-blocks-container">
                <ol className="p-0 m-0">
                    {blocks.map((block) => {
                        return block.type === "question" ? (
                            <SubmissionContext.Provider key={block.id} value={userSubmission}>
                                <Question
                                    block={block}
                                    answer={activityState.find(answer => answer.blockId === block.id)}
                                    dispatch={dispatch} />
                            </SubmissionContext.Provider>
                        ) : <Block key={block.id} block={block} />
                    })}
                </ol>
            </div>
            {/* Show submit buttons if activity, and submission is draft or not yet created */}
            {showFormButtons ? (
                <div>
                    <Button
                        type="secondary"
                        className="btn btn-outline-dark"
                        onClick={() => handleSave({ status: "draft" })}
                        disabled={!!createLoading || !!updateLoading}
                        text="Save as Draft" />
                    <Button
                        type="primary"
                        onClick={() => handleSave({ status: "submitted" })}
                        disabled={!!createLoading || !!updateLoading}
                        text="Submit" />
                </div>
            ) : null}
        </Fragment>
    );
}

export default Editor;
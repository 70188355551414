import React from "react";
import currentPortal from "../../utils/currentPortal";

const CourseHeader = ({ course }) => {

    const onStudent = currentPortal() === "student";
    const backUrl = onStudent ? "/student/my/classes"
                              : "/teacher/my/courses";

    return (
        <>
            <a
                onClick={() => document.body.style = {}}
                href={backUrl}
                className="d-flex mb-4 text-dark">
                    <span className="fe fe-chevron-left me-2" />
                    My {onStudent ? "Classes" : "Courses"}
            </a>
            <div className="mb-4">
                <h2 className="mb-2">{course.name}</h2>
                { course.teacher ? (
                    <p>by <span className="text-dark">{course.teacher.name}</span></p>
                ) : null }
            </div>
        </>
    );
}

export default CourseHeader;
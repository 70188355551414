import React, { Fragment } from 'react'
import { useMutation } from '@apollo/client';
import { CREATE_MEDIA_FILE } from 'graphql/mutations';
import FileRenderer from './FileRenderer';

const File = ({ block, dispatch, readOnly }) => {

  const { id, type, data } = block;

  const [createMediaFile, { loading, error }] = useMutation(CREATE_MEDIA_FILE, {
    onCompleted: (data) => { handleResponse(data) },
  });

  const handleAttach = (e) => {
    const file = e.target.files[0];
    if (!file) { return; }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const variables =  {
        fileData: reader.result,
        fileName: file.name,
        fileType: file.type,
      }
      createMediaFile({ variables });
    }
  }

  const handleResponse = (data) => {
    const { mediaFile } = data.createMediaFile;
    const payload = {
      id,
      data: {
        file: {
          url: mediaFile.fileUrl,
          name: mediaFile.fileName,
          type: mediaFile.fileType,
        },
      },
    }
    dispatch({ type: 'update', payload });
  }

  const iconName = type === 'img' ? 'image' : 'upload';
  const inputLabel = type === 'img' ? 'Upload image' : 'Upload file';
  const inputAccept = type === 'img' ? 'image/*' : '*';

  return (
    <Fragment>
      { data && data.file && data.file.url ? (
        <Fragment>
          { type === 'img' ? (
            <img src={data.file.url} className="mw-100" />
          ) : (
            <FileRenderer fileName={data.file.name}/>
          ) }
        </Fragment>
      ) : (
        !readOnly ? (
          <Fragment>
            <div className="d-inline-flex border py-2 px-4">
              <label className="d-inline-flex align-items-center">
                <span className={`fe fe-${iconName} h2 me-3 mb-0`} />
                <span className="me-6">{inputLabel}</span>
                <input
                  type="file"
                  accept={inputAccept}
                  disabled={!!loading}
                  onChange={(e) => handleAttach(e)} />
              </label>
            </div>
            <div className="mt-2">
              { !!loading ? (
                <p className="text-secondary">Waiting for file to upload...</p>
              ) : null }
              { !!error ? (
                <p className="text-danger">Failed to upload.</p>
              ) : null }
            </div>
          </Fragment>
        ) : null
      ) }
    </Fragment>
  );
}

export default File
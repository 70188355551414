import React, { useEffect, useRef } from 'react'
import FileRenderer from 'components/editor/blocks/FileRenderer';

const QuestionRenderer = ({ blockId, data: { question, points }, setPoints, readOnly }) => {

  const { questionType, questionText, choices, attachment } = question;

  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = points;
    }
  }, []);

  const handleInput = (e) => {
    const points = parseFloat(e.currentTarget.textContent) || 0;
    if (points >= 0) {
      setPoints(points);
    }
  }

  return (
    <div>
      <div className="d-flex align-items-start mb-3">
        <p className="pe-3 m-0">{questionText}</p>
        <span className="py-1 px-2 m-0 bg-light rounded h4">
          <span contentEditable={!readOnly} ref={ref} onInput={(e) => handleInput(e)} />
          <span>{points == 1 ? ' pt' : ' pts'}</span>
        </span>
      </div>
      { !!attachment ? (
        <div className="mb-3">
          { attachment.type.includes('image') ? (
            <img src={attachment.url} className="mw-100" />
          ) : (
            <FileRenderer fileName={attachment.name} fileUrl={attachment.url}/>
          ) }
        </div>
      ) : null }
      { questionType === 'text' ? (
        <textarea
          className="form-control"
          placeholder="Type your answer here" />
      ) : null }
      { questionType === 'multiple_choice' ? (
        <ol className="list-group d-inline-flex" type="a">
          { choices.map((choice, index) => (
            <label key={index} className="form-check-label d-flex me-auto">
              <input
                className="form-check-input me-4"
                type="radio"
                name={blockId}
                value="" />
              <li>{choice.choiceText}</li>
            </label>
          )) }
        </ol>
      ) : null }
      { questionType === 'file_attachment' ? (
        <input type="file" name="file" />
      ) : null }
    </div>
  );
}

export default QuestionRenderer
import React from 'react'
import CourseModule from './CourseModule';

const CourseModules = ({ courseModules }) => {

  return (
    <ul className="list-group">
      { [...courseModules]
        .sort((a, b) => a.ordinality - b.ordinality)
        .map((courseModule) => (
          <CourseModule
            key={courseModule.id}
            courseModule={courseModule} />
        )) }
    </ul>
  );
}

export default CourseModules
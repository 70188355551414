import React, { Fragment } from 'react'
import { useQuery } from '@apollo/client';
import { FETCH_QUESTIONS } from 'graphql/queries';

const QuestionBank = ({ setQuestion }) => {

    const { loading, data, refetch, fetchMore } = useQuery(FETCH_QUESTIONS, {
        notifyOnNetworkStatusChange: true,
        variables: {
            offset: 0,
            limit: 6
        },
    });

    const handleSearch = (e) => {
        setTimeout(() => {
            refetch({ searchString: e.target.value });
        }, 1000);
    }

    return (
        <div className="border p-4">
            <form className="mb-4">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search for question text..."
                    onChange={(e) => handleSearch(e)}
                    autoFocus
                />
            </form>
            <div className="question-bank overflow-auto">
                { !!data?.fetchQuestions?.length ? (
                    <Fragment>
                        <div className="d-flex flex-wrap">
                            { data.fetchQuestions.map((question) => (
                                <div
                                    key={question.id}
                                    className="card me-4 cursor-pointer"
                                    onClick={() => setQuestion(question)}
                                >
                                    <div className="card-body">
                                        <h5 className="card-subtitle mb-2 text-secondary text-uppercase d-none">
                                            {question.category}
                                        </h5>
                                        <p className="card-text">{question.questionText}</p>
                                        <div className="text-muted small d-flex align-items-center">
                                            <span className="fe fe-clock me-2" />
                                            Last edited on {question.formatUpdatedAt}
                                        </div>
                                    </div>
                                </div>
                            )) }
                        </div>
                        <button
                            type="button"
                            className="btn btn-info"
                            disabled={!!loading}
                            onClick={() => fetchMore({
                                variables: {
                                    offset: data.fetchQuestions.length
                                },
                            })}
                        >
                            Load More
                        </button>
                    </Fragment>
                ) : (
                    <span>No questions found.</span>
                ) }
            </div>
        </div>
    );
}

export default QuestionBank
import React from "react";
import Text from "components/editor/blocks/Text";
import List from "components/editor/blocks/List";
import File from "components/editor/blocks/File";
import Math from "components/editor/blocks/Math";
import Embed from "components/editor/blocks/questions/Embed";
import Divider from "../../editor/blocks/Divider";

const Block = ({ block, block: { type } }) => {

    return (
        <div className="d-flex align-items-start block-container">
            <div className="w-100">
                {["p", "h1", "h2", "h3"].includes(type) ? (
                    <Text block={block} readOnly={true} />
                ) : null}
                {["ul", "ol"].includes(type) ? (
                    <List block={block} readOnly={true} />
                ) : null}
                {["img", "file"].includes(type) ? (
                    <File block={block} readOnly={true} />
                ) : null}
                {type === "tex" ? (
                    <Math block={block} readOnly={true} />
                ) : null}
                {type === "embed" && (
                    <Embed block={block} readOnly={true} />
                )}
                {type === "divider" && (
                    <Divider />
                )}
            </div>
        </div>
    );
}

export default Block
import React, { Fragment } from "react";
import SubmissionsTable from "./SubmissionsTable";
import Select from "react-select";

const StatusTabs = ({ submissions, setSelectedStatus, selectedStatus }) => {
    const statusOptions = [
        { label: "All", value: "all" },
        { label: "Draft", value: "draft" },
        { label: "Submitted", value: "submitted" },
        { label: "Checked", value: "checked" },
        { label: "Published", value: "published" },
    ];

    function handleStatusFilterChange({ value }) {
        setSelectedStatus(value);
    }

    return (
        <Fragment>
            <div className="d-flex justify-content-end mb-4">
                <div className="d-flex flex-column section-selector w-100 w-md-50">
                    <label htmlFor="submission-status-filter">Filter by status</label>
                    <Select
                        id="submission-status-filter"
                        onChange={handleStatusFilterChange}
                        options={statusOptions}
                        defaultValue={statusOptions.filter(o => o.value === selectedStatus)}
                        placeholder="Filter by status"
                    />
                </div>
            </div>
            <SubmissionsTable submissions={submissions} />
        </Fragment>
    );
}

export default StatusTabs;
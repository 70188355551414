import React from "react";
import { useMutation } from "@apollo/client";
import { FETCH_COURSE_USERS } from "graphql/queries";
import { CREATE_COURSE_USER } from "graphql/mutations";

const User = ({ user, isEnlisted, courseId }) => {

    const [createCourseUser] = useMutation(CREATE_COURSE_USER, {
        onCompleted: (data) => alert("Student enlisted."),
        onError: (error) => alert(error.message),
        update(cache, { data: { createCourseUser: { courseUser } } }) {
            cache.updateQuery({
                query: FETCH_COURSE_USERS,
                variables: { courseId },
            }, (data) => ({
                fetchCourseUsers: [...data.fetchCourseUsers, courseUser],
            }));
        }
    });

    const handleEnlist = () => {
        createCourseUser({ variables: { courseId, userId: user.id } })
    }

    return (
        <tr>
            <td>{user.name}</td>
            <td>{user.email}</td>
            <td>{user.lrn}</td>
            <td>{user.yearLevel ? user.yearLevel.name : null}</td>
            <td>
                {isEnlisted ? (
                    <button type="button" className="btn btn-sm btn-secondary" disabled>
                        Enlisted
                    </button>
                ) : (
                    <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() => handleEnlist()}>
                        Enlist
                    </button>
                )}
            </td>
        </tr>
    );
}

export default User;
import React, { useState, useEffect, useReducer } from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_COURSE_USERS } from 'graphql/queries';
import { useFetchSetting } from 'hooks/useFetchSetting';
import CourseUsers from './partials/CourseUsers';

const MyCourses = ({ userId }) => {

  const { data } =  useQuery(FETCH_COURSE_USERS, { variables: { userId } });
  const { data: permData } = useFetchSetting({ name: "allow_student_enlistment" });

  const [searchString, setSearchString] = useState('');
  const [state, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    if (data && data.fetchCourseUsers) {
      dispatch({ type: 'initialize', payload: data.fetchCourseUsers });
    }
  }, [data]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (!!searchString) { dispatch({ type: 'search' }); }
      else { dispatch({ type: 'reset' }); }
    }, 1000);
    return () => clearTimeout(debounce);
  }, [searchString]);

  function reducer(state, action) {
    switch (action.type) {
      case 'search':
        return data && data.fetchCourseUsers ? (
          data.fetchCourseUsers.filter(({ course }) => {
            return course.name.toLowerCase().includes(searchString.toLowerCase());
          })
        ) : state
      case 'reset':
        return data && data.fetchCourseUsers || state;
      case 'initialize':
        return action.payload;
      default:
        return state;
    }
  }

  return (
    <div className="container-fluid p-4">
      <h2>My Classes</h2>
      <form className="col-4 mb-4">
        <input
          type="text"
          className="form-control"
          placeholder="Search for class name..."
          onChange={(e) => setSearchString(e.target.value)}
          autoFocus />
      </form>
      <CourseUsers courseUsers={state} userId={userId} allowEnlistment={permData?.fetchSetting.allow_student_enlistment} />
    </div>
  );
}

export default MyCourses
import React, { Fragment } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useFetchCourse } from "hooks/useFetchCourse";
import Breadcrumbs from "./partials/Breadcrumbs";
import SidebarToggler from "../content_builder/sidebar/SidebarToggler";
import Banner from "../../components/elements/Banner";
import formatDate from "../../utils/formatDate";
import DescriptionBlock from "../content_builder/DescriptionBlock";
import pluralizeString from "../../utils/pluralizeString";

const HomePage = () => {
    let params = useParams();
    const { data } = useFetchCourse({ slug: params.slug });
    const navigate = useNavigate();

    return (
        <div className="col-12 col-md-9 px-5 bg-white min-vh-100">
            {data && data.fetchCourse ? (
                <Fragment>
                    {data.fetchCourse.isFresh && (
                        <div className="mb-3">
                            <Banner
                                text="We have generated some content to start you off. You may start by editing the names of modules and lessons."
                                type="primary"
                                buttonText="Go to Syllabus Builder"
                                buttonOnClick={() => navigate(`/teacher/my/courses/${params.slug}/content`, { replace: true })} />
                        </div>
                    )}
                    <Breadcrumbs crumbs={[]} />
                    <div className="d-flex flex-column section-title-container mb-4">
                        <h2 className="mb-2">{data.fetchCourse.name}</h2>
                        <div className="d-flex flex-column flex-md-row mt-2">
                            <span>Last update: {formatDate(data.fetchCourse.updatedAt)}</span>
                            <SidebarToggler />
                        </div>
                    </div>
                    <div className="d-none mb-3">
                        <h3>Summary</h3>
                        <div className="d-flex justify-content-between">
                            <div className="bg-light p-4" style={{ width: "30%" }}>
                                <h4 className="mb-3">Quizzes</h4>
                                <p className="h2 text-primary mb-0">6/12 submitted</p>
                            </div>
                            <div className="bg-light p-4" style={{ width: "30%" }}>
                                <h4 className="mb-3">Assignments</h4>
                                <p className="h2 text-primary mb-0">12/24 submitted</p>
                            </div>
                            <div className="bg-light p-4" style={{ width: "30%" }}>
                                <h4 className="mb-3">Exams</h4>
                                <p className="h2 text-primary mb-0">3/6 submitted</p>
                            </div>
                        </div>
                    </div>
                    <div className="d-none text-end mb-3">
                        <Link to={`/teacher/my/courses/${params.slug}/submissions`}>
                            View Submissions
                        </Link>
                    </div>
                    <DescriptionBlock
                        title="About this course"
                        buttonText="Edit Course"
                        buttonClass="btn btn-primary"
                        buttonOnClick={() => window.location.replace(`/teacher/my/courses/${params.slug}/edit`)}
                        editMode={false}
                        readOnly={false}
                        defaultValue={data.fetchCourse.description}/>
                    <div className="mt-6">
                        <h3>Course Overview</h3>
                        <ul className="p-1 border-top">
                            {[...data.fetchCourse.courseModules]
                                .sort((a, b) => a.ordinality - b.ordinality)
                                .map((courseModule, index) => (
                                    <li
                                        key={courseModule.id}
                                        className="d-flex py-4 border-bottom">
                                            <div className="row w-100">
                                                <div className="col-9 px-4">
                                                    <span>{courseModule.name}</span>
                                                </div>
                                                <div className="col-3 text-center">
                                                    <span>{pluralizeString("Lesson", courseModule.lessons.length)}</span>
                                                </div>
                                            </div>
                                    </li>
                                ))}
                            {data.fetchCourse.courseModules.length === 0 ? (
                                <p className="mb-0">No modules yet.</p>
                            ) : null}
                        </ul>
                    </div>
                </Fragment>
            ) : null}
        </div>
    );
}

export default HomePage
import React, { Fragment, useEffect, useReducer, useState } from 'react'
import Block from './Block';
import { defaultBlock } from 'blocks';
import { usePrompt } from '../../hooks/usePrompt';
import SectionNavigator from '../content_viewer/SectionNavigator';
import DescriptionBlock from '../content_builder/DescriptionBlock';
import { UPDATE_COURSE_CONTENT } from '../../graphql/mutations';

const Editor = ({
    courseContent,
    updateContent,
    contentType,
    loading,
    readOnly,
    setIsReadOnly,
    course,
}) => {
    const [editorState, dispatch] = useReducer(reducer, []);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [sectionDescriptionEdit, setSectionDescriptionEdit] = useState(false);

    function reducer(state, action) {
        switch (action.type) {
            case 'add':
                const index = state.findIndex((block) => block.id === action.id) + 1;
                return [...state.slice(0, index), action.payload, ...state.slice(index)];
            case 'update':
                return state.map((block) => (
                    block.id === action.payload.id ? { ...block, data: action.payload.data } : block
                ));
            case 'convert':
                return state.map((block) => (
                    block.id === action.payload.id ? { ...block, data: action.payload.data, type: action.payload.type } : block
                ));
            case 'delete':
                if (state.length === 1) {
                    return [defaultBlock()];
                }
                return state.filter((block) => block.id !== action.payload.id);
            case 'insert':
                const newState = [...state];
                const indexFrom = state.findIndex(block => block.id === action.payload.id);
                const indexTo = action.payload.index;
                const block = newState.splice(indexFrom, 1);
                return [...newState.slice(0, indexTo), ...block, ...newState.slice(indexTo)];
            case 'initialize':
                return action.payload;
            default:
                return state;
        }
    }

    useEffect(() => {
        const payload = !!courseContent.blocks.length ? courseContent.blocks : [defaultBlock()];
        dispatch({ type: 'initialize', payload });
    }, [courseContent]);

    // Prompt on unsaved changes
    const message = "There are unsaved changes on this section. Discard changes?"
    const isDirty =
        ((courseContent.contentType !== contentType) || (JSON.stringify(courseContent.blocks) !== JSON.stringify(editorState))) && !readOnly;
    usePrompt(message, isDirty);

    function handleSave(payload = null, callback = null) {
        let updatedState = (editorState && payload)
                                ? editorState.map((block) => (
                                    block.id === payload.id ? { ...block, data: payload.data } : block
                                  ))
                                : editorState;
        const variables = {
            id: courseContent.id,
            blocks: updatedState,
            contentType,
        }
        updateContent({ variables })
            .then((data) => {
                if (callback) callback();
            });
        setShowSaveButton(true);
    }

    return (
        <Fragment>
            <DescriptionBlock
                title={`About this ${courseContent.contentType}`}
                editMode={sectionDescriptionEdit}
                defaultValue={courseContent.description}
                recordId={courseContent.id}
                setEditMode={setSectionDescriptionEdit}
                mutation={UPDATE_COURSE_CONTENT}
                readOnly={readOnly} />
            <div id="editor" className="pb-6">
                <ol className="p-0 m-0">
                    { editorState.map((block, index) => (
                        <Block
                            key={block.id}
                            block={block}
                            dispatch={dispatch}
                            readOnly={readOnly}
                            contentType={contentType}
                            index={index}
                            setShowSaveButton={setShowSaveButton}
                            handleSave={handleSave}
                        />
                    )) }
                </ol>
            </div>
            { (!readOnly && showSaveButton) && (
                <div className="d-flex justify-content-center justify-content-md-start">
                    <button type="button" className="btn btn-primary" disabled={!!loading} onClick={() => handleSave()}>
                        Save
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary text-center ms-2"
                        onClick={() => handleSave(null, () => setIsReadOnly(!readOnly))}>
                        Preview
                    </button>
                </div>
            ) }
            {readOnly ? <SectionNavigator course={course} /> : null}
        </Fragment>
    );
}

export default Editor
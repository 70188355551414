import React, { useEffect, useState } from "react";
import Breadcrumbs from "./partials/Breadcrumbs";
import SidebarToggler from "../content_builder/sidebar/SidebarToggler";
import { useFetchCourse } from "hooks/useFetchCourse";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loader } from "../utils/Loader";
import Select from "react-select";
import { FETCH_COURSE_STUDENTS_PROGRESS } from "../../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import Pagination from "../elements/Pagination";

const StudentProgressReport = () => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search.substring(1));
    const q = Object.fromEntries(searchParams);
    const { data, loading } = useFetchCourse({ slug: params.slug });
    const course = data?.fetchCourse;
    const [selectedFilters, setSelectedFilters] = useState({
        studentFirstNameStartsWith: null,
        studentLastNameStartsWith: null,
        page: Number(q.page) || 1,
    });

    function handlePageChange(page) {
        updatePageUrlParam(page);
        setSelectedFilters(prevFilters => ({ ...prevFilters, page: page }));
    };

    function updatePageUrlParam(page) {
        q.page = page;
        navigate(location.pathname + `?${new URLSearchParams(q).toString()}`, { replace: true });
    }

    return (
        <div className="col-12 col-md-9 px-5 bg-white min-vh-100">
            <Breadcrumbs crumbs={[{ name: "Student Progress Report" }]} />
            <h2>Student Progress Report</h2>
            <SidebarToggler text="Navigate Course" />
            {loading ? <Loader /> : null}
            {!loading && !course ? <h1>Course not found</h1> : null}
            {!loading && !!course ? (
                <>
                        <PageHeader
                            setSelectedFilters={setSelectedFilters}
                            updatePageUrlParam={updatePageUrlParam} />
                        <ReportTable
                            course={course}
                            selectedFilters={selectedFilters}
                            handlePageChange={handlePageChange} />
                </>
            ) : null}
        </div>
    )
}

const ReportTable = ({ course, selectedFilters, handlePageChange }) => {
    const [fetchProgress, { loading }] = useLazyQuery(
        FETCH_COURSE_STUDENTS_PROGRESS,
        { fetchPolicy: "cache-and-network" }
    );
    const courseContents = [...course.courseModules]
                            .sort((a, b) => a.ordinality - b.ordinality)
                            .flatMap(module => module.lessons)
                            .flatMap(lesson => {
                                return [...lesson.courseContents]
                                        .sort((a, b) => a.ordinality - b.ordinality)
                            });

    const [progresses, setProgresses] = useState([]);
    const [pageInfo, setPageInfo] = useState({});

    useEffect(() => {
        fetchProgress({ variables: { slug: course.slug, filters: selectedFilters } })
            .then(res => {
                let data = res.data.fetchCourseStudentsProgress;
                setProgresses(data.studentsProgress);
                setPageInfo(data.pagy);
            })
    }, [selectedFilters.studentFirstNameStartsWith,
        selectedFilters.studentLastNameStartsWith,
        selectedFilters.page])

    return (
        <div className="my-6">
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th className="py-0 p-2" rowSpan="2" width="15%">First Name</th>
                            <th className="py-0 p-2" rowSpan="2" width="15%">Last Name</th>
                            <th className="text-center p-2" colSpan={courseContents.length}>Course content</th>
                        </tr>
                        <tr>
                            {courseContents.map(content => (
                                <th className="text-truncate p-1"
                                    key={`header-${content.slug}`}
                                    colSpan={1}
                                    width="10%"
                                    style={{ maxWidth: "8rem" }}
                                    title={content.name}>{content.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td>
                                    <Loader />
                                </td>
                            </tr>
                        ) : null}
                        {!loading && !Boolean(progresses.length) ? (
                            <tr className="text-center">
                                <td colSpan={courseContents.length + 1}>No progress to show</td>
                            </tr>
                        ) : null}
                        {!loading && Boolean(progresses.length) ? (
                            <>
                                {progresses.map(row => (
                                    <tr key={`row-${row.student.id}`}>
                                        <td>{row.student.firstName}</td>
                                        <td>{row.student.lastName}</td>
                                        {courseContents.map(content => (
                                            <td className="text-center" key={`progress-${row.student.id}-${content.slug}`}>
                                                <ProgressPill status={row.progress.find(progress => progress.id === content.id)?.status} />
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </>
                        ) : null}
                    </tbody>
                </table>
            </div>
            <Pagination
                pageInfo={pageInfo}
                handlePageChange={handlePageChange}
                placement="end" />
        </div>
    );
}

const ProgressPill = ({ status }) => {
    const pillClass = [
        "badge rounded-pill text-uppercase",
        !status ? "bg-light" : null,
        status === "read" ? "bg-info" : null,
        status === "done" ? "bg-success" : null,
    ].join(" ");

    const text = !status ? "no progress"
                         : status === "read"
                            ? "opened"
                            : "done";

    return (
        <span className={pillClass}>{text}</span>
    );
}

const PageHeader = ({ setSelectedFilters, updatePageUrlParam }) => {
    const options = "ABCDEFGHIJKLMNÑOPQRSTUVWXYZ".split("")
                    .map(opt => ({ label: opt, value: opt.toLowerCase() }));

    function handleFilterChange(key, value) {
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            [key]: value, // filterKey: "value"
            page: 1, // always redirect to page 1 when changing filters
        }));
        updatePageUrlParam(1);
    }

    return (
        <div className="mt-4">
            <h3>Filters</h3>
            <div className="d-flex flex-column flex-md-row">
                <div className="filter-select-container">
                    <label htmlFor="firstNameFilter">First name starts with</label>
                    <Select
                        id="firstNameFilter"
                        onChange={opt => handleFilterChange("studentFirstNameStartsWith", opt?.value)}
                        options={options}
                        isClearable
                    />
                </div>
                <div className="filter-select-container">
                    <label htmlFor="lastNameFilter">Last name starts with</label>
                    <Select
                        id="lastNameFilter"
                        onChange={opt => handleFilterChange("studentLastNameStartsWith", opt?.value)}
                        options={options}
                        isClearable
                    />
                </div>
            </div>
        </div>
    );
}

export default StudentProgressReport;
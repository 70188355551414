import React, { useState, useEffect, Fragment } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import Breadcrumbs from './Breadcrumbs';
import CourseContent from './CourseContent';

const MainPanel = ({ course, readOnly, setIsReadOnly }) => {

  let params = useParams();
  let navigate = useNavigate();

  const [section, setSection] = useState(null);

  // On load, navigate to most recently updated section
  useEffect(() => {
    if (!params.slug2) {
      // get courseContent with latest updatedAt
      const courseContent = course.courseModules
              .flatMap(courseModule => courseModule.lessons)
              .flatMap(lesson => lesson.courseContents)
              .sort((a, b) => a.updatedAt - b.updatedAt)[0]
      let url = readOnly
                  ? `/teacher/courses/${params.slug}/content/${courseContent?.slug}`
                  : `/teacher/my/courses/${params.slug}/content/${courseContent?.slug}`
      if (courseContent) {
        navigate(url, { replace: true });
      }
    }
  }, []);

  useEffect(() => {
    setSection(null);

    course.courseModules.forEach(courseModule => {
      courseModule.lessons.forEach(lesson => {
        lesson.courseContents.forEach(courseContent => {
          if (courseContent.slug === params.slug2) {
            setSection({ courseModule, lesson, courseContent });
          }
        });
      });
    });
  }, [course, params]);

  return (
    <div className="col-12 col-md-9 ps-md-4">
      { section ? (
        <Fragment>
          <Breadcrumbs
            section={section}
            readOnly={readOnly}
            setIsReadOnly={setIsReadOnly} />
          <CourseContent
            course={course}
            section={section}
            readOnly={readOnly}
            setIsReadOnly={setIsReadOnly} />
        </Fragment>
      ) : null }
      { (!section && !readOnly) ? (
        <div className='bg-white border border-light border-3 rounded-2 p-5'>
          <p className='fs-2'>Create your lecture and activities</p>
          <ol>
            <li>Start by adding a module</li>
            <li>Add a lesson under new module</li>
            <li>In lessons, you can add lecture, assignment, quiz, or exam</li>
          </ol>
        </div>
      ) : null }
    </div>
  );
}

export default MainPanel
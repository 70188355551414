import React from 'react';

const SidebarDrawer = ({ visible, children }) => {
    if (!visible) return null;
    return (
        <div className="offcanvas offcanvas-start" id="sidebar" tabIndex="-1" aria-labelledby="sidebarLabel">
            <div className="offcanvas-body p-0">
                {children}
            </div>
        </div>
    )
}

SidebarDrawer.defaultProps = {
    visible: true,
};

export default SidebarDrawer;
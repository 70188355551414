import React from 'react'
import { useMutation } from '@apollo/client';
import { REORDER_COURSE_CONTENTS } from 'graphql/mutations';
import CourseContent from './CourseContent';
import NewCourseContent from './NewCourseContent';

const CourseContents = ({ lesson, lesson: { courseContents }, readOnly }) => {

  const [reorderContents] = useMutation(REORDER_COURSE_CONTENTS);

  const handleSort = ({ dataId, index }) => {
    const sortedIds = [...courseContents]
      .sort((a, b) => a.ordinality - b.ordinality)
      .map(courseContent => courseContent.id)
      .filter(id => id !== dataId);
    const courseContentIds = [...sortedIds.slice(0, index), dataId, ...sortedIds.slice(index)];

    reorderContents({ variables: { id: lesson.id, courseContentIds } });
  }

  return (
    <ul className="list-group ps-5">
      { [...courseContents].sort((a, b) => a.ordinality - b.ordinality).map((courseContent, index) => (
        <CourseContent
          key={courseContent.id}
          courseContent={courseContent}
          lesson={lesson}
          index={index}
          handleSort={handleSort}
          readOnly={readOnly} />
      )) }
      { !readOnly ? (
        <NewCourseContent lesson={lesson} />
      ) : null }
    </ul>
  );
}

export default CourseContents
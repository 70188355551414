import React, { Fragment, useState, useEffect } from 'react'
import { useMutation } from '@apollo/client';
import { UPDATE_LESSON, DESTROY_LESSON } from 'graphql/mutations';
import { useParams } from "react-router-dom";
import CourseContents from './CourseContents';
import LessonForm from './LessonForm';
import Dropdown from './Dropdown';
import LessonDrag from './LessonDrag';

const Lesson = ({ courseModule, lesson, index, handleSort, readOnly }) => {

  let params = useParams();

  // On load or section select, expand lesson tab of active section, and collapse other lessons
  useEffect(() => {
    if (params.slug2) {
      const courseContent = lesson.courseContents.find(courseContent => courseContent.slug === params.slug2);
      toggleSections(!!courseContent);
    }
  }, [params]);

  const [isVisible, toggleVisibility] = useState(false);
  const [lessonName, setLessonName] = useState(lesson.name);
  const [showSections, toggleSections] = useState(false);

  const [updateLesson, { loading, error }] = useMutation(UPDATE_LESSON, {
    onCompleted: (data) => handleClose(),
  });

  const [destroyLesson, { loading: destroyLoading }] = useMutation(DESTROY_LESSON, {
    update(cache, { data: { destroyLesson } }) {
      cache.modify({
        id: cache.identify(courseModule),
        fields: {
          lessons(currentLessons, { readField }) {
            return currentLessons.filter(lesson => (
              destroyLesson.id !== readField('id', lesson)
            ));
          },
        },
      });
    }
  });

  const mutateLesson = (e) => {
    e.preventDefault();
    updateLesson({ variables: { id: lesson.id, name: lessonName } });
  }

  const handleDestroy = () => {
    if (confirm("You are about to delete a lesson. Continue?")) {
      destroyLesson({ variables: { id: lesson.id } });
    }
  }

  const handleClose = () => {
    toggleVisibility(false);
  }

  return (
    <Fragment>
      <li className="list-group-item p-0">
        { !!isVisible ? (
          <LessonForm
            mutateLesson={mutateLesson}
            lessonName={lessonName}
            setLessonName={setLessonName}
            handleClose={handleClose}
            loading={loading}
            error={error} />
        ) : (
          <Fragment>
            <div
              className="me-4 cursor-pointer"
              onClick={() => toggleSections(showSections => !showSections)}
            >
              <LessonDrag
                lesson={lesson}
                index={index}
                handleSort={handleSort}
                showSections={showSections} />
            </div>
            { !readOnly ? (
              <Dropdown
                toggleVisibility={toggleVisibility}
                handleDestroy={handleDestroy}
                loading={destroyLoading}
                editText="Edit lesson" />
            ) : null }
          </Fragment>
        ) }
      </li>
      { !!showSections ? (
        <CourseContents
          lesson={lesson}
          readOnly={readOnly} />
      ) : null }
    </Fragment>
  );
}

export default Lesson
import React, { useCallback, useContext } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_MEDIA_FILE } from "graphql/mutations";
import { SubmissionContext } from "context";
import FileRenderer from "components/editor/blocks/FileRenderer";
import pluralizeString from "../../utils/pluralizeString";
import { useDropzone } from "react-dropzone";
import { default as UploadFileOutlineGray } from "../../../assets/images/upload-file-outlined-gray.svg";


const QuestionRenderer = ({ blockId, data: { question, points }, answer, dispatch }) => {

    const { questionType, questionText, choices, attachment } = question;

    const submission = useContext(SubmissionContext);

    const [createMediaFile, { loading, error }] = useMutation(CREATE_MEDIA_FILE, {
        onCompleted: (data) => { handleResponse(data) },
    });

    const handleInput = (e) => {
        const payload = { id: blockId, content: { text: e.target.value } }
        dispatch({ type: "update", payload });
    }

    const handleSelect = (e) => {
        const payload = { id: blockId, content: { choiceId: e.target.value } }
        dispatch({ type: "update", payload });
    }

    const removeFile = () => {
        dispatch({ type: "update", payload: { id: blockId, content: null } });
    }

    const handleResponse = (data) => {
        const { mediaFile } = data.createMediaFile;
        const payload = {
            id: blockId,
            content: {
                file: {
                    url: mediaFile.fileUrl,
                    name: mediaFile.fileName,
                    type: mediaFile.fileType,
                },
            },
        }
        dispatch({ type: "update", payload });
    }

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => {
                const variables = {
                    fileData: reader.result,
                    fileName: file.name,
                    fileType: file.type,
                }
                createMediaFile({ variables });
            }
            reader.readAsDataURL(file);
        });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <div className="w-100">
            <div className="question-text">
                <span>{questionText}</span>
                <span className="badge rounded-pill bg-light">
                    {pluralizeString("pt", points)}
                </span>
            </div>
            <Attachment attachment={attachment} />
            <TextQuestion
                questionType={questionType}
                submission={submission}
                answer={answer}
                handleInput={handleInput} />
            <MultipleChoiceQuestion
                choices={choices}
                blockId={blockId}
                handleSelect={handleSelect}
                answer={answer}
                submission={submission}
                questionType={questionType} />
            <FileAttachmentQuestion
                loading={loading}
                error={error}
                answer={answer}
                submission={submission}
                questionType={questionType}
                getInputProps={getInputProps}
                getRootProps={getRootProps}
                removeFile={removeFile} />
        </div>
    );
}

const FileAttachmentQuestion = ({
    loading,
    error,
    answer,
    submission,
    questionType,
    getInputProps,
    getRootProps,
    removeFile,
}) => {
    if (questionType !== "file_attachment") return null;

    return (
        <>
            {answer && !answer.content ? (
                <div {...getRootProps()}
                    className="image-dropzone">
                    <input {...getInputProps()} />
                    <img src={UploadFileOutlineGray} alt="Upload file icon" />
                    <span>Drag and drop or click here to upload your image or file</span>
                </div>
            ) : null}
            {!!answer && answer.content && answer.content.file && !loading && !error ? (
                <FileRenderer
                    fileName={answer.content.file.name}
                    fileUrl={answer.content.file.url}
                    fileType={answer.content.file.type}
                    removeFile={removeFile}
                    hasDelete={!submission || submission?.status === "draft"} />
            ) : null}
        </>
    );
}

const MultipleChoiceQuestion = ({
    choices,
    blockId,
    handleSelect,
    answer,
    submission,
    questionType,
}) => {
    if (questionType !== "multiple_choice") return null;

    return (
        <ol className="multiple-choice list-group" type="A">
            {choices.map((choice, index) => (
                <label key={index} className="form-check-label d-flex me-auto">
                    <input
                        className="form-check-input"
                        type="radio"
                        name={blockId}
                        value={choice.id}
                        onChange={(e) => handleSelect(e)}
                        checked={answer && answer.content ? answer.content.choiceId === choice.id : false}
                        disabled={submission && submission.status !== "draft"} />
                    <li>{choice.choiceText}</li>
                </label>
            ))}
        </ol>
    );
}

const TextQuestion = ({ questionType, answer, submission, handleInput }) => {
    if (questionType !== "text") return null;

    return (
        <textarea
            className="form-control"
            placeholder="Type your answer here"
            onChange={(e) => handleInput(e)}
            defaultValue={answer && answer.content ? answer.content.text : ""}
            disabled={submission && submission.status !== "draft"} />
    );
}

const Attachment = ({ attachment }) => {
    if (!attachment) return null;

    return (
        <div className="mb-3">
            {attachment.type.includes("image") ? (
                <img src={attachment.url} className="mw-100" />
            ) : (
                <FileRenderer fileName={attachment.name} fileUrl={attachment.url} />
            )}
        </div>
    );
}

export default QuestionRenderer;
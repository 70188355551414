import React, { useRef, useEffect } from "react";
import { defaultBlock } from "blocks";
import urlifyText from "../../../utils/urlifyText";

const Text = ({ block, dispatch, readOnly }) => {

    const { id, type, data } = block;
    const ref = useRef(null);
    useEffect(() => {
        let content = !readOnly
                        ? data.text
                        : type === "p"
                            ? urlifyText(data.text)
                            : data.text;
        if (ref.current) {
            ref.current.innerHTML = content;
            ref.current.focus();
        }
    }, [readOnly]);

    const handleInput = (e) => {
        const payload = {
            id,
            data: {
                text: e.currentTarget.textContent,
            },
        }

        dispatch({ type: "update", payload });
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            dispatch({ type: "add", payload: defaultBlock(), id });
        }

        if (e.key === "Backspace" && data.text === "") {
            dispatch({ type: "delete", payload: { id } });
        }
    }

    const TextTag = type;

    return (
        <TextTag
            ref={ref}
            {...(!readOnly ? { contentEditable: true } : {})}
            className="py-2 mb-0 course-content-editable"
            data-placeholder={!readOnly ? "Start typing here" : ""}
            onInput={(e) => handleInput(e)}
            onKeyDown={(e) => handleKeyDown(e)} />
    );
}

export default Text;
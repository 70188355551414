import React, { Fragment, useState } from 'react'
import { useMutation } from '@apollo/client';
import { UPDATE_COURSE_MODULE, DESTROY_COURSE_MODULE } from 'graphql/mutations';
import CourseModuleForm from './CourseModuleForm';
import Lessons from './Lessons';
import Dropdown from './Dropdown';
import CourseModuleDrag from './CourseModuleDrag';

const CourseModule = ({ course, courseModule, index, handleSort, readOnly }) => {

  const [isVisible, toggleVisibility] = useState(false);
  const [moduleName, setModuleName] = useState(courseModule.name);
  const [moduleDescription, setModuleDescription] = useState(courseModule.description);

  const [updateModule, { loading, error }] = useMutation(UPDATE_COURSE_MODULE, {
    onCompleted: (data) => handleClose(),
  });

  const [destroyModule, { loading: destroyLoading }] = useMutation(DESTROY_COURSE_MODULE, {
    update(cache, { data: { destroyCourseModule } }) {
      cache.modify({
        id: cache.identify(course),
        fields: {
          courseModules(currentCourseModules, { readField }) {
            return currentCourseModules.filter(courseModule => (
              destroyCourseModule.id !== readField('id', courseModule)
            ));
          },
        },
      });
    }
  });

  const mutateModule = (e) => {
    e.preventDefault();
    updateModule({ variables: { id: courseModule.id, name: moduleName, description: moduleDescription } });
  }

  const handleDestroy = () => {
    if (confirm("You are about to delete a module. Continue?")) {
      destroyModule({ variables: { id: courseModule.id } });
    }
  }

  const handleClose = () => {
    toggleVisibility(false);
  }

  return (
    <Fragment>
      <li className="list-group-item px-0 border-bottom">
        { !!isVisible ? (
          <CourseModuleForm
            mutateModule={mutateModule}
            moduleName={moduleName}
            moduleDescription={moduleDescription}
            setModuleDescription={setModuleDescription}
            setModuleName={setModuleName}
            handleClose={handleClose}
            loading={loading}
            error={error} />
        ) : (
          <Fragment>
            <CourseModuleDrag
              courseModule={courseModule}
              index={index}
              handleSort={handleSort} />
            { !readOnly ? (
              <Dropdown
                toggleVisibility={toggleVisibility}
                handleDestroy={handleDestroy}
                loading={destroyLoading}
                editText="Edit module" />
            ) : null }
          </Fragment>
        ) }
      </li>
      <Lessons
        courseModule={courseModule}
        readOnly={readOnly} />
    </Fragment>
  );
}

export default CourseModule
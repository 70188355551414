import React, { Fragment } from 'react'
import QuestionBuilder from './questions/QuestionBuilder';
import QuestionRenderer from './questions/QuestionRenderer';

const Question = ({ block: { id, data }, dispatch, readOnly, setShowSaveButton, handleSave }) => {

  const setPoints = (points) => {
    const payload = {
      id,
      data: { ...data, points },
    }
    dispatch({ type: 'update', payload });
  }

  const setQuestion = (_question) => {
    const question = {
      id: _question.id,
      questionType: _question.questionType,
      questionText: _question.questionText,
      attachment: _question.attachment,
      choices: _question.choices.map(({ __typename, ...choice }) => choice),
    }
    const payload = {
      id,
      data: { ...data, question },
    }
    dispatch({ type: 'update', payload });
    handleSave(payload);
  }

  return (
    <Fragment>
      { !!data.question ? (
        <li className="question-list-item">
          <QuestionRenderer
            blockId={id}
            data={data}
            setPoints={setPoints}
            readOnly={readOnly} />
        </li>
      ) : null }
      { !data.question && !readOnly ? (
        <QuestionBuilder setQuestion={setQuestion} setShowSaveButton={setShowSaveButton} />
      ) : null }
    </Fragment>
  );
}

export default Question
import React from "react";
import CourseUser from "./CourseUser";
import SplashScreen from "../../elements/SplashScreen";

const CourseUsers = ({ courseUsers, courseId, allowEnlistment }) => {

    return (
        <>
            {Boolean(courseUsers.length) ? (
                <table className="table mt-4">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Role</th>
                            <th scope="col">LRN</th>
                            <th scope="col">Year Level</th>
                            {allowEnlistment && (<th />)}
                        </tr>
                    </thead>
                    <tbody>
                        {courseUsers.map((courseUser) => (
                            <CourseUser key={courseUser.id} courseUser={courseUser} courseId={courseId} allowEnlistment={allowEnlistment} />
                        ))}
                    </tbody>
                </table>
            ) : null}
            {!Boolean(courseUsers.length) ? (
                <SplashScreen
                    title="No student found"
                    subTitle={`There are no student enlisted in this course`}/>
            ) : null}
        </>
    );
}

export default CourseUsers
import React from 'react'

const Choice = ({ choice, id, dispatch }) => {

  const handleChange = (e) => {
    const payload = {
      id,
      choiceText: e.target.value,
      isCorrect: choice.isCorrect,
    }
    dispatch({ type: 'update', payload });
  }

  const handleCheck = (e) => {
    const payload = {
      id,
      choiceText: choice.choiceText,
      isCorrect: e.target.checked,
    }
    dispatch({ type: 'update', payload });
  }

  return (
    <div className="d-flex align-items-start mb-3">
      <li className="w-100 me-3">
        <textarea
          className="form-control"
          placeholder="Type selection here"
          onChange={(e) => handleChange(e)}
          defaultValue={choice.choiceText} />
        <div className="form-check mt-2">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              onChange={(e) => handleCheck(e)}
              defaultChecked={choice.isCorrect} />
            Correct answer
          </label>
        </div>
      </li>
      <span
        className="fe fe-trash text-secondary h3"
        style={{cursor: "pointer"}}
        onClick={() => dispatch({ type: 'delete', payload: { id } })} />
    </div>
  );
}

export default Choice
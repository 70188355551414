import React from "react";

const Button = ({
    type,
    text,
    size,
    onClick,
    className,
    visible,
    disabled,
    children,
}) => {
    if (!visible) return null;

    const buttonType = type === "primary" ? "submit" : "button";
    const buttonClass = [
        "btn mx-2", `btn-${size}`,
        type === "primary" ? "btn-primary" : "",
        type === "secondary" ? "btn-transparent border" : "",
    ].join(" ");

    return (
        <button
            className={className || buttonClass}
            type={buttonType}
            onClick={onClick}
            disabled={disabled}>
                {text}
                {children}
        </button>
    );
}

Button.defaultProps = {
    type: "primary",
    text: "Button",
    size: "md",
    onClick: () => {},
    className: null,
    visible: true,
    disabled: false,
};

export default Button;
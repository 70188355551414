export default function formatDate(
    date,
    options = { locale: "en-PH",
                dateStyle: "long",
                timeStyle: "short",
                timeZone: "Asia/Manila",
              }
    ) {
    const dateTime = new Date(date);
    let { locale, ...format } = options;
    return new Intl.DateTimeFormat(locale, format).format(dateTime);
}
import React, { useState } from 'react'
import { useMutation } from '@apollo/client';
import { COURSE_MODULE_FIELDS } from 'graphql/fragments';
import { CREATE_COURSE_MODULE } from 'graphql/mutations';
import CourseModuleForm from './CourseModuleForm';

const NewCourseModule = ({ course }) => {

  const [isVisible, toggleVisibility] = useState(false);
  const [moduleName, setModuleName] = useState("");

  const [createModule, { loading, error }] = useMutation(CREATE_COURSE_MODULE, {
    onCompleted: (data) => handleClose(),
    update(cache, { data: { createCourseModule: { courseModule } } }) {
      cache.modify({
        id: cache.identify(course),
        fields: {
          courseModules(currentCourseModules) {
            const newCourseModule = cache.writeFragment({
              data: courseModule,
              fragment: COURSE_MODULE_FIELDS
            });
            
            return [...currentCourseModules, newCourseModule];
          },
        },
      });
    }
  });

  const mutateModule = (e) => {
    e.preventDefault();
    createModule({ variables: { name: moduleName, courseId: course.id } })
  }

  const handleClose = () => {
    setModuleName("");
    toggleVisibility(false);
  }

  return (
    <div className="py-2">
      { !!isVisible ? (
        <CourseModuleForm
          mutateModule={mutateModule}
          moduleName={moduleName}
          setModuleName={setModuleName}
          handleClose={handleClose}
          loading={loading}
          error={error} />
      ) : (
        <a className="cursor-pointer" onClick={() => toggleVisibility(true)}>
          + Add Module
        </a>
      ) }
    </div>
  );
}

export default NewCourseModule
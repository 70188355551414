import React, { Fragment } from 'react'
import Choice from './Choice';

const Choices = ({ choices, dispatch }) => {

  return (
    <Fragment>
      <h4>Answers</h4>
      <div className="ps-3 mb-4">
        <ol className="list-group" type="a">
          { choices.map((choice) => (
            <Choice
              key={choice.id}
              choice={choice}
              id={choice.id}
              dispatch={dispatch} />
          )) }
        </ol>
        <a
          className=""
          style={{cursor: "pointer"}}
          onClick={() => dispatch({ type: 'add' })}>
          + Add Answer
        </a>
      </div>
    </Fragment>
  );
}

export default Choices
import React, { Fragment, useState, useEffect } from 'react'
import { useParams, Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';

const SubmissionsTable = ({ submissions }) => {

  let params = useParams();

  const itemsPerPage = 10;

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);

  const [sortCriteria, setSortCriteria] = useState('');
  const [sortReverse, setSortReverse] = useState(false);
  
  // On filter select, reset sort rules and return to first page
  useEffect(() => {
    setSortCriteria('');
    setSortReverse(false);
    setPage(0);
  }, [submissions]);

  useEffect(() => {
    const sortedItems = [...submissions];
    const sortValue = sortReverse ? -1 : 1;

    if (sortCriteria === 'name') {
      sortedItems.sort((a, b) => (a.courseContent.name).localeCompare(b.courseContent.name) * sortValue);
    } else if (sortCriteria === 'lesson') {
      sortedItems.sort((a, b) => (a.lesson.name).localeCompare(b.lesson.name) * sortValue);
    } else if (sortCriteria === 'user') {
      sortedItems.sort((a, b) => (a.user.name).localeCompare(b.user.name) * sortValue);
    } else if (sortCriteria === 'date') {
      sortedItems.sort((a, b) => (a.submitDate || '').localeCompare(b.submitDate || '') * sortValue);
    } else if (sortCriteria === 'score') {
      sortedItems.sort((a, b) => (a.totalScore - b.totalScore) * sortValue);
    }

    const offset = (page * itemsPerPage) % submissions.length;
    setCurrentItems(sortedItems.slice(offset, offset + itemsPerPage));
    setPageCount(Math.ceil(submissions.length / itemsPerPage));
  }, [page, itemsPerPage, sortCriteria, sortReverse, submissions]);

  const handlePageClick = (e) => {
    setPage(e.selected);
  };

  const handleSort = (criteria) => {
    if (criteria === sortCriteria) {
      setSortReverse(sortReverse => !sortReverse);
    } else {
      setSortCriteria(criteria);
      setSortReverse(false);
    }
  }

  const sortIcon = sortReverse ? 'arrow-down' : 'arrow-up';

  return (
    <Fragment>
      <div className="card">
        <div className="card-body">
          <table className="table table-striped mb-0">
            <thead>
              <tr>
                <th scope="col">
                  <div className="d-inline-flex cursor-pointer" onClick={() => handleSort('name')}>
                    Name
                    <span className={`fe fe-${sortCriteria === 'name' ? sortIcon : 'align-justify'} ms-2`} />
                  </div>
                </th>
                <th scope="col">
                  <div className="d-inline-flex cursor-pointer" onClick={() => handleSort('lesson')}>
                    Lesson
                    <span className={`fe fe-${sortCriteria === 'lesson' ? sortIcon : 'align-justify'} ms-2`} />
                  </div>
                </th>
                <th scope="col">
                  <div className="d-inline-flex cursor-pointer" onClick={() => handleSort('date')}>
                    Date Submitted
                    <span className={`fe fe-${sortCriteria === 'date' ? sortIcon : 'align-justify'} ms-2`} />
                  </div>
                </th>
                <th scope="col">
                  <div className="d-inline-flex cursor-pointer" onClick={() => handleSort('user')}>
                    Submitted By
                    <span className={`fe fe-${sortCriteria === 'user' ? sortIcon : 'align-justify'} ms-2`} />
                  </div>
                </th>
                <th scope="col">
                  <div className="d-inline-flex cursor-pointer" onClick={() => handleSort('score')}>
                    Score
                    <span className={`fe fe-${sortCriteria === 'score' ? sortIcon : 'align-justify'} ms-2`} />
                  </div>
                </th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              { currentItems.map((submission) => (
                <tr key={submission.id}>
                  <td>{submission.courseContent.name}</td>
                  <td>{submission.lesson.name}</td>
                  <td>{submission.formatSubmitDate}</td>
                  <td>{submission.user.name}</td>
                  <td>{submission.totalScore}</td>
                  <td>
                    <Link to={`/teacher/my/courses/${params.slug}/submissions/${submission.id}`}>
                      View
                    </Link>
                  </td>
                </tr>
              )) }
            </tbody>
          </table>
        </div>
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel={<span className="fe fe-chevron-right" />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel={<span className="fe fe-chevron-left" />}
        renderOnZeroPageCount={null}
        forcePage={page}
        containerClassName="pagination align-items-center justify-content-end"
        pageClassName="page"
        activeClassName="rounded-circle bg-light"
        disabledLinkClassName="text-muted"
      />
    </Fragment>
  );
}

export default SubmissionsTable
import React, { Fragment } from "react";
import { useOutletContext } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import SidebarToggler from "../../content_builder/sidebar/SidebarToggler";
import formatDate from "../../../utils/formatDate";
import DescriptionBlock from "../../content_builder/DescriptionBlock";
import pluralizeString from "../../../utils/pluralizeString";

const HomePage = () => {
    const { course } = useOutletContext();

    return (
        <div className="col-12 col-md-9 course-main-panel">
            {course ? (
                <Fragment>
                    <Breadcrumbs crumbs={[]} />
                    {/* TODO: create a re-usable component for headers */}
                    {/* TODO: add last_updated_by_user_id on course_contents table to show who updated course */}
                    <div className="d-flex flex-column section-title-container mb-4">
                        <h2 className="mb-2">{course.name}</h2>
                        <div className="d-flex flex-column flex-md-row mt-2">
                            <span>Last update: {formatDate(course.updatedAt)}</span>
                            <SidebarToggler text="Navigate course" />
                        </div>
                    </div>
                    <DescriptionBlock
                        title="About this course"
                        editMode={false}
                        readOnly
                        defaultValue={course.description}/>
                    <div className="mt-6">
                        <h3>Course Overview</h3>
                        <ul className="p-1 border-top">
                            {[...course.courseModules]
                                .sort((a, b) => a.ordinality - b.ordinality)
                                .map(courseModule => (
                                    <li
                                        key={courseModule.id}
                                        className="d-flex py-4 border-bottom">
                                        <div className="row w-100">
                                            <div className="col-9 px-4">
                                                <span>{courseModule.name}</span>
                                            </div>
                                            <div className="col-3 text-center">
                                                <span>{pluralizeString("Lesson", courseModule.lessons.length)}</span>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            {!Boolean(course.courseModules.length) ? (
                                <p className="mb-0">No modules yet.</p>
                            ) : null}
                        </ul>
                    </div>
                </Fragment>
            ) : null}
        </div>
    );
}

export default HomePage;
import React, { Fragment, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useFetchCourse } from 'hooks/useFetchCourse';
import Sidebar from './Sidebar';
import MainPanel from './MainPanel';
import SidebarDrawer from './sidebar/SidebarDrawer';

const ContentBuilder = ({ readOnly }) => {

  let params = useParams();

  const [isReadOnly, setIsReadOnly] = useState(readOnly || false);

  const { data } =  useFetchCourse({ slug: params.slug });

  return (
    <div className="container-fluid d-flex py-4 m-0">
      { data && data.fetchCourse ? (
        <Fragment>
          <SidebarDrawer>
            <Sidebar course={data.fetchCourse} readOnly={isReadOnly} onDrawer />
          </SidebarDrawer>
          <Sidebar course={data.fetchCourse} readOnly={isReadOnly} />
          <MainPanel course={data.fetchCourse} readOnly={isReadOnly} setIsReadOnly={setIsReadOnly} />
        </Fragment>
      ) : null }
    </div>
  );
}

export default ContentBuilder
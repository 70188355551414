import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { useFetchCourse } from 'hooks/useFetchCourse';
import { ACTIVITY_TYPES } from 'blocks';
import Select from 'react-select';

const SubmissionFilters = ({ fetchSubmissions }) => {

  let params = useParams();
  const { data } = useFetchCourse({ slug: params.slug });


  const [lessons, setLessons] = useState([]);
  const [activities, setActivities] = useState([]);

  const [lessonId, setLesson] = useState('');
  const [activityType, setActivityType] = useState('');
  const [courseContent, setCourseContent] = useState(null);
  const [user, setUser] = useState(null);

  // On course query, set lessons for dropdown
  useEffect(() => {
    if (data && data.fetchCourse) {
      const lessons = [...data.fetchCourse.courseModules]
        .sort((a, b) => a.ordinality - b.ordinality)
        .map(courseModule => courseModule.lessons).flat()
        .sort((a, b) => a.ordinality - b.ordinality);

      setLessons(lessons);
    }
  }, [data]);

  // On course query or filter select, set activities for dropdown
  useEffect(() => {
    const activities = lessons
      .filter(lesson => lessonId ? lesson.id === lessonId : true)
      .map(lesson => lesson.courseContents).flat()
      .filter(courseContent => courseContent.isActivity && (
        !!activityType ? courseContent.contentType === activityType : true
      ))
      .sort((a, b) => a.ordinality - b.ordinality)
      .map(courseContent => ({ label: courseContent.name, value: courseContent.id }));

    setActivities(activities);
  }, [lessons, lessonId, activityType]);

  // On lesson select, reset course content filter
  const handleSelect = (e) => {
    setLesson(e.target.value);
    setCourseContent(null);
  }

  // On activity type select; reset course content filter
  const handleType = (e) => {
    setActivityType(e.target.value);
    setCourseContent(null);
  }

  // On clear, reset all filters
  const handleClear = () => {
    setLesson('');
    setActivityType('');
    setCourseContent(null);
    setUser(null);
  }

  return (
    <div className="p-4 bg-light mb-3">
      <div className="row mx-0 mb-3">
        <label className="col-6 col-lg-3 ps-0">
          <span className="fw-bold d-inline-block mb-2">Lesson</span>
          <select className="form-select" value={lessonId} onChange={(e) => handleSelect(e)}>
            <option value="">All Lessons</option>
            { lessons.map((lesson) => (
              <option key={lesson.id} value={lesson.id}>{lesson.name}</option>
            )) }
          </select>
        </label>
        <label className="col-6 col-md-3">
          <span className="fw-bold d-inline-block mb-2">Activity Type</span>
          <select className="form-select" value={activityType} onChange={(e) => handleType(e)}>
            <option value="">All Types</option>
            { ACTIVITY_TYPES.map((activity) => (
              <option key={activity.type} value={activity.type}>{activity.label}</option>
            )) }
          </select>
        </label>
        <label className="col-6 col-md-3 ps-0">
          <span className="fw-bold d-inline-block mb-2">Activity Name</span>
          <Select
            value={courseContent}
            onChange={setCourseContent}
            options={activities}
            placeholder="Select Activity"
            isClearable={true}
          />
        </label>
        <label className="col-6 col-md-3 pe-0">
          <span className="fw-bold d-inline-block mb-2">Student Name</span>
          <Select
            value={user}
            onChange={setUser}
            options={data && data.fetchCourse ? (
              data.fetchCourse.enlistedUsers.map(user => ({ label: user.name, value: user.id }))
            ) : []}
            placeholder="Select Student"
            isClearable={true}
          />
        </label>
      </div>
      <div className="text-end">
        <button
          type="button"
          className="btn btn-outline-dark me-3"
          onClick={() => handleClear()}
        >
          Clear filters
        </button>
        <button
          type="button"
          className="btn btn-primary"
          disabled={!courseContent && !user}
          onClick={() => fetchSubmissions({ variables: {
            slug: params.slug,
            courseContentId: courseContent && courseContent.value,
            userId: user && user.value,
          } })}
        >
          Search
        </button>
      </div>
    </div>
  );
}

export default SubmissionFilters
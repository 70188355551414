import React from "react";
import { default as PaperClip } from "../../../../assets/images/paper-clip.svg";
import Button from "../../elements/Button";
import { Download } from "react-feather";

const FileRenderer = ({ fileName, removeFile, hasDelete, fileType, fileUrl }) => {
    const renderImage = fileType?.includes("image");

    const containerClasses = [
        "file-renderer flex-column gap-2",
        renderImage ? "" : "",
    ].join(" ");

    return (
        <div className={containerClasses}>
            {renderImage ? (
                <img src={fileUrl} alt="Uploaded image file" />
            ) : null}
            <div className="d-flex justify-content-between w-100">
                <div className="d-flex align-items-center gap-4">
                    <img src={PaperClip} alt="Paperclip icon" />
                    <span>{fileName}</span>
                </div>
                <div className="d-flex align-items-center gap-3">
                    <a href={fileUrl} download={fileName}>
                        <Download className="text-body" size={20} />
                    </a>
                    {hasDelete ? (
                        <Button
                            type="secondary"
                            className="btn btn-transparent text-danger"
                            text="Delete"
                            onClick={() => removeFile()} />
                    ) : null}
                </div>
            </div>
        </div>
    );
}

FileRenderer.defaultProps = {
    fileName: null,
    removeFile: () => {},
    hasDelete: false,
}

export default FileRenderer;
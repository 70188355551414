import { gql } from '@apollo/client';

export const COURSE_CONTENT_FIELDS = gql`
  fragment CourseContentFields on CourseContent {
    id
    name
    slug
    ordinality
    description
    contentType
    blocks
    isActivity
    updatedAt
  }
`

export const LESSON_FIELDS = gql`
  fragment LessonFields on Lesson {
    id
    name
    slug
    ordinality
    description
  }
`

export const PAGY_FIELDS = gql`
  fragment pagyFields on Pagy {
    count
    from
    last
    next
    offset
    page
    pages
    prev
    to
  }
`

export const COURSE_MODULE_FIELDS = gql`
  fragment CourseModuleFields on CourseModule {
    id
    name
    slug
    ordinality
    description
  }
`

export const COURSE_FIELDS = gql`
  ${COURSE_MODULE_FIELDS}
  ${LESSON_FIELDS}
  ${COURSE_CONTENT_FIELDS}
  fragment CourseFields on Course {
    id
    name
    isFresh
    slug
    description
    yearLevel {
      id
      name
    }
    teacher {
      id
      name
    }
    formatGradingPeriod
    formatUpdatedAt
    updatedAt
    courseModules {
      ...CourseModuleFields
      lessons {
        ...LessonFields
        courseContents {
          ...CourseContentFields
          courseContentProgress
        }
      }
    }
    enlistedUsers {
      id
      name
    }
  }
`

export const USER_FIELDS = gql`
  fragment UserFields on User {
    id
    name
    email
    lrn
    yearLevel {
      id
      name
    }
  }
`

export const SUBMISSION_FIELDS = gql`
  ${COURSE_CONTENT_FIELDS}
  ${LESSON_FIELDS}
  fragment SubmissionFields on Submission {
    id
    status
    answers
    formatStartDate
    submitDate
    formatSubmitDate
    updatedAt
    metadata
    totalScore
    courseContent {
      ...CourseContentFields
    }
    lesson {
      ...LessonFields
    }
    user {
      name
    }
    lastCheckedByUser {
      name
    }
  }
`

export const QUESTION_FIELDS = gql`
  fragment QuestionFields on Question {
    id
    questionType
    questionText
    category
    choices {
      id
      choiceText
      isCorrect
    }
    attachment
    formatUpdatedAt
  }
`
import React from "react";
import Button from "../elements/Button";

const PageButton = ({ onClick, children, currentPage }) => {
    const c = [
        "btn btn-sm rounded-lg mx-1",
        currentPage
            ? "btn-primary pointer-events-none"
            : "btn-light",
    ].join(" ");

    return (
        <Button
            type="secondary"
            text=""
            disabled={currentPage}
            className={c}
            onClick={onClick}>
            {children}
        </Button>
    );
};

export default PageButton;

import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { CHECK_SUBMISSION } from "graphql/mutations";
import Button from "../../elements/Button";
import { useParams } from "react-router-dom";
import currentPortal from "../../../utils/currentPortal";

const Sidebar = ({
    submission,
    submission: { courseContent, metadata },
    activityState,
    onDrawer,
    readOnly,
}) => {

    const [deductions, setDeductions] = useState(metadata.deductions || 0);
    const [bonus, setBonus] = useState(metadata.bonus || 0);
    const [remarks, setRemarks] = useState(metadata.remarks || "");
    const published = submission?.status === "published";
    const params = useParams();

    const onStudent = currentPortal() === "student";
    const backUrl = onStudent ? `/student/my/classes/${params.slug}/submissions`
                              : `/teacher/my/courses/${params.slug}/submissions`;

    const handleDeductions = (e) => {
        const deductions = parseFloat(e.target.value) || 0;
        if (deductions >= 0) {
            setDeductions(deductions);
        }
    }

    const handleBonus = (e) => {
        const bonus = parseFloat(e.target.value) || 0;
        if (bonus >= 0) {
            setBonus(bonus);
        }
    }

    const [checkSubmission, { loading }] = useMutation(CHECK_SUBMISSION, {
        onCompleted: (data) => {
            let submission = data.checkSubmission.submission;
            if (submission.status === "published") {
                alert(`${submission.courseContent.contentType} score is now published.`)
            } else {
                alert("Progress saved.")
            }
        },
        onError: (error) => { alert("Failed to save progress.") },
    });

    const handleSave = (status) => {
        if (!allItemsChecked(status)) {
            alert("Score cannot be published. You might have missed to check some items.")
            return;
        }
        const variables = {
            id: submission.id,
            status: status,
            answers: activityState,
            deductions,
            bonus,
            remarks
        }
        checkSubmission({ variables });
    }

    function allItemsChecked(status) {
        if (status !== "published") return true;
        return !activityState.some(q => !q.status.isChecked);
    }

    const questions = courseContent.blocks.filter(block => block.type === "question");
    const totalPoints = questions.reduce((a, b) => a + parseFloat(b.data.points) || 0, 0);
    const checkedItems = activityState.filter(({ status }) => status.isChecked).length;
    const currentPoints = activityState.reduce((a, b) => a + parseFloat(b.status.points) || 0, 0);
    const containerClasses = [
        "syllabus-sidebar",
        !onDrawer ? "p-5 sidebar h-auto col-3 d-none d-md-block" : "px-3 py-6 h-100",
    ].join(" ");


    return (
        <div className={containerClasses}>
            <a
                href={backUrl}
                className="d-flex mb-6 text-dark">
                    <span className="fe fe-chevron-left me-2" />
                    {`${onStudent ? "My" : "Course"} submissions`}
            </a>
            <div className="d-flex justify-content-between">
                <p className="fw-bold">Score:</p>
                {!readOnly ? (
                    <p>{currentPoints}/{totalPoints}</p>
                ) : (
                    <p>{published ? `${currentPoints}/${totalPoints}` : "Unpublished"}</p>
                )}
            </div>
            <label className="d-flex align-items-center justify-content-between mb-4">
                Deductions:
                {readOnly ? (
                    <p>{published ? submission.metadata.deductions : "Unpublished"}</p>
                ) : (
                    <input
                        className="form-control form-control-sm points-input border-0"
                        type="number"
                        step={1}
                        defaultValue={deductions}
                        onChange={(e) => handleDeductions(e)} />
                )}
            </label>
            <label className="d-flex align-items-center justify-content-between">
                Bonus:
                {readOnly ? (
                    <p>{published ? submission.metadata.bonus : "Unpublished"}</p>
                ) : (
                    <input
                        className="form-control form-control-sm points-input border-0 points-input"
                        type="number"
                        step={1}
                        min={0}
                        defaultValue={bonus}
                        onChange={(e) => handleBonus(e)} />
                )}
            </label>
            <hr />
            <div className="d-flex justify-content-between mb-3">
                <h2 className="fw-bold">Total:</h2>
                {!readOnly ? (
                    <h2 className="fw-bold">{currentPoints - deductions + bonus}/{totalPoints}</h2>
                ) : (
                    <h2 className="fw-bold">
                        {published ? `${currentPoints - deductions + bonus}/${totalPoints}` : "Unpublished"}
                    </h2>
                )}
            </div>
            <div className="d-flex flex-column">
                {readOnly ? (
                    <>
                        {published ? (
                            <>
                                <label>Remarks:</label>
                                <div className="course-content-block py-3">
                                    {submission.metadata.remarks || "No remarks"}
                                </div>
                            </>
                        ) : null}
                    </>
                ) : (
                    <label>
                        Remarks:
                        <textarea
                            className="form-control"
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)} />
                    </label>
                )}
                {!readOnly ? (
                    <div className="d-flex flex-column">
                        <div className="text-end mb-4">
                            <span className="text-muted text-sm">{checkedItems}/{questions.length} Items checked</span>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <Button
                                type="button"
                                text="Save as draft"
                                className="btn btn-secondary bg-white text-dark border mx-2"
                                disabled={!!loading}
                                onClick={() => handleSave("checked")} />
                            <Button
                                type="button"
                                text="Publish results"
                                className="btn btn-primary mx-2"
                                disabled={!!loading}
                                onClick={() => handleSave("published")} />
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default Sidebar
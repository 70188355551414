import { v4 as uuid } from 'uuid';

export const defaultBlock = () => {
  return {
    id: uuid(),
    type: 'p',
    data: {
      text: '',
    },
  }
}

export const BLOCK_TYPES = [
  {
    type: "p",
    label: "Paragraph",
    description: "Plain text",
    data: {
      text: '',
    }
  },
  {
    type: "h1",
    label: "Heading 1",
    description: "Big section heading",
    data: {
      text: '',
    }
  },
  {
    type: "h2",
    label: "Heading 2",
    description: "Medium section heading",
    data: {
      text: '',
    }
  },
  {
    type: "h3",
    label: "Heading 3",
    description: "Small section heading",
    data: {
      text: '',
    }
  },
  {
    type: "ul",
    label: "Bulleted List",
    description: "Create a list with bullets",
    data: {
      items: [''],
    }
  },
  {
    type: "ol",
    label: "Numbered List",
    description: "Create a list with numbering",
    data: {
      items: [''],
    }
  },
  {
    type: "img",
    label: "Image",
    description: "Upload an image (Max. upload size: 5 MB)",
    data: {
      file: {
        name: '',
        type: '',
        size: '',
        url: '',
      },
    }
  },
  {
    type: "file",
    label: "File",
    description: "Upload a document file",
    data: {
      file: {
        name: '',
        type: '',
        size: '',
        url: '',
      },
    }
  },
  {
    type: "tex",
    label: "TeX",
    description: "Write a mathematical equation",
    data: {
      equation: '',
    }
  },
  {
    type: "embed",
    label: "Youtube video",
    description: "Upload or add Youtube link",
    data: {
      url: "",
      type: "", // youtube, spotify, etc.
    }
  },
  {
    type: "divider",
    label: "Divider",
    description: "Add horizontal rule",
    data: {}
  },
]

export const ACTIVITY_BLOCKS = [
  {
    type: "question",
    label: "Question",
    description: "Multiple choice, Identification, or File attachment",
    data: {
      question: null,
      points: 1,
    },
  },
]

export const QUESTION_TYPES = [
  {
    type: "text",
    label: "Text",
    description: "Student will input their answer",
  },
  {
    type: "multiple_choice",
    label: "Multiple Choice",
    description: "Student will select from choices",
  },
  {
    type: "file_attachment",
    label: "File Attachment",
    description: "Student will input their answer based on attached file",
  },
]

export const QUESTION_CATEGORIES = [
  "mathematics",
  "science",
  "language",
  "history",
]

export const defaultChoice = () => {
  return {
    id: uuid(),
    choiceText: '',
    isCorrect: false,
  }
}

export const ACTIVITY_TYPES = [
  {
    type: "assignment",
    label: "Assignment",
    description: "Description",
  },
  {
    type: "quiz",
    label: "Quiz",
    description: "Description",
  },
  {
    type: "exam",
    label: "Examination",
    description: "Description",
  },
]

export const CONTENT_TYPES = [
  {
    type: "lecture",
    label: "Lecture",
    description: "Description",
  },
  ...ACTIVITY_TYPES,
]
import React, { Fragment, useEffect, useState } from 'react'
import CourseContents from './CourseContents';
import { useParams } from "react-router-dom";

const Lesson = ({ lesson }) => {

  let params = useParams();

  const [showSections, toggleSections] = useState(false);

  // On load or section select, expand lesson tab of active section, and collapse other lessons
  useEffect(() => {
    if (params.slug2) {
      const courseContent = lesson.courseContents.find(courseContent => courseContent.slug === params.slug2);
      toggleSections(!!courseContent);
    }
  }, [params]);

  return (
    <Fragment>
      <li className="list-group-item px-0 py-2">
        <div className="d-flex">
          <div className="px-1 me-3 h-50" style={{backgroundColor: "#C6D7F1"}}>
            <span className="fe fe-file" />
          </div>
          <a href="" className="d-inline-flex text-dark"
            onClick={(e) => { e.preventDefault(); toggleSections(showSections => !showSections) }}>
            <p className="me-2 mb-0">{lesson.name}</p>
            <span className={`fe fe-chevron-${!!showSections ? 'up' : 'down'}`} />
          </a>
        </div>
      </li>
      { !!showSections ? (
        <CourseContents courseContents={lesson.courseContents} />
      ) : null }
    </Fragment>
  );
}

export default Lesson
import React, { useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom';
import CourseNav from './CourseNav';
import SidebarDrawer from '../content_builder/sidebar/SidebarDrawer';
import useCourseQuery from '../../hooks/useCourseQuery';

const Course = () => {
    const params = useParams();
    const [course, setCourse] = useState(undefined);
    const { fetchCourseAsync } = useCourseQuery();

    useEffect(() => {
        fetchCourseAsync({ variables: { slug: params.slug } })
            .then(res => setCourse(res.data.fetchCourse));
    }, []);

    return (
        <div className="m-0 d-flex">
            <SidebarDrawer>
                <CourseNav onDrawer />
            </SidebarDrawer>
            <CourseNav />
            <Outlet context={{ course }} />
        </div>
    );
}

export default Course
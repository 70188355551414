import React, { useContext } from 'react';
import { useParams, Link } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { FIND_SUBMISSION } from 'graphql/queries';
import { UserContext } from 'context';

const CourseContent = ({ courseContent }) => {

  let params = useParams();

  const userId = useContext(UserContext);

  const { data } =  useQuery(FIND_SUBMISSION, { variables: { userId, courseContentId: courseContent.id } });

  function toggleSidebarDrawer() {
    const backDrop = document.querySelectorAll(".offcanvas-backdrop");
    if (!Boolean(backDrop.length)) return;

    backDrop.forEach((element) => {
      element.dispatchEvent(new MouseEvent("mousedown"));
    });
  }

  return (
    <li className="list-group-item px-0 py-2">
      <div className="d-flex">
        <div
          className={`px-1 h-50 me-3 h-50 ${courseContent.slug === params.slug2 ? 'bg-primary text-white' : ''}`}
          style={{backgroundColor: "#C6D7F1"}}>
          <span className={`fe fe-${!!courseContent.isActivity ? 'activity' : 'menu'}`} />
        </div>
        <Link to={`/student/my/classes/${params.slug}/content/${courseContent.slug}`}
              className="text-dark me-2"
              onClick={toggleSidebarDrawer}>
          {courseContent.name}
        </Link>
        { data && data.findSubmission && data.findSubmission.status !== 'draft' ? (
          <span className="fe fe-check-circle text-success" />
        ) : null }
      </div>
    </li>


  );
}

export default CourseContent;
import { gql } from '@apollo/client';
import {
    SUBMISSION_FIELDS, COURSE_MODULE_FIELDS, LESSON_FIELDS,
    COURSE_CONTENT_FIELDS, USER_FIELDS, QUESTION_FIELDS
} from './fragments'

export const CREATE_COURSE_USER = gql`
  ${USER_FIELDS}
  mutation($courseId: ID!, $userId: ID!) {
    createCourseUser(input: {
      params: {
        courseId: $courseId,
        userId: $userId,
      }
    }) {
      courseUser {
        id
        role
        course {
          id
          name
          slug
          teacher {
            name
          }
          isEnlisted
        }
        user {
          ...UserFields
        }
      }
    }
  }
`

export const DESTROY_COURSE_USER = gql`
  mutation($id: ID!) {
    destroyCourseUser(input: {
      id: $id,
    }) {
      id
    }
  }
`

export const CREATE_SUBMISSION = gql`
  ${SUBMISSION_FIELDS}
  mutation($courseContentId: ID!, $status: String, $answers: JSON!) {
    createSubmission(input: {
      params: {
        courseContentId: $courseContentId,
        status: $status,
        answers: $answers,
      }
    }) {
      submission {
        ...SubmissionFields
      }
    }
  }
`

export const UPDATE_SUBMISSION = gql`
  mutation($id: ID!, $status: String!, $answers: JSON!) {
    updateSubmission(input: {
      id: $id,
      params: {
        status: $status,
        answers: $answers,
      }
    }) {
      submission {
        id
        status
        answers
        formatStartDate
        formatSubmitDate
      }
    }
  }
`

export const CHECK_SUBMISSION = gql`
  mutation($id: ID!, $status: String!, $answers: JSON!, $deductions: Float, $bonus: Float, $remarks: String) {
    checkSubmission(input: {
      id: $id,
      params: {
        status: $status,
        answers: $answers,
        deductions: $deductions,
        bonus: $bonus,
        remarks: $remarks,
      }
    }) {
      submission {
        id
        status
        answers
        metadata
        courseContent {
          contentType
        }
      }
    }
  }
`

export const CREATE_MEDIA_FILE = gql`
  mutation($fileData: String!, $fileName: String!, $fileType: String!) {
    createMediaFile(input: {
      params: {
        fileData: $fileData,
        fileName: $fileName,
        fileType: $fileType,
      }
    }) {
      mediaFile {
        id
        fileName
        fileType
        fileUrl
      }
    }
  }
`

export const CREATE_COURSE_MODULE = gql`
  ${COURSE_MODULE_FIELDS}
  ${LESSON_FIELDS}
  ${COURSE_CONTENT_FIELDS}
  mutation($name: String!, $courseId: ID!) {
    createCourseModule(input: {
      params: {
        name: $name,
        courseId: $courseId,
      }
    }) {
      courseModule {
        ...CourseModuleFields
        lessons {
          ...LessonFields
          courseContents {
            ...CourseContentFields
          }
        }
      }
    }
  }
`

export const UPDATE_COURSE_MODULE = gql`
  ${COURSE_MODULE_FIELDS}
  mutation($id: ID!, $name: String, $courseId: ID, $description: String) {
    updateCourseModule(input: {
      id: $id,
      params: {
        name: $name,
        courseId: $courseId,
        description: $description,
      }
    }) {
      courseModule {
        ...CourseModuleFields
      }
    }
  }
`

export const DESTROY_COURSE_MODULE = gql`
  mutation($id: ID!) {
    destroyCourseModule(input: {
      id: $id,
    }) {
      id
    }
  }
`

export const CREATE_LESSON = gql`
  ${LESSON_FIELDS}
  ${COURSE_CONTENT_FIELDS}
  mutation($name: String!, $courseModuleId: ID!) {
    createLesson(input: {
      params: {
        name: $name,
        courseModuleId: $courseModuleId,
      }
    }) {
      lesson {
        ...LessonFields
        courseContents {
          ...CourseContentFields
        }
      }
    }
  }
`

export const UPDATE_LESSON = gql`
  ${LESSON_FIELDS}
  mutation($id: ID!, $name: String, $ordinality: Int, $courseModuleId: ID, $description: String) {
    updateLesson(input: {
      id: $id,
      params: {
        name: $name,
        ordinality: $ordinality,
        courseModuleId: $courseModuleId,
        description: $description,
      }
    }) {
      lesson {
        ...LessonFields
      }
    }
  }
`

export const DESTROY_LESSON = gql`
  mutation($id: ID!) {
    destroyLesson(input: {
      id: $id,
    }) {
      id
    }
  }
`

export const CREATE_COURSE_CONTENT = gql`
  ${COURSE_CONTENT_FIELDS}
  mutation($name: String!, $lessonId: ID!, $contentType: String) {
    createCourseContent(input: {
      params: {
        name: $name,
        lessonId: $lessonId,
        contentType: $contentType,
      }
    }) {
      courseContent {
        ...CourseContentFields
      }
    }
  }
`

export const UPDATE_COURSE_CONTENT = gql`
  ${COURSE_CONTENT_FIELDS}
  mutation($id: ID!, $name: String, $ordinality: Int, $lessonId: ID, $blocks: JSON, $contentType: String, $description: String) {
    updateCourseContent(input: {
      id: $id,
      params: {
        name: $name,
        ordinality: $ordinality,
        lessonId: $lessonId,
        blocks: $blocks,
        contentType: $contentType,
        description: $description,
      }
    }) {
      courseContent {
        ...CourseContentFields
      }
    }
  }
`

export const DESTROY_COURSE_CONTENT = gql`
  mutation($id: ID!) {
    destroyCourseContent(input: {
      id: $id,
    }) {
      id
    }
  }
`

export const REORDER_COURSE_MODULES = gql`
  mutation($id: ID!, $courseModuleIds: [ID!]!) {
    reorderCourseModules(input: {
      id: $id,
      courseModuleIds: $courseModuleIds,
    }) {
      courseModules {
        id
        ordinality
      }
    }
  }
`

export const REORDER_LESSONS = gql`
  mutation($id: ID!, $lessonIds: [ID!]!) {
    reorderLessons(input: {
      id: $id,
      lessonIds: $lessonIds,
    }) {
      lessons {
        id
        ordinality
      }
    }
  }
`

export const REORDER_COURSE_CONTENTS = gql`
  mutation($id: ID!, $courseContentIds: [ID!]!) {
    reorderCourseContents(input: {
      id: $id,
      courseContentIds: $courseContentIds,
    }) {
      courseContents {
        id
        ordinality
      }
    }
  }
`

export const CREATE_QUESTION = gql`
  ${QUESTION_FIELDS}
  mutation($questionType: String!, $questionText: String!, $category: String!, $choices: [ChoiceInput!],
    $fileData: String!, $fileName: String!, $fileType: String!) {
    createQuestion(input: {
      params: {
        questionType: $questionType,
        questionText: $questionText,
        category: $category,
        choices: $choices,
      },
      file: {
        fileData: $fileData,
        fileName: $fileName,
        fileType: $fileType,
      }
    }) {
      question {
        ...QuestionFields
      }
    }
  }
`

export const UPDATE_QUESTION = gql`
  mutation($id: ID!, $questionType: String!, $questionText: String!, $category: String!,
    $choices: [ChoiceInput!], $fileData: String!, $fileName: String!, $fileType: String!) {
    updateQuestion(input: {
      id: $id,
      params: {
        questionType: $questionType,
        questionText: $questionText,
        category: $category,
        choices: $choices,
      },
      file: {
        fileData: $fileData,
        fileName: $fileName,
        fileType: $fileType,
      }
    }) {
      question {
        id
      }
    }
  }
`

export const READ_COURSE_CONTENT = gql`
    mutation readContent($slug: String!) {
        readCourseContent(input: { slug: $slug }) {
            isSuccess
        }
    }
`;

export const DONE_COURSE_CONTENT = gql`
    mutation doneContent($slug: String!) {
        doneCourseContent(input: { slug: $slug }) {
            isSuccess
        }
    }
`;

import React, { Fragment, useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { FETCH_USERS } from "graphql/queries"
import Users from "./partials/Users";
import YearLevelFilter from "./partials/YearLevelFilter";
import { useOutletContext } from "react-router";
import Button from "../elements/Button";
import SplashScreen from "../elements/SplashScreen";

const SearchUsers = ({ courseUsers, courseId }) => {

    const { course } = useOutletContext();
    const [fetchUsers, { loading, data, refetch, fetchMore }] = useLazyQuery(FETCH_USERS, {
        notifyOnNetworkStatusChange: true,
        variables: {
            offset: 0,
            limit: 10,
            yearLevelId: course?.yearLevel?.id,
        },
    });

    const [searchString, setSearchString] = useState("");
    const [yearLevelId, setYearLevelId] = useState("");
    const [key, toggleKey] = useState(false);

    useEffect(() => {
        fetchUsers();
        setYearLevelId(course?.yearLevel?.id)
    }, [course?.yearLevel?.id]);

    const handleSubmit = (e) => {
        e.preventDefault();

        refetch({ searchString, yearLevelId });
    }

    const handleClear = (e) => {
        e.preventDefault();

        setSearchString("");
        setYearLevelId(course?.yearLevel?.id);
        toggleKey(key => !key);
        refetch({ searchString: "", yearLevelId});
    }

    return (
        <Fragment key={key}>
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                    <div className="form-group col-6">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search for student name, LRN..."
                            onChange={(e) => setSearchString(e.target.value)}
                            autoFocus />
                    </div>
                </div>
                {/* Will comment out this filter for now, we will restrict course enlistment on year level */}
                {/* <h4>Filters</h4>
                <div className="row">
                    <YearLevelFilter setYearLevelId={setYearLevelId} />
                </div> */}
                <button type="submit" className="btn btn-primary">Submit</button>
                <a
                    href=""
                    className="btn btn-outline-danger ms-3"
                    onClick={(e) => handleClear(e)}>
                    Clear
                </a>
            </form>
            {data && data.fetchUsers ? (
                <Fragment>
                    {!Boolean(data.fetchUsers.length) ? (
                        <SplashScreen
                            title="No student found"
                            subTitle={`There are no student found in ${course?.yearLevel?.name} year level`}
                            primaryButtonText={"Update course year level"}
                            primaryButtonUrl={`/teacher/my/courses/${course?.slug}/edit`} />
                    ) : null}
                    {Boolean(data.fetchUsers.length) ? (
                        <>
                            <Users users={data.fetchUsers} courseUsers={courseUsers} courseId={courseId} />
                            <Button type="primary" text="Load more"
                                    visible={data.fetchUsers.length >= 10}
                                    onClick={() => fetchMore({
                                        variables: {
                                            offset: data.fetchUsers.length,
                                            yearLevelId: course?.yearLevel?.id,
                                        },
                                    })}
                                    disabled={!!loading} />
                        </>
                    ) : null}
                </Fragment>
            ) : null}
        </Fragment>
    );
}

export default SearchUsers
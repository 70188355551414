import React, { Fragment, useEffect, useState } from "react";
import StatusTabs from "./StatusTabs";
import { ACTIVITY_TYPES } from "blocks";
import Button from "../../../elements/Button";

const ActivityTabs = ({ submissions }) => {

    const [activityType, setActivityType] = useState("assignment");
    const [selectedStatus, setSelectedStatus] = useState("all");
    const [filteredSubmissions, setFilteredSubmissions] = useState([]);

    useEffect(() => {
        filterSubmissions(activityType, selectedStatus);
    }, [activityType, selectedStatus, submissions]);

    function filterSubmissions(activity, status) {
        let submissionsToShow = submissions.filter(submission => (
            submission.courseContent.contentType === activity
        )).filter(submission => (
            status === "all" ? true : submission.status === status
        ));
        setFilteredSubmissions(submissionsToShow);
    }

    function getActivitySubmissionCount(type) {
        return submissions.filter(
            ({ courseContent }) => courseContent.contentType === type
        ).length
    }

    return (
        <Fragment>
            <ul className="nav nav-tabs mb-4">
                {ACTIVITY_TYPES.map((activity) => (
                    <li key={activity.type} className="nav-item">
                        <Button
                            text={`${activity.label} (${getActivitySubmissionCount(activity.type)})`}
                            onClick={() => setActivityType(activity.type)}
                            className={`nav-link ${activityType === activity.type ? "active" : ""}`}
                        />
                    </li>
                ))}
            </ul>
            <StatusTabs
                submissions={filteredSubmissions}
                setSelectedStatus={setSelectedStatus}
                selectedStatus={selectedStatus} />
        </Fragment>
    );
}

export default ActivityTabs
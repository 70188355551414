import React, { Fragment, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client';
import { UPDATE_COURSE_CONTENT } from 'graphql/mutations';
import { CONTENT_TYPES } from 'blocks';
import Editor from 'components/editor/Editor';
import SidebarToggler from './sidebar/SidebarToggler';
import formatDate from '../../utils/formatDate';
import DescriptionBlock from './DescriptionBlock';
import { UPDATE_LESSON } from '../../graphql/mutations';
import Button from '../elements/Button';

const CourseContent = ({
  section: { courseModule, lesson, courseContent },
  course,
  readOnly,
  setIsReadOnly }) => {
    const [contentType, setContentType] = useState(null);
    const [lessonDescriptionEdit, setLessonDescriptionEdit] = useState(false);

    useEffect(() => {
        setContentType(courseContent.contentType);
    }, [courseContent]);

    const [updateContent, { loading }] = useMutation(UPDATE_COURSE_CONTENT, {
        onCompleted: () => { alert('Page saved.') },
        onError: () => { alert('Failed to save page.') },
    });

    return (
        <Fragment>
            <div className="d-flex flex-row flex-md-column">
                <div className="d-flex flex-column">
                    <h2 className="mb-2">{courseContent.name}</h2>
                    <div className="d-flex flex-column flex-md-row">
                        <span>Lesson: <span className="text-primary">{lesson.name}</span></span>
                        <span className="mx-md-4">Last edited: {formatDate(courseContent.updatedAt)}</span>
                        <SidebarToggler />
                    </div>
                </div>
            </div>
            <DescriptionBlock
                title="About this lesson"
                editMode={lessonDescriptionEdit}
                defaultValue={lesson.description}
                recordId={lesson.id}
                setEditMode={setLessonDescriptionEdit}
                mutation={UPDATE_LESSON}
                readOnly={readOnly} />
            { !readOnly && (
                <div className="d-flex justify-content-center justify-content-md-start my-2 border-bottom">
                    { CONTENT_TYPES.map((_contentType, index) => (
                        <div key={index} className={contentType === _contentType.type ? "border-bottom border-primary border-2" : ""}>
                            <Button
                                text={_contentType.label}
                                type="secondary"
                                className={`btn btn-transparent ${contentType === _contentType.type ? "text-primary" : "text-dark"} h3 mb-0`}
                                onClick={() => setContentType(_contentType.type)}
                                disabled={loading} />
                        </div>
                    )) }
                </div>
            ) }
            <Editor
                courseContent={courseContent}
                updateContent={updateContent}
                contentType={contentType}
                loading={loading}
                readOnly={readOnly}
                setIsReadOnly={setIsReadOnly}
                course={course}
            />
        </Fragment>
    );
}

export default CourseContent
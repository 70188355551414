import React, { Fragment } from "react";
import { useOutletContext } from "react-router-dom";
import Sidebar from "./Sidebar";
import MainPanel from "./MainPanel";
import SidebarDrawer from "../content_builder/sidebar/SidebarDrawer";

const ContentViewer = () => {
    const { course } = useOutletContext();

    return (
        <div className="d-flex min-vh-100 w-100">
            {course ? (
                <Fragment>
                    <SidebarDrawer>
                        <Sidebar key="content-viewer-sidebar" course={course} onDrawer />
                    </SidebarDrawer>
                    <Sidebar key="content-viewer-sidebar" course={course} />
                    <MainPanel course={course} />
                </Fragment>
            ) : null}
        </div>
    );
}

export default ContentViewer;
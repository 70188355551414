import React, { useState, useRef, useEffect } from 'react'

const List = ({ block, dispatch, readOnly }) => {

  const { id, type, data } = block;

  const [items, setItems] = useState(data.items);
  const [activeIndex, setActiveIndex] = useState(data.items.length - 1);

  const ref = useRef([]);

  useEffect(() => {
    if (ref.current.length) {
      ref.current = ref.current.filter((obj) => obj !== null)
      ref.current.forEach((item, index) => item.innerHTML = items[index]);
    }

    const activeItem = ref.current[activeIndex];
    if (activeItem) { activeItem.focus() }
  }, [items.length]);

  const handleInput = ({ e, index }) => {
    const newItems = items.map((item, itemIndex) => (
      itemIndex === index ? e.currentTarget.textContent : item
    ));
    handleUpdate({ items: newItems });
  }

  const handleKeyDown = ({ e, index }) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      const newItems = [...items.slice(0, index + 1), '', ...items.slice(index + 1)];
      handleUpdate({ items: newItems });
      setActiveIndex(index + 1);
    }

    if (e.key === 'Backspace' && items[index] === '' && items.length !== 1) {
      const newItems = items.filter((_, itemIndex) => index !== itemIndex)
      handleUpdate({ items: newItems });
      setActiveIndex(index - 1);
    }

    if (e.key === 'Backspace' && items[index] === '' && items.length === 1) {
      dispatch({ type: 'delete', payload: { id } });
    }
  }

  const handleUpdate = ({ items }) => {
    setItems(items);

    const payload = {
      id,
      data: {
        items,
      },
    }
    dispatch({ type: 'update', payload });
  }

  const ListTag = type;

  return (
    <ListTag>
      { items.map((item, index) => {
        return (
          <li
            key={index}
            ref={(obj) => ref.current[index] = obj}
            contentEditable={!readOnly}
            className="p-2 course-content-editable"
            data-placeholder={!readOnly ? "List item" : ""}
            onInput={(e) => handleInput({ e, index })}
            onKeyDown={(e) => handleKeyDown({ e, index })} />
        )
      }) }
    </ListTag>
  );
}

export default List
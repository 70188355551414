import React, { Fragment, useEffect, useState } from "react";
import FileRenderer from "components/editor/blocks/FileRenderer";
import { CheckCircle } from "react-feather";

const QuestionRenderer = ({
    blockId,
    data: { question, points },
    answer,
    status,
    dispatch,
    published,
    readOnly,
}) => {
    const { questionType, questionText, choices, attachment } = question;
    const [correctAnswer, setCorrectAnswer] = useState(null);

    // Automatic checking of answer
    useEffect(() => {
        if (questionType === "multiple_choice") {
            const correctAnswer = choices.find(choice => choice.isCorrect);
            setCorrectAnswer(correctAnswer);

            // If question is not yet checked, correct answer is present, and answer is present
            if (!status.isChecked && correctAnswer && answer && answer.content) {
                const isCorrect = correctAnswer.id === answer.content.choiceId;
                const payload = {
                    blockId,
                    status: {
                        isChecked: true,
                        isCorrect,
                        points: isCorrect ? parseFloat(points) || 0 : 0,
                    }
                }
                dispatch({ type: "update", payload });
            }
        }
    }, []);

    const containerClasses = [
        "p-3 border border-2 rounded",
        status.isChecked ? !readOnly ? status.isCorrect ? "border-success" : "border-danger"
                                     : published ? status.isCorrect ? "border-success" : "border-danger"
                                                 : "border"
                         : "border-0",
    ].join(" ");

    const statusIcon = status.isCorrect ? (
        "fe-check-circle text-success"
    ) : "fe-x text-danger";

    return (
        <div className={containerClasses}>
            <div className="d-flex align-items-start mb-3">
                <p className="pe-3 m-0">{questionText}</p>
                <span className="py-1 px-2 m-0 bg-light rounded h4">
                    {`${points} ${points == 1 ? " pt" : " pts"}`}
                </span>
                {(!readOnly) ? (
                    <>
                        {status.isChecked && <span className={`ms-auto fe ${statusIcon}`} />}
                    </>
                ) : (
                    <>
                        {published && <span className={`ms-auto fe ${statusIcon}`} /> }
                    </>
                )}
            </div>
            {!!attachment ? (
                <div className="mb-3">
                    {attachment.type.includes("image") ? (
                        <img src={attachment.url} className="mw-100" />
                    ) : (
                        <FileRenderer fileName={attachment.name} fileUrl={attachment.url} />
                    )}
                </div>
            ) : null}
            {questionType === "text" ? (
                <p className="p-3 border mb-0">
                    {answer && answer.content && String(answer.content.text || "").trim() || (
                        <span className="text-danger">No answer.</span>
                    )}
                </p>
            ) : null}
            {questionType === "multiple_choice" ? (
                <Fragment>
                    <ol className="list-group mb-3 ms-4" type="a">
                        {choices.map((choice, index) => (
                            <li
                                key={index}
                                className={answer && answer.content && answer.content.choiceId === choice.id ? (
                                    "fw-bold text-primary"
                                ) : ""}
                            >
                                {choice.choiceText}
                                {(correctAnswer && correctAnswer.choiceText === choice.choiceText) ? (
                                    <>
                                        {!readOnly ? (
                                            <CheckCircle className="text-success mx-2" size={16} />
                                        ) : (
                                            <>
                                                {published && <CheckCircle className="text-success mx-2" size={16} />}
                                            </>
                                        )}
                                    </>
                                ) : null}
                            </li>
                        ))}
                    </ol>
                </Fragment>
            ) : null}
            {questionType === "file_attachment" ? (
                <Fragment>
                    {answer && answer.content && answer.content.file ? (
                        <FileRenderer fileName={answer.content.file.name}
                                      fileUrl={answer.content.file.url}
                                      fileType={answer.content.file.type} />
                    ) : <p className="text-danger mb-0">No file attached.</p>}
                </Fragment>
            ) : null}
        </div>
    );
}

export default QuestionRenderer
import React, { Fragment, useRef, useState } from 'react'
import AddBlock from './AddBlock';
import BlockMenu from './BlockMenu';
import Text from './blocks/Text';
import List from './blocks/List';
import File from './blocks/File';
import Math from './blocks/Math';
import Question from './blocks/Question';
import Embed from './blocks/questions/Embed';
import Button from '../elements/Button';
import Divider from './blocks/Divider';

const Block = ({
    block,
    block: { type },
    dispatch,
    readOnly,
    contentType,
    index,
    setShowSaveButton,
    handleSave,
}) => {

    const [isDropTarget, setDropTarget] = useState(false);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
        setDropTarget(true);
    }

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDropTarget(false);
    }

    const handleDrop = (e) => {
        e.preventDefault();
        setDropTarget(false);

        const id = e.dataTransfer.getData('block-id');
        if (id) {
            dispatch({ type: 'insert', payload: { id, index } })
        }
    }

    const containerClasses = [
      "w-100 d-flex align-items-start flex-row-reverse py-2",
      isDropTarget ? 'bg-info text-white' : "",
      !readOnly ? "course-content-block" : "",
    ].join(" ");

    const ref = useRef(null);

    const handleDrag = (e) => {
      e.dataTransfer.setData('block-id', block.id);
      e.dataTransfer.setDragImage(e.target.nextElementSibling, 0, 0);
    }

    return (
        <div
            onDragOver={(e) => handleDragOver(e)}
            onDragLeave={(e) => handleDragLeave(e)}
            onDrop={(e) => handleDrop(e)}
            className="d-flex align-items-center flex-row-reverse">
                {!readOnly ? (
                    <div ref={ref} draggable="true" onDragStart={(e) => handleDrag(e)}>
                        <Button
                            text=""
                            className="btn btn-transparent d-flex">
                                <i className="fe fe-more-vertical" style={{ width: "7px" }}/>
                                <i className="fe fe-more-vertical" style={{ width: "7px" }} />
                        </Button>
                    </div>
                ) : null}
                <div className={containerClasses}>
                    { !readOnly && (
                        <div className="me-3 d-flex align-items-center">
                            <BlockMenu block={block} dispatch={dispatch} />
                            <AddBlock block={block} dispatch={dispatch} contentType={contentType} />
                        </div>
                    ) }
                    <div className="w-100">
                        { ["p", "h1", "h2", "h3"].includes(type) && (
                            <Text block={block} dispatch={dispatch} readOnly={readOnly} />
                        ) }
                        { ["ul", "ol"].includes(type) && (
                            <List block={block} dispatch={dispatch} readOnly={readOnly} />
                        ) }
                        { ["img", "file"].includes(type) && (
                            <File block={block} dispatch={dispatch} readOnly={readOnly} />
                        ) }
                        { type === "tex" && (
                            <Math block={block} dispatch={dispatch} readOnly={readOnly} />
                        ) }
                        { type === "question" && (
                            <Question
                                block={block}
                                dispatch={dispatch}
                                readOnly={readOnly}
                                setShowSaveButton={setShowSaveButton}
                                handleSave={handleSave} />
                        ) }
                        { type === "embed" && (
                            <Embed block={block} dispatch={dispatch} readOnly={readOnly} />
                        ) }
                        { type === "divider" && (
                            <Divider />
                        ) }
                    </div>
                </div>
        </div>
    );
}

export default Block
import React, { useRef, useEffect, Fragment } from 'react'
import { defaultBlock } from 'blocks';

const Math = ({ block, dispatch, readOnly }) => {

  const { id, type, data } = block;

  const previewRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (previewRef.current) {
      katex.render(data.equation, previewRef.current, {
        throwOnError: false
      });
    }
  }, [data]);

  const handleChange = (e) => {
    const payload = {
      id,
      data: {
        equation: e.target.value,
      },
    }

    dispatch({ type: 'update', payload });
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      dispatch({ type: 'add', payload: defaultBlock(), id });
    }

    if (e.key === 'Backspace' && data.equation === '') {
      dispatch({ type: 'delete', payload: { id } });
    }
  }

  return (
    <Fragment>
      { data.equation ? (
        <div className="tex-equation">
          <span
            ref={previewRef}
            className="text-center" />
        </div>
      ) : null }
      { !readOnly ? (
        <input
          ref={inputRef}
          className="form-control"
          placeholder="Enter TeX equation"
          defaultValue={data.equation}
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) => handleKeyDown(e)}
          autoFocus />
      ) : null }
    </Fragment>
  );
}

export default Math
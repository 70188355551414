import React, { useState, useReducer, useEffect } from 'react'
import { useMutation } from '@apollo/client';
import { CREATE_QUESTION } from 'graphql/mutations';
import { QUESTION_CATEGORIES, defaultChoice } from 'blocks';
import Choices from './Choices';

const QuestionForm = ({ questionType, setQuestion }) => {

    const [createQuestion, { error }] = useMutation(CREATE_QUESTION, {
        onCompleted: (data) => setQuestion(data.createQuestion.question),
    });

    const [fileData, setFileData] = useState('');
    const [fileName, setFileName] = useState('');
    const [fileType, setFileType] = useState('');

    const [choices, dispatch] = useReducer(reducer, []);

    function reducer(state, action) {
        switch (action.type) {
            case 'add':
                return [...state, defaultChoice()];
            case 'update':
                const { id, choiceText, isCorrect } = action.payload;
                return state.map((choice) => (
                    choice.id === id ? { ...choice, choiceText, isCorrect  } : choice
                ));
            case 'delete':
                return state.filter((choice) => choice.id !== action.payload.id);
            case 'initialize':
                return action.payload;
            default:
                return state;
        }
    }

    useEffect(() => {
        if (questionType === 'multiple_choice') {
            let payload = [];
            for (let i = 0; i < 4; i++) {
                payload.push(defaultChoice());
            }

            dispatch({ type: 'initialize', payload });
        }
    }, []);

    const handleAttach = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            setFileData(reader.result);
            setFileName(file.name);
            setFileType(file.type);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const variables = {
            questionType,
            questionText: e.target.questionText.value,
            category: e.target.category.value,
            choices,
            fileData,
            fileName,
            fileType
        }
        createQuestion({ variables });
    }

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className="form-group d-none">
                <label className="form-label" htmlFor="category">Category</label>
                <select
                    className="form-select"
                    id="category"
                    name="category"
                >
                    <option value="" />
                    { QUESTION_CATEGORIES.map((category, index) => (
                        <option key={index} value={category}>{category}</option>
                    )) }
                </select>
            </div>
            <div className="form-group">
                <textarea
                    className="form-control"
                    placeholder="Type your question here"
                    name="questionText"
                />
            </div>
            <div className="form-group">
                <label className="d-inline-flex me-auto">
                    <span className="me-5">Attachment</span>
                    <input type="file" name="file" onChange={(e) => handleAttach(e)} />
                </label>
            </div>
            { questionType === 'multiple_choice' && (
                <Choices choices={choices} dispatch={dispatch} />
            ) }
            { error?.graphQLErrors?.map(({ message }, index) => (
                <p key={index} className="text-danger small">{message}</p>
            )) }
            <input type="submit" value="Save Question" className="btn btn-primary" />
        </form>
    );
}

export default QuestionForm
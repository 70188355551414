import React, { useState } from 'react';

const LessonDrag = ({ lesson, index, handleSort, showSections }) => {

  const [isDropTarget, setDropTarget] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    setDropTarget(true);
  }

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDropTarget(false);
  }

  const handleDrop = (e) => {
    e.preventDefault();
    setDropTarget(false);

    const dataId = e.dataTransfer.getData('lesson-id');
    if (dataId) {
      handleSort({ dataId, index });
    }
  }

  const handleDrag = (e) => {
    e.dataTransfer.setData('lesson-id', lesson.id);
  }

  return (
    <div
      className={`py-2 d-flex ${isDropTarget ? 'bg-info text-white' : ''}`}
      draggable="true"
      onDragOver={(e) => handleDragOver(e)}
      onDragLeave={(e) => handleDragLeave(e)}
      onDrop={(e) => handleDrop(e)}
      onDragStart={(e) => handleDrag(e)}
    >
      <div className="px-1 me-3 h-50 bg-tropical-blue">
        <span className="fe fe-file" />
      </div>
      <p className="me-auto mb-0" title={lesson.name}>{lesson.name}</p>
      <span className={`fe fe-chevron-${!!showSections ? 'up' : 'down'}`} />
    </div>
  );
}

export default LessonDrag
import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_COURSE_USERS } from 'graphql/queries';
import { useFetchSetting } from 'hooks/useFetchSetting';
import Breadcrumbs from './partials/Breadcrumbs';
import CourseUsers from './partials/CourseUsers';
import SearchUsers from './SearchUsers';
import SidebarToggler from '../content_builder/sidebar/SidebarToggler';

const CourseEnlistment = ({ courseId }) => {

    const { data } = useQuery(FETCH_COURSE_USERS, { variables: { courseId } });
    const { data: permData } = useFetchSetting({ name: "allow_teacher_enlistment" });

    return (
        <div className="col-12 col-md-9 px-5 bg-white min-vh-100">
            {data && data.fetchCourseUsers ? (
                <Fragment>
                    <Breadcrumbs crumbs={[{ name: 'Students' }]} />
                    <h2>Students</h2>
                    <SidebarToggler text="Navigate Course" />
                    <ul className="nav nav-tabs" id="enlistTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="enlisted-tab" data-bs-toggle="tab" data-bs-target="#enlisted" type="button" role="tab" aria-controls="enlisted" aria-selected="true">Enlisted Students</button>
                        </li>
                        {permData?.fetchSetting.allow_teacher_enlistment && (
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="add-tab" data-bs-toggle="tab" data-bs-target="#add" type="button" role="tab" aria-controls="add" aria-selected="false">Add Students</button>
                            </li>)}
                    </ul>
                    <div className="tab-content mt-4" id="enlistTabContent">
                        <div className="tab-pane fade show active" id="enlisted" role="tabpanel" aria-labelledby="enlisted-tab">
                            <CourseUsers courseUsers={data.fetchCourseUsers} courseId={courseId} allowEnlistment={permData?.fetchSetting.allow_teacher_enlistment} />
                        </div>
                        {permData?.fetchSetting.allow_teacher_enlistment && (
                            <div className="tab-pane fade" id="add" role="tabpanel" aria-labelledby="add-tab">
                                <SearchUsers courseUsers={data.fetchCourseUsers} courseId={courseId} />
                            </div>)}
                    </div>
                </Fragment>
            ) : null}
        </div>
    );
}

export default CourseEnlistment
import React from "react";
import CourseModules from "./sidebar/CourseModules";
import { Link } from "react-router-dom";

const Sidebar = ({ course, onDrawer = false }) => {
    const containerClasses = [
        "p-4 bg-light w-full",
        !onDrawer ? "sidebar col-3 d-none d-md-block h-100" : "min-vh-100",
    ].join(" ");

    return (
        <div className={containerClasses}>
            <Link onClick={() => document.body.style = {}} to={`/student/my/classes/${course.slug}`} className="d-flex mb-4">
                <span className="fe fe-chevron-left me-2" />
                Back to class home page
            </Link>
            <div className="mb-4">
                <h2 className="mb-2">{course.name}</h2>
                {course.teacher ? (
                    <p>by <span className="text-primary">{course.teacher.name}</span></p>
                ) : null}
            </div>
            <CourseModules courseModules={course.courseModules} />
        </div>
    );
}

export default Sidebar
import React from 'react';
import Button from "../elements/Button";
import { Trash } from "react-feather";

const BlockMenu = ({ block, dispatch }) => {

  const handleDelete = () => {
    if (confirm("You are about to remove a block. Continue?")) {
      dispatch({ type: 'delete', payload: { id: block.id } })
    }
  }

  return (
      <Button
        text=""
        className="btn btn-transparent px-1"
        onClick={() => handleDelete()}>
          <Trash />
      </Button>
  );
}

export default BlockMenu
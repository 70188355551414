import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import CourseHeader from "components/shared/CourseHeader";

const CourseNav = ({ course, visible, onDrawer }) => {
    if (!visible) return null;

    const containerClasses = [
        "p-5 syllabus-sidebar",
        !onDrawer ? "sidebar h-auto col-3 d-none d-md-block" : "h-100",
    ].join(" ");


    function toggleSidebarDrawer() {
        const backDrop = document.querySelectorAll(".offcanvas-backdrop");
        if (!Boolean(backDrop.length)) return;

        backDrop.forEach((element) => {
            element.dispatchEvent(new MouseEvent("mousedown"));
        });
        document.body.style = {};
    }

    return (
        <div className={containerClasses}>
            {course ? (
                <Fragment>
                    <CourseHeader course={course} />
                    <ul className="nav flex-column mt-7">
                        <li className="nav-item">
                            <NavLink
                                onClick={toggleSidebarDrawer}
                                end={true}
                                to={`/student/my/classes/${course.slug}`}
                                className="nav-link text-dark ps-4">
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                onClick={toggleSidebarDrawer}
                                to={`/student/my/classes/${course.slug}/content`}
                                className="nav-link text-dark ps-4">
                                View Course Content
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                onClick={toggleSidebarDrawer}
                                to={`/student/my/classes/${course.slug}/submissions`}
                                className="nav-link text-dark ps-4">
                                My Submissions
                            </NavLink>
                        </li>
                    </ul>
                </Fragment>
            ) : null}
        </div>
    );
}

CourseNav.defaultProps = {
    course: null,
    visible: true,
    onDrawer: false,
}

export default CourseNav;
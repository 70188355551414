import { gql } from '@apollo/client';
import { COURSE_FIELDS, SUBMISSION_FIELDS, USER_FIELDS, QUESTION_FIELDS, COURSE_CONTENT_FIELDS, LESSON_FIELDS, COURSE_MODULE_FIELDS, PAGY_FIELDS } from './fragments'

export const FETCH_COURSES = gql`
  query($searchString: String, $offset: Int, $limit: Int) {
    fetchCourses(params: {
      searchString: $searchString,
      offset: $offset,
      limit: $limit,
    }) {
      id
      name
      slug
      teacher {
        name
      }
      isEnlisted
    }
  }
`

export const FETCH_COURSE = gql`
  ${COURSE_FIELDS}
  query($slug: String!) {
    fetchCourse(slug: $slug) {
      ...CourseFields
    }
  }
`

export const FETCH_COURSE_USERS = gql`
  ${USER_FIELDS}
  query($userId: ID, $courseId: ID) {
    fetchCourseUsers(params: {
      userId: $userId,
      courseId: $courseId,
    }) {
      id
      role
      course {
        id
        name
        slug
        teacher {
          name
        }
      }
      user {
        ...UserFields
      }
    }
  }
`

export const FETCH_SUBMISSIONS = gql`
  ${SUBMISSION_FIELDS}
  query($slug: String!, $userId: ID) {
    fetchSubmissions(params: {
      slug: $slug,
      userId: $userId,
    }) {
      ...SubmissionFields
    }
  }
`

export const FETCH_COURSE_SUBMISSIONS = gql`
  ${SUBMISSION_FIELDS}
  ${USER_FIELDS}
  query($slug: String!, $userId: ID, $courseContentId: ID) {
    fetchCourseSubmissions(params: {
      slug: $slug,
      userId: $userId,
      courseContentId: $courseContentId,
    }) {
      ...SubmissionFields
      user {
        ...UserFields
      }
    }
  }
`

export const FETCH_SETTING = gql`
  query($name: String!){
    fetchSetting(name: $name)
  }
`

export const FETCH_SUBMISSION = gql`
  ${SUBMISSION_FIELDS}
  query($slug: String!, $id: ID!) {
    fetchSubmission(
      slug: $slug,
      id: $id
      ) {
      ...SubmissionFields
    }
  }
`

export const FETCH_COURSE_CONTENT = gql`
  ${COURSE_MODULE_FIELDS}
  ${LESSON_FIELDS}
  ${COURSE_CONTENT_FIELDS}
  ${SUBMISSION_FIELDS}
  query($slug: String!, $courseId: ID!) {
    fetchCourseContent(slug: $slug, courseId: $courseId) {
      ...CourseContentFields
      courseContentProgress
      userSubmission {
        ...SubmissionFields
      }
      lastUpdateByUser {
        name
      }
      userPrerequisiteStatus {
        name
        status
        slug
        contentType
      }
      lesson {
        ...LessonFields
      }
      courseModule {
        ...CourseModuleFields
      }
    }
  }
`;

export const FIND_SUBMISSION = gql`
  ${SUBMISSION_FIELDS}
  query($courseContentId: ID!, $userId: ID!) {
    findSubmission(
      courseContentId: $courseContentId,
      userId: $userId
    ) {
      ...SubmissionFields
    }
  }
`

export const FETCH_USERS = gql`
  ${USER_FIELDS}
  query($searchString: String, $yearLevelId: ID, $offset: Int, $limit: Int) {
    fetchUsers(params: {
      searchString: $searchString,
      yearLevelId: $yearLevelId,
      offset: $offset,
      limit: $limit,
    }) {
      ...UserFields
    }
  }
`

export const FETCH_QUESTIONS = gql`
  ${QUESTION_FIELDS}
  query($searchString: String, $offset: Int, $limit: Int) {
    fetchQuestions(params: {
      searchString: $searchString,
      offset: $offset,
      limit: $limit,
    }) {
      ...QuestionFields
    }
  }
`

export const FETCH_QUESTION = gql`
  query($id: ID!) {
    fetchQuestion(id: $id) {
      id
      questionType
      questionText
      choices {
        id
        choiceText
      }
      attachment
    }
  }
`

export const FETCH_QUESTION_DATA = gql`
  query($id: ID!) {
    fetchQuestion(id: $id) {
      id
      questionType
      questionText
      category
      choices {
        id
        choiceText
        isCorrect
      }
      attachment
    }
  }
`

export const FETCH_YEAR_LEVELS = gql`
  query {
    fetchYearLevels {
      id
      name
    }
  }
`

export const FETCH_COURSE_STUDENTS_PROGRESS = gql`
  ${PAGY_FIELDS}
  query fetchCourseStudentsProgress($slug: String!, $filters: StudentNameFilter) {
    fetchCourseStudentsProgress(slug: $slug, filters: $filters) {
      studentsProgress {
        student {
          firstName
          lastName
          id
        }
        progress {
          id
          status
        }
      }
      pagy {
        ...pagyFields
      }
    }
  }
`
import React from 'react'
import CourseContent from './CourseContent';

const CourseContents = ({ courseContents }) => {

  return (
    <ul className="list-group ps-5">
      { [...courseContents]
        .sort((a, b) => a.ordinality - b.ordinality)
        .map((courseContent) => (
          <CourseContent
            key={courseContent.id}
            courseContent={courseContent} />
        )) }
    </ul>
  );
}

export default CourseContents
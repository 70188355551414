import React, { useState } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from '@apollo/client';
import { COURSE_CONTENT_FIELDS } from 'graphql/fragments';
import { CREATE_COURSE_CONTENT } from 'graphql/mutations';
import CourseContentForm from './CourseContentForm';

const NewCourseContent = ({ lesson }) => {

  let params = useParams();
  let navigate = useNavigate();

  const [isVisible, toggleVisibility] = useState(false);
  const [contentName, setContentName] = useState("");

  const [createContent, { loading, error }] = useMutation(CREATE_COURSE_CONTENT, {
    onCompleted: (data) => { handleClose(), handleNavigate(data) },
    update(cache, { data: { createCourseContent: { courseContent } } }) {
      cache.modify({
        id: cache.identify(lesson),
        fields: {
          courseContents(currentCourseContents) {
            const newCourseContent = cache.writeFragment({
              data: courseContent,
              fragment: COURSE_CONTENT_FIELDS
            });

            return [...currentCourseContents, newCourseContent];
          },
        },
      });
    }
  });

  const mutateContent = (e) => {
    e.preventDefault();
    createContent({ variables: { name: contentName, lessonId: lesson.id } });
  }

  const handleClose = () => {
    setContentName("");
    toggleVisibility(false);
  }

  const handleNavigate = (data) => {
    const { createCourseContent: { courseContent: { slug } } } = data;
    navigate(`/teacher/my/courses/${params.slug}/content/${slug}`, { replace: true });
  }

  return (
    <div className="py-2">
      { !!isVisible ? (
        <CourseContentForm
          mutateContent={mutateContent}
          contentName={contentName}
          setContentName={setContentName}
          handleClose={handleClose}
          loading={loading}
          error={error} />
      ) : (
        <a className="cursor-pointer" onClick={() => toggleVisibility(true)}>
          + Add Section
        </a>
      ) }
    </div>
  );
}

export default NewCourseContent
import React, { useState, Fragment, useEffect } from 'react'
import { QUESTION_TYPES } from 'blocks';
import QuestionBank from './QuestionBank';
import QuestionForm from './QuestionForm';

const QuestionBuilder = ({ setQuestion, setShowSaveButton }) => {

  const [questionType, setQuestionType] = useState('');
  const [showQuestionBank, toggleQuestionBank] = useState(false);

  useEffect(() => {
    setShowSaveButton(!questionType);
  }, [questionType])

  return (
    <Fragment>
      { !questionType && !showQuestionBank ? (
        <Fragment>
          <p>Select question type</p>
          { QUESTION_TYPES.map(({ type, label, description }) => (
            <a
              key={type}
              className="btn btn-primary col-3 me-3 mb-3"
              title={description}
              onClick={() => setQuestionType(type)}
              >{label}
            </a>
          )) }
          <p>or</p>
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => toggleQuestionBank(true)}
            >Select from Question Bank
          </button>
        </Fragment>
      ) : null }
      { !!questionType ? (
        <QuestionForm questionType={questionType} setQuestion={setQuestion} />
      ) : null }
      { showQuestionBank ? (
        <QuestionBank setQuestion={setQuestion} />
      ) : null }
    </Fragment>
  );
}

export default QuestionBuilder
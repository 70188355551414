import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import StudentPortal from "./StudentPortal";
import MyCourses from "./MyCourses";
// import SearchCourses from "./SearchCourses";
import Course from "./course_view/Course";
import HomePage from "./course_view/HomePage";
import MySubmissions from "./course_view/MySubmissions";
import ContentViewer from "components/content_viewer/ContentViewer";
import { UserContext } from "context";
import Submission from "../course_view/Submission";
import EditPassword from './EditPassword';

const Root = ({ userId }) => {

    return (
        <UserContext.Provider value={userId}>
            <BrowserRouter>
                <Routes>
                    <Route path="/student" element={<StudentPortal />}>
                        <Route path="profile/edit_password" element={<EditPassword />} />
                        <Route path="my/classes" element={<MyCourses userId={userId} />} />
                        <Route element={<Course />}>
                            <Route path="my/classes/:slug" element={<HomePage />} />
                            <Route path="my/classes/:slug/submissions" element={<MySubmissions userId={userId} />} />
                            <Route path="my/classes/:slug/submissions/:submissionId" element={<Submission readOnly />} />
                            <Route path="my/classes/:slug/content" element={<ContentViewer />} />
                            <Route path="my/classes/:slug/content/:slug2" element={<ContentViewer />} />
                        </Route>
                        {/* <Route path="classes" element={<SearchCourses userId={userId} />} /> */}
                        <Route path="*" element={<p>404</p>} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </UserContext.Provider>
    );
}

export default Root;
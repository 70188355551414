import React, { useEffect } from "react";

const EditPassword = () => {
    const CSRF = document.querySelector('meta[name="csrf-token"]')
                         .getAttribute("content");

    useEffect(() => {
        const passwordInputs = document.querySelectorAll("input[type='password'");

        // disable form submit on Enter
        // moody behaviour
        if (Boolean(passwordInputs.length)) {
            passwordInputs.forEach(input => {
                input.addEventListener("keypress", function(e) {
                    if (e.keyCode == 13) e.preventDefault();
                })
            })
        }
    }, [])

    return (
        <div className="container-fluid py-3 bg-white min-vh-100">
            <div className="d-flex flex-column col-12 col-lg-6 offset-lg-3 p-md-4 p-lg-0 mt-lg-6 justify-content-center">
                <h2 className="mt-4 text-center">Edit Password</h2>
                <form action="/profile/update" method="POST" acceptCharset="UTF-8">
                    <input type="hidden" name="_method" value="put" />
                    <input type="hidden" name="is_student" value="true" />
                    <input type="hidden" name="authenticity_token" value={CSRF} />
                    <div className="form-group">
                        <label htmlFor="user[password]" className="form-label">Password</label>
                        <input type="password" name="user[password]" className="form-control" required/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="user[password_confirmation]" className="form-label">Password Confirmation</label>
                        <input type="password" name="user[password_confirmation]" className="form-control" required/>
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                        <button onClick={() => window.history.back()} className="btn btn-outline-secondary bg-white text-dark mx-3">Cancel</button>
                        <button type="submit" className="btn btn-primary">Save</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditPassword;
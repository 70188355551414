import React from 'react'
import Button from '../elements/Button';
import { useMutation } from '@apollo/client';

const DescriptionBlock = ({
    title,
    editMode,
    setEditMode,
    defaultValue,
    recordId,
    mutation,
    buttonText,
    readOnly,
    buttonOnClick,
    buttonClass,
}) => {
    function handleButtonOnClick() {
        if (buttonOnClick) {
            buttonOnClick()
            return;
        }
        setEditMode(true);
    }
    return (
        <div className="py-4">
            <div className={`d-flex justify-content-${editMode ? "start" : "between"} align-items-center mb-3`}>
                <h3 className="mb-0" style={{ padding: "0.65rem 0" }}>{title}</h3>
                <Button
                    text={buttonText}
                    className={buttonClass || "btn btn-transparent text-primary h3 mb-0"}
                    onClick={() => handleButtonOnClick()}
                    visible={!editMode && !readOnly} />
            </div>
            {editMode ? (
                <DescriptionForm
                    defaultValue={defaultValue}
                    mutation={mutation}
                    setEditMode={setEditMode}
                    recordId={recordId} />
            ) : null}
            {!editMode ? (
                <div className="course-content-block py-3 my-0">
                    {defaultValue || "No description added yet."}
                </div>
            ) : null}
        </div>
    );
}

const DescriptionForm = ({
    defaultValue,
    mutation,
    setEditMode,
    recordId,
}) => {
    const [updateMutation] = useMutation(mutation, {
        onCompleted: data => setEditMode(false),
    });
    function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        updateMutation({
            variables: { id: recordId, description: formData.get("description") }});
    }

    return (
        <form className="row py-2" onSubmit={handleSubmit}>
            <div className="col-12 col-md-10">
                <input
                    name="description"
                    className="form-control"
                    type="text"
                    defaultValue={defaultValue}
                    autoComplete="off"
                    autoFocus/>
            </div>
            <div className="col-3 offset-7 offset-md-0 col-md-2 d-flex mt-2 mt-md-0">
                <Button type="secondary" text="Cancel" onClick={() => setEditMode(false)} />
                <Button type="primary" text="Save" />
            </div>
        </form>
    );
}

DescriptionForm.defaultProps = {
    defaultValue: "Description value",
    mutation: null,
    setEditMode: () => {},
    recordId: null,
}

DescriptionBlock.defaultProps = {
    title: "Description block",
    recordId: null,
    editMode: false,
    setEditMode: () => {},
    defaultValue: "Description value",
    mutation: null,
    buttonText: "Edit description",
    readOnly: false,
    buttonOnClick: null,
    buttonClass: null,
};

export default DescriptionBlock;
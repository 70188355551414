import { useMutation } from '@apollo/client';
import { READ_COURSE_CONTENT, DONE_COURSE_CONTENT } from '../graphql/mutations';

const useCourseContentProgress = (callback = null) => {

    function onError(e) {
        console.log(e);
    }

    const [readContent] = useMutation(READ_COURSE_CONTENT, {
        onCompleted: data => { if (callback) callback(data) },
        onError: onError,
    });


    const [doneContent, { loading: doneContentLoading }] = useMutation(DONE_COURSE_CONTENT, {
        onCompleted: data => { if (callback) callback(data) },
        onError: onError,
    });

    function confirmReadContent(courseContent, callback = null) {
        let prerequisite = courseContent.userPrerequisiteStatus;
        if (prerequisite.status !== "done" && prerequisite.slug) return; // not done on prev content
        if (["done", "read"].includes(courseContent.courseContentProgress)) return;

        readContent({ variables: { slug: courseContent.slug } })
            .then(res => { if (callback) callback() })
    }

    function confirmDoneContent(courseContent, callback = null) {
        let prerequisite = courseContent.userPrerequisiteStatus;
        if (courseContent.contentType !== "lecture") return;
        if (courseContent.courseContentProgress === "done") return;
        if (prerequisite.status !== "done" && prerequisite.slug) return; // not done on prev content

        let message = "Do you want to mark ";
        message += courseContent.name;
        message += " as done?";

        if (confirm(message)) {
            doneContent({ variables: { slug: courseContent.slug } })
                .then(res => { if (callback) callback() })
        }
    }

    return {
        readContent,
        doneContent,
        confirmDoneContent,
        confirmReadContent,
        doneContentLoading,
    };
}

export default useCourseContentProgress;
import React from 'react'
import { useLocation } from 'react-router-dom';

const Breadcrumbs = ({
  section: { courseModule, lesson, courseContent },
  readOnly,
  setIsReadOnly }) => {

    const location = useLocation();

    const onCourseBuilder = location.pathname.includes("/my/");

  return (
    <>
      {(readOnly && onCourseBuilder) && (
        <div className="alert alert-secondary d-flex justify-content-between align-items-center" role="alert">
          <span>
            You are in <strong>Preview Mode</strong>. This is what the students will see when they view this course.
          </span>
          <button
            className="btn btn-sm btn-transparent text-light"
            onClick={() => setIsReadOnly(!readOnly)}>Close preview</button>
        </div>
      )}
      <nav className="syllabus-builder-breadcrumbs" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active py-2 text-truncate">{courseModule.name}</li>
          <li className="breadcrumb-item active py-2 text-truncate">{lesson.name}</li>
          <li className="breadcrumb-item text-primary py-2 current text-truncate">{courseContent.name}</li>
        </ol>
      </nav>
    </>
  );
}

export default Breadcrumbs
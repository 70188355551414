import React, { Fragment } from 'react'
import Lessons from './Lessons';

const CourseModule = ({ courseModule }) => {

  return (
    <Fragment>
      <div className="py-2 mb-2 d-flex flex-column">
        <span><strong>{courseModule.name}</strong></span>
        <span className="mb-0 text-muted">{courseModule.lessons.length} Lessons</span>
      </div>
      <Lessons lessons={courseModule.lessons} />
    </Fragment>
  );
}

export default CourseModule
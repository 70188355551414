import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import QuestionEditor from 'components/question_bank/QuestionEditor';

document.addEventListener('turbolinks:load', () => {
  const root = document.querySelector("#question-editor");
  const token = document.querySelector('meta[name="csrf-token"]').content;

  if (!!root) {
    const client = new ApolloClient({
      uri: '/graphql',
      cache: new InMemoryCache(),
      headers: {
        'X-CSRF-Token': token
      }
    });
    const questionId = root.getAttribute("data-id");

    ReactDOM.render(
      <ApolloProvider client={client} >
        <QuestionEditor questionId={questionId} />
      </ApolloProvider>,
      root,
    )
  }
})

import React from "react";

const Breadcrumbs = ({ crumbs }) => {
    if (!Boolean(crumbs.length)) return null;
    const crumbClass = "breadcrumb-item text-truncate";

    return (
        <nav className="syllabus-builder-breadcrumbs" aria-label="breadcrumb">
            <ol className="breadcrumb">
                {crumbs.map((crumb, idx) => (
                    <li key={`crumb-${idx}`} className={crumbClass}>
                        {crumb?.url ? (
                            <a href={crumb.url}>{crumb.text}</a>
                            ) : crumb.text}
                    </li>
                ))}
            </ol>
        </nav>
    );
}

export default Breadcrumbs;
import React from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import PageButton from "./PageButton";

const Pagination = ({
    placement = "center",
    pageInfo,
    handlePageChange,
}) => {
    const { page, pages } = pageInfo;
    const isFirst = page === 1;
    const isLast = page === pages;
    const beforeCurrentPage = (page - 1) > 0
                                ? [2,1].map(i => page - i).filter(i => i > 1 && i !== pages)
                                : [];
    const afterCurrentPage = (pages - page) > 0
                                ? [1,2].map(i => page + i).filter(i => i < pages && i !== 1)
                                : [];
    const elipsisBefore = (page - 1) >= 5
                            ? "..."
                            : (page - 4) === 1 ? 2 : null;
    const elipsisAfter = (pages - page) >= 5
                            ? "..."
                            : (pages - (page + 3)) === 1 ? pages -1 : null;
    return (
        <>
            {pageInfo ? (
                <div
                    className={`d-flex align-items-center justify-content-${placement} w-100`}>
                    {!isFirst && (
                        <PageButton
                            onClick={() =>
                                handlePageChange(Math.max(page - 1))
                            }>
                            <ChevronLeft size={16} />
                        </PageButton>
                    )}
                    {!isFirst && (
                        <PageButton
                            onClick={() => handlePageChange(1)}>
                            1
                        </PageButton>
                    )}
                    {elipsisBefore ? (
                        <PageButton
                            {...(typeof elipsisBefore == "number" && { onClick: () => handlePageChange(elipsisBefore) })}>
                            {elipsisBefore}
                        </PageButton>
                    ) : null}
                    {beforeCurrentPage.map(page => (
                        <PageButton
                            key={`page-${page}`}
                            onClick={() => handlePageChange(page)}>
                            {page}
                        </PageButton>
                    ))}
                    <PageButton currentPage>
                        {page}
                    </PageButton>
                    {afterCurrentPage.map(page => (
                        <PageButton
                            key={`page-${page}`}
                            onClick={() => handlePageChange(page)}>
                            {page}
                        </PageButton>
                    ))}
                    {elipsisAfter ? (
                        <PageButton
                            {...(typeof elipsisAfter == "number" && { onClick: () => handlePageChange(elipsisAfter) })}>
                            {elipsisAfter}
                        </PageButton>
                    ) : null}
                    {!isLast && (
                        <PageButton
                            onClick={() => handlePageChange(pages)}>
                            {pages}
                        </PageButton>
                    )}
                    {!isLast && (
                        <PageButton
                            onClick={() =>
                                handlePageChange(Math.max(page + 1))
                            }>
                            <ChevronRight size={16} />
                        </PageButton>
                    )}
                </div>
            ) : null}
        </>
    );
};

export default Pagination;